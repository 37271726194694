import { pipe } from "@scripts/fp-ts";
import { aboutPage } from "@scripts/generated/domaintables/pages";
import type { IssuerPartnerLanderPageData } from "@scripts/generated/models/issuerPageData";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Markdown } from "@scripts/react/components/Markdown";
import { getCustomTitleOrName } from "@scripts/syntax/pageTitles";

import { useIssuerSitesSelector } from "../../state/store";

export const PartnerLanderAbout = (props: { aboutText: IssuerPartnerLanderPageData["aboutText"] }) => {
  const pages = useIssuerSitesSelector("pages");

  return <>
    <h2 className="mt-2">{getCustomTitleOrName(aboutPage)(pages)}</h2>
    {pipe(props.aboutText, mapOrEmpty(_ => <Markdown content={_.data} />))}
  </>;
};
