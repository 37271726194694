import type { RNEA } from "@scripts/fp-ts";
import { omit } from "@scripts/fp-ts/struct";
import type { ProjectRelatedContent, ProjectWithRelatedContent } from "@scripts/generated/models/project";
import type { SitesJumpLink } from "@scripts/react/components/SidebarLinks";

import { relatedContent as genRelatedContent } from "./relatedContentJumpLinks";

export const relatedContent = (rc: ProjectRelatedContent): SitesJumpLink =>
  genRelatedContent(omit("photos", "quickFacts")(rc));

export const all = (p: ProjectWithRelatedContent): RNEA.ReadonlyNonEmptyArray<SitesJumpLink> => [relatedContent(p.relatedContent)];
