import { useCallback, useEffect } from "react";

import { Static } from "@scripts/bondlinkStatic";
import { debounce } from "@scripts/util/debounce";

export const useScrollPositionEffect = (callback: (value: number, scrolling: boolean) => void, debounceMs?: number) => {
  const handleScroll = useCallback((scrolling: boolean) => debounce(() => {
    callback(globalThis.scrollY, scrolling);
  }, debounceMs ?? Static.baseTransitionDelay), [callback, debounceMs]);

  // Setup event listener on window element in order to execute scroll listener on page.
  useEffect(() => {
    handleScroll(false)();
    const scrollListener = handleScroll(true);
    globalThis.addEventListener("scroll", scrollListener, { capture: true, passive: true });

    return () => {
      globalThis.removeEventListener("scroll", scrollListener, { capture: true });
    };
  }, [handleScroll]);
};
