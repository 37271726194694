import type { BLConfigWithLog } from "@scripts/bondlink";
import { A, O, pipe, R, RA, RNEA } from "@scripts/fp-ts";
import type { ActivityReportDataPointU } from "@scripts/generated/domaintables/activityReportDataPoints";
import { documentDownloads, emailAddress, emailsClicked, emailsOpened, emailsReceived, engagementScore, infoRequests, institutionalInvestorType, lastActivityDate, name, offeringViews, organization, phoneNumber, productFocus, roadshowViews, sectorFocus, title, userType } from "@scripts/generated/domaintables/activityReportDataPoints";
import type { ActivityReportTemplate } from "@scripts/generated/models/activityReportTemplate";
import type { WithId } from "@scripts/generated/models/threadThrough";
import { emptyCellConst } from "@scripts/react/components/table/cells/NoDataCell";
import type { TableColumnsExport } from "@scripts/react/components/table/tableSyntax";
import { humanDateFull } from "@scripts/syntax/date/joda";
import { prop } from "@scripts/util/prop";
import type { ExcelExportColumn } from "@scripts/util/xlsx/syntax";

import { getEngagementScoreAsNumber } from "../EngagementScore";
import type { DealPortalExportColumns, IATableModel, IssuerPortalExportColumns, UATableModel } from "../userActivitySyntax";

export const standardReports: ReadonlyArray<WithId<ActivityReportTemplate>> = [{
  // Server ids start at 1, these shouldn't overlap
  id: -2,
  record: {
    name: "Basic Report",
    description: O.some("This report contains a few key data points and shows the same data as viewed on the Investor Activity page."),
    dataPoints: [
      name,
      emailAddress,
      organization,
      userType,
      lastActivityDate,
    ],
  },
}, {
  id: -1,
  record: {
    name: "Complete Report",
    description: O.some("This report includes all possible data points for every row."),
    dataPoints: [
      name,
      emailAddress,
      phoneNumber,
      organization,
      title,
      userType,
      institutionalInvestorType,
      productFocus,
      sectorFocus,
      lastActivityDate,
      engagementScore,
      offeringViews,
      roadshowViews,
      documentDownloads,
      infoRequests,
      emailsReceived,
      emailsOpened,
      emailsClicked,
    ],
  },
}];

type TableRow = IATableModel["Row"] | UATableModel["Row"];

const dataPointExcelColumnWidth = (
  config: BLConfigWithLog, dataPoint: ActivityReportDataPointU
) => {
  switch (dataPoint._tag) {
    case "EmailAddress":
    case "Organization":
    case "ProductFocus":
    case "SectorFocus":
      return 48;
    case "PhoneNumber":
      return 17;
    case "Name":
    case "Title":
    case "UserType":
    case "InstitutionalInvestorType":
      return 34;
    case "LastActivityDate":
      return 16;
    case "DocumentDownloads":
    case "EmailsClicked":
    case "EmailsOpened":
    case "EmailsReceived":
    case "EngagementScore":
    case "InfoRequests":
    case "OfferingViews":
    case "RoadshowViews":
      return 14;
  }

  return config.exhaustive(dataPoint);
};

const dataPointToReportColumn = (
  config: BLConfigWithLog, dataPoint: ActivityReportDataPointU
) => {
  switch (dataPoint._tag) {
    case "EmailAddress":
      return {
        "email": {
          getCellValue: (row: TableRow) => row.__metadata.actor.email,
          title: "Email",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "PhoneNumber":
      return {
        "phoneNumber": {
          getCellValue: (row: TableRow) => pipe(
            row.__metadata.actor.phoneNumber,
            O.getOrElse(emptyCellConst)
          ),
          title: "Phone",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "Organization":
      return {
        "organization": {
          title: organization.name,
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "Title":
      return {
        "title": {
          getCellValue: (row: TableRow) => pipe(
            row.__metadata.actor.title,
            O.getOrElse(emptyCellConst)
          ),
          title: "Title",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "UserType":
      return {
        "userType": {
          title: "User Type",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "InstitutionalInvestorType":
      return {
        "institutionalType": {
          title: "Institutional Investor Type",
          getCellValue: (row: TableRow) => pipe(
            row.__metadata.actor.investorType,
            O.map(prop("name")),
            O.getOrElse(emptyCellConst)
          ),
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "ProductFocus":
      return {
        "productFocus": {
          title: "Product Focus",
          getCellValue: (row: TableRow) => pipe(
            Array.from(row.__metadata.actor.investorFocuses.values()),
            RNEA.fromArray,
            O.map(_ => _.map(f => f.name).join(", ")),
            O.getOrElse(emptyCellConst)
          ),
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "SectorFocus":
      return {
        "sectorFocus": {
          title: "Sector Focus",
          getCellValue: (row: TableRow) => pipe(
            Array.from(row.__metadata.actor.investorSectors.values()),
            RNEA.fromArray,
            O.map(_ => _.map(s => s.name).join(", ")),
            O.getOrElse(emptyCellConst)
          ),
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "EngagementScore":
      return {
        "engagementScore": {
          conditionalFormatting: "dataBar" as const,
          getCellValue: (row: TableRow) => getEngagementScoreAsNumber(config, row.engagementScore.level),
          title: "Engagement Score",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "LastActivityDate":
      return {
        "lastActivity": {
          getCellValue: (row: TableRow) => humanDateFull(row.lastActivity),
          title: "Last Activity",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "OfferingViews":
      return {
        "offeringViews": {
          conditionalFormatting: "colorScale" as const,
          getCellValue: (row: TableRow) =>
            typeof row.__metadata.activities.viewedOffering === "number"
              ? row.__metadata.activities.viewedOffering
              : Array.from(row.__metadata.activities.viewedOffering.values()).reduce((acc, n) => acc + n, 0),
          title: "Bond Offering Views",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "RoadshowViews":
      return {
        "roadshowViews": {
          conditionalFormatting: "colorScale" as const,
          getCellValue: (row: TableRow) =>
            typeof row.__metadata.activities.viewedRoadshow === "number"
              ? row.__metadata.activities.viewedRoadshow
              : Array.from(row.__metadata.activities.viewedRoadshow.values()).reduce((acc, n) => acc + n, 0),
          title: "Roadshow Views",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "DocumentDownloads":
      return {
        "documentDownloads": {
          conditionalFormatting: "colorScale" as const,
          getCellValue: (row: TableRow) =>
            typeof row.__metadata.activities.downloadedDocument === "number"
              ? row.__metadata.activities.downloadedDocument
              : Array.from(row.__metadata.activities.downloadedDocument.values()).reduce((acc, n) => acc + n, 0),
          title: "Document Downloads",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "InfoRequests":
      return {
        "infoRequests": {
          conditionalFormatting: "colorScale" as const,
          getCellValue: (row: TableRow) => row.__metadata.activities.submittedContactForm,
          title: "Information Requests",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "EmailsReceived":
      return {
        "emailsReceived": {
          conditionalFormatting: "colorScale" as const,
          getCellValue: (row: TableRow) => row.__metadata.activities.receivedEmail,
          title: "Emails Received",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "EmailsOpened":
      return {
        "emailsOpened": {
          conditionalFormatting: "colorScale" as const,
          getCellValue: (row: TableRow) => row.__metadata.activities.openedEmail,
          title: "Emails Opened",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "EmailsClicked":
      return {
        "emailsClicked": {
          conditionalFormatting: "colorScale" as const,
          getCellValue: (row: TableRow) => row.__metadata.activities.clickedEmail,
          title: "Emails Clicked",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
    case "Name":
      return {
        "name": {
          title: "Name",
          width: dataPointExcelColumnWidth(config, dataPoint),
        },
      };
  }

  return config.exhaustive(dataPoint);
};

export const parseExcelColumns = <A, MetaData, ExportExtra extends string[], ExportIgnore extends Array<keyof A>>(
  reportColumns: TableColumnsExport<A, MetaData, ExportExtra, ExportIgnore>
) =>
  pipe(
    reportColumns,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
    R.toEntriesNoSort as any,
    A.map(([, v]: [string, ExcelExportColumn]) => ({
      ...v,
      title: pipe(O.fromNullable(v.title), O.getOrElse(() => "")),
    }))
  );

export const parseTemplateColumnsDealPortal = (
  config: BLConfigWithLog, reportTemplate: WithId<ActivityReportTemplate>
) => pipe(
  reportTemplate.record.dataPoints,
  RA.reduce<ActivityReportDataPointU, DealPortalExportColumns>(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as DealPortalExportColumns,
    (acc, _) => ({ ...acc, ...dataPointToReportColumn(config, _) })
  )
);

export const parseTemplateColumnsIssuerPortal = (
  config: BLConfigWithLog, reportTemplate: WithId<ActivityReportTemplate>
) => pipe(
  reportTemplate.record.dataPoints,
  RA.reduce<ActivityReportDataPointU, IssuerPortalExportColumns>(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as IssuerPortalExportColumns,
    (acc, _) => ({ ...acc, ...dataPointToReportColumn(config, _) })
  )
);
