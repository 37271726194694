import { type PropsWithChildren, useState } from "react";

import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import { ESG } from "@scripts/generated/domaintables/contentTagTypes";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { hasTag } from "@scripts/models/taggedContent";
import { Anchor, AnchorIcon } from "@scripts/react/components/Anchor";
import { trueOrEmpty } from "@scripts/react/components/Empty";
import { Grid, GridCol } from "@scripts/react/components/layout/Grid";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { ShowHideToggle } from "@scripts/react/components/ShowMore";
import type { SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import { Svg } from "@scripts/react/components/Svg";
import { ThumbnailCard } from "@scripts/react/components/ThumbnailCard";
import { klass } from "@scripts/react/util/classnames";
import { type AboutPageData } from "@scripts/routes/routing/ssr/issuersites";

import projectIcon from "@svgs/construction.svg";
import leafIcon from "@svgs/leaf.svg";

import { useIssuerSitesSelector } from "../../state/store";

type Project = AboutPageData["projects"][number];

type ProjectsSectionProps = {
  maxLength?: number;
  projects: RNEA.ReadonlyNonEmptyArray<Project>;
  jumpLink: SitesJumpLink;
  truncateLength: number;
};

export const ProjectCard = (props: { project: Project }) => {
  const issuer = useIssuerSitesSelector("issuer");

  const projectRoute = SR.issuersitesAboutControllerProjectItem({
    issuerId: issuer.id,
    issuerSlug: issuer.slug,
    projectId: props.project.data.id,
  });

  return (
    <ThumbnailCard
      bodyKlasses={["h-100"]}
      cardKlasses={["card-link"]}
      cardUrlPropsO={O.some({
        target: "_self",
        url: projectRoute.url,
      })}
      imgPropsO={pipe(
        props.project.data.record.data.photos,
        RA.head,
        O.map(_ => ({
          imgUrl: _.data.data.record.uploadResponse.uri,
          altText: `Preview photo for ${props.project.data.record.data.project.projectTitle}.`,
        }))
      )}
    >
      <div {...klass("d-flex", "flex-col", "justify-content-between", "h-100")}>
        <h5>
          <Anchor
            target={"_self"}
            route={{
              title: props.project.data.record.data.project.projectTitle,
              route: projectRoute,
            }}
          />
          {pipe(
            hasTag(ESG)(props.project.data.record),
            trueOrEmpty(<Svg src={leafIcon} {...klass("ml-025")} />)
          )}
        </h5>
        <AnchorIcon
          icon={projectIcon}
          target={"_self"}
          route={{
            title: "View Project",
            route: projectRoute,
          }}
        />
      </div>
    </ThumbnailCard>
  );
};

export const ProjectsSection = (props: PropsWithChildren<ProjectsSectionProps>) => {
  const [showMore, setShowMore] = useState(false);
  const pages = useIssuerSitesSelector("pages");

  const displayedProjects = showMore
    ? props.projects
    : RNEA.firstNRaw(props.truncateLength)(props.projects);

  return (
    <AccentDividerSection jumpLink={props.jumpLink} pages={pages} klasses="accent-border-top">
      <Grid klasses={"card-grid"} attrs={O.none}>
        {pipe(
          displayedProjects,
          RA.map(_ =>
            <GridCol
              cols={[".c-24", ".c-lg-12"]}
              key={_.data.id}
              klasses={[]}
            >
              <ProjectCard project={_} />
            </GridCol>
          )
        )}
      </Grid>
      {props.projects.length > props.truncateLength && (
        <div {...klass("mt-15")}>
          <ShowHideToggle
            isShowingMore={showMore}
            setIsShowingMore={setShowMore}
            text={{ show: "Show all", hide: "Show less" }}
            appendIcon
          />
        </div>
      )}
      {props.children}
    </AccentDividerSection>
  );
};
