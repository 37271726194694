import { pipe } from "@scripts/fp-ts";
import type { CustomPageDataO } from "@scripts/generated/models/customPages";
import * as ISR from "@scripts/generated/routers/sitesRouter";
import { AnchorButton } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import type { Klass } from "@scripts/react/util/classnames";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";

import { useIssuerSitesSelector } from "../state/store";

export const CustomPageSection = (props: { customPage: CustomPageDataO, klasses?: Klass }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  return (
    <AccentDividerSection jumpLink={jl.customPage(props.customPage)} pages={pages} klasses={props.klasses}>
      {pipe(
        props.customPage.data,
        mapOrEmpty(data =>
          <Markdown
            content={data.data.record.data}
            klasses={["truncate", "truncate-5", "w-md-65"]}
          />
        )
      )}
      <AnchorButton
        target={"_self"}
        route={{
          title: `View ${props.customPage.title}`,
          route: ISR.issuersitesIssuerControllerCustom({
            issuerId: issuer.id,
            issuerSlug: issuer.slug,
            i: props.customPage.page.index,
          }),
        }}
        variant="primary"
        klasses={["mt-2"]}
      />
    </AccentDividerSection>
  );
};
