import { E, pipe } from "@scripts/fp-ts";
import type { IssuerPartnerLanderPageData } from "@scripts/generated/models/issuerPageData";

import { PartnerLanderProjects } from "./PartnerLanderProjects";
import { PartnerLanderWelcomeLetter } from "./PartnerLanderWelcomeLetter";

export const PartnerLanderWelcomeLetterOrProjects = (props: { data: IssuerPartnerLanderPageData }) =>
  pipe(
    props.data.welcomeLetterOrProjects,
    E.fold(
      _ => <PartnerLanderWelcomeLetter welcomeLetter={_} />,
      _ => <PartnerLanderProjects partner={props.data.partner} projects={_} />,
    ),
  );
