import { makeModalUrl } from "@scripts/codecs/deepLinkTarget";
import { findIssuersModalId } from "@scripts/generated/domaintables/deepLinkTypes";
import * as AssetsRouter from "@scripts/generated/routers/assetsRouter";
import * as CR from "@scripts/generated/routers/corpRouter";
import { Anchor, AnchorUnsafe, AnchorWithChildren, BondLinkLinkedInAnchor, BondLinkTwitterAnchor } from "@scripts/react/components/Anchor";
import { ButtonLink } from "@scripts/react/components/Button";
import { useConfig } from "@scripts/react/context/Config";
import { klass } from "@scripts/react/util/classnames";
import { useModal } from "@scripts/react/util/useModal";
import { dashboard } from "@scripts/routes/routing/investorportal/baseRoutes";
import { urlInterface } from "@scripts/routes/urlInterface";
import { absoluteUrl } from "@scripts/util/url";

import type { DisclaimerType } from "./DisclaimerModal";
import { DisclaimerModal } from "./DisclaimerModal";

const muniSearchModalUrl = makeModalUrl(dashboard(), findIssuersModalId, []);

export const Footer = (props: {
  disclaimerType: DisclaimerType;
  includeFindIssuers?: false;
}) => {
  const config = useConfig();
  const [modalOpen, openModal, closeModal] = useModal("Disclaimer Modal");

  const includeFindIssuers = props.includeFindIssuers ?? true;

  return (
    <footer>
      <div {...klass("footer-bondlink d-flex")}>
        <div {...klass("container d-flex flex-col")}>
          <div {...klass("d-flex flex-col flex-sm-row justify-content-sm-between")}>
            <h5 {...klass("d-flex align-items-center justify-content-center justify-content-sm-start mb-1")}>
              <span>Powered by</span>
              <AnchorWithChildren
                aria-label="BondLink Homepage"
                externalLinkLocation="none"
                klasses={"no-decoration d-inline-block"}
                route={{
                  title: "BondLink Home Page",
                  route: urlInterface("GET", config.baseUrl),
                }}
                target={"_blank"}
              >
                <img src={AssetsRouter.assetImgLogosBondlink2019InvertedSvg().url} alt="BondLink Logo" {...klass("bondlink-logo")} />
              </AnchorWithChildren>
            </h5>
            <div {...klass("mb-1 social-icons-row text-center")}>
              <BondLinkTwitterAnchor />
              <BondLinkLinkedInAnchor />
            </div>
          </div>
          <div {...klass("links text-center text-sm-left")}>
            {includeFindIssuers && <AnchorUnsafe
              aria-label={"Find Issuers"}
              target="_blank"
              title="Find Issuers"
              href={muniSearchModalUrl}
              externalLinkLocation="none"
              klasses="no-decoration"
            />}
            <ButtonLink {...klass("no-decoration")} onClick={openModal}>Disclaimer</ButtonLink>
            <Anchor
              externalLinkLocation={"none"}
              klasses={"no-decoration"}
              route={{
                title: "Terms",
                route: absoluteUrl(config)(CR.corporateStaticTerms()),
              }}
              target={"_blank"}
            />
            <Anchor
              externalLinkLocation={"none"}
              klasses={"no-decoration"}
              route={{
                title: "Privacy",
                route: absoluteUrl(config)(CR.corporateStaticPrivacy()),
              }}
              target={"_blank"}
            />
            <span>&copy;{new Date().getFullYear()} BondLink, Inc.</span>
          </div>
        </div>
      </div>
      <DisclaimerModal
        disclaimerType={props.disclaimerType}
        dismissAction={closeModal}
        modalOpen={modalOpen}
      />
    </footer>
  );
};
