import type { BLConfigWithLog } from "@scripts/bondlink";
import { klass } from "@scripts/react/util/classnames";
import * as es from "@scripts/syntax/engagementScore";

export const getEngagementScoreAsNumber = (config: BLConfigWithLog, level: es.EngagementLevel) => {
  switch (level) {
    case "veryLow": return 0;
    case "low": return 1;
    case "medium": return 2;
    case "high": return 3;
    case "veryHigh": return 4;
    default: return config.exhaustive(level);
  }
};

const getColor = (thresholds: es.EngagementThresholds, score: es.EngagementScore) => (level: es.EngagementLevel): "green" | "gray" => {
  return score.score >= thresholds[level] ? "green" : "gray";
};

const EngagementScore = (props: { score: es.EngagementScore, thresholds: es.EngagementThresholds }) => {
  const color = getColor(props.thresholds, props.score);
  return (
    <div {...klass("engagement-score")}>
      <span {...klass("box-one-rem", color("low"))} />
      <span {...klass("box-one-rem", color("medium"))} />
      <span {...klass("box-one-rem", color("high"))} />
      <span {...klass("box-one-rem", color("veryHigh"))} />
    </div>
  );
};

export const UserActivityEngagementScore = (props: { score: es.EngagementScore }) =>
  <EngagementScore score={props.score} thresholds={es.userActivityEngagementThresholds} />;

export const DealViewEngagementScore = (props: { score: es.EngagementScore }) =>
  <EngagementScore score={props.score} thresholds={es.dealViewEngagementThresholds} />;
