import type { Html } from "@scripts/codecs/html";
import type { O } from "@scripts/fp-ts";
import { pipe } from "@scripts/fp-ts";
import { noneOrEmpty } from "@scripts/react/components/Empty";
import { ErrorLayout } from "@scripts/react/components/error/ErrorLayout";

import { useIssuerSitesSelector } from "../../state/store";
import { GoToHomePage } from "./GoToHomePage";

export const ServerError = (props: { debugContent: O.Option<Html> }) => {
  const issuer = useIssuerSitesSelector("issuer");

  return <ErrorLayout
    header="Sorry, we ran into a server error."
    content={<>
      <p className="large text-center">
        We got a 500 error, which means something on our end is temporarily not working. You can try again later, or <a href="mailto:@{blConf.customerSuccessEmail}">email us</a> for help if the problem persists.
      </p>
      {pipe(issuer.bankId, noneOrEmpty(GoToHomePage))}
    </>}
    debugContent={props.debugContent}
  />;
};
