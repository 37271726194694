import type { Html } from "@scripts/codecs/html";
import { O, pipe } from "@scripts/fp-ts";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { Anchor, ContactBLPAnchor, ContactBondLinkAnchor } from "@scripts/react/components/Anchor";
import { noneOrEmpty } from "@scripts/react/components/Empty";
import { ErrorLayout } from "@scripts/react/components/error/ErrorLayout";

import { useIssuerSitesSelector } from "../../state/store";
import { GoToHomePage } from "./GoToHomePage";

export const Forbidden = (props: { debugContent: O.Option<Html> }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const contactLink = pipe(
    issuer.bankId,
    O.fold(() => ContactBondLinkAnchor, () => ContactBLPAnchor)
  )({ title: "contact BondLink support" });

  return <ErrorLayout
    header="Sorry, you do not have permission to view this page."
    content={<>
      <h5>What could have caused this?</h5>
      <p>
        You are trying to view a page which you don’t have access to, your login session might have expired,
        or you might have logged out from another browser tab or window.
      </p>
      <h5>What you can do</h5>
      <p>
        Make sure you are <Anchor
          target="_self"
          route={{
            title: "logged in",
            route: V2Router.baseAuthControllerLogin({
              bankId: issuer.bankId,
              issuerId: O.some(issuer.id),
              uhash: O.none,
              reason: O.none,
              redirect: O.none,
            }),
          }}
        />.
        If you need help, please {contactLink}.
      </p>
      {pipe(issuer.bankId, noneOrEmpty(GoToHomePage))}
    </>}
    debugContent={props.debugContent}
  />;
};
