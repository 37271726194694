import { E, pipe, RA } from "@scripts/fp-ts";
import * as p from "@scripts/generated/domaintables/pages";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import { cusip6sMeta, cusip9sMeta } from "@scripts/routes/routing/issuerportal/dataMeta";
import { sectionToFeatures } from "@scripts/syntax/sidebarLinks";

export const cusip6 = SitesJumpLink(cusip6sMeta, [E.right(p.cusip6)]);

export const cusip9 = SitesJumpLink(cusip9sMeta, [E.right(p.emmaLinks)]);

export const allWithCustomTitles = [cusip6, cusip9];

export const allWithoutCustomTitles =
  allWithCustomTitles.map(_ => ({ ..._, sections: pipe(_.sections, RA.chain(sectionToFeatures), RA.map(E.left)) }));
