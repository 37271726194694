import { useState } from "react";
import * as E from "fp-ts/lib/Either";
import type { Lazy } from "fp-ts/lib/function";
import { constVoid, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import type * as RNA from "fp-ts/lib/ReadonlyNonEmptyArray";
import * as RR from "fp-ts/lib/ReadonlyRecord";
import * as R from "fp-ts/lib/Record";
import { useStableO } from "fp-ts-react-stable-hooks";
import type { Dispatch } from "redux";

import type { TEWithEffect } from "@scripts/api/methods";
import type { RespOrErrors } from "@scripts/fetch";
import type { SortDocCategoriesC } from "@scripts/generated/models/document";
import type { SortItemsC } from "@scripts/generated/models/sortItems";
import type { DataMetaBase } from "@scripts/meta/dataMeta";
import { show403Error } from "@scripts/react/components/api-loader/ApiLoader";
import { defaultErrorMsgEl, error403MsgEl } from "@scripts/react/components/error/errorMessages";
import type { ApiSort, ApiSortOnSuccess, ApiSortType, TableColumnRow } from "@scripts/react/components/table/tableSyntax";
import type { Listeners, NotificationAction, NotificationStore } from "@scripts/react/state/notifications";
import { notificationAdd, notificationRemove, useNotificationHandler } from "@scripts/react/state/notifications";

type RowError = { rowId: number, messages: ReadonlyArray<string> };
type RowStatus = E.Either<RowError, "disabled">;
export type GetRowStatus = (rowId: number) => O.Option<RowStatus>;
export type UpdateRowStatus = (status: RowStatus) => (id: RNA.ReadonlyNonEmptyArray<number>) => void;
export type UpdateRowErrorStatus = (status: RowStatus) => (id: number) => (e: O.Option<RespOrErrors>) => void;
type DisabledRows = RR.ReadonlyRecord<string, RowStatus>;
export type DisableRowWrapper = ReturnType<typeof disableRowDuringApiReq>;
export type ClearRowStatus = () => void;

export const rowDisable: RowStatus = E.right("disabled");
const rowError: (rowId: number) => RowStatus = (rowId: number) => E.left({ rowId: rowId, messages: [] });

export const disableRowDuringApiReq = (updateRow: UpdateRowErrorStatus, clearRowStatus: ClearRowStatus) =>
  (rowId: number, onSuccess = constVoid) =>
    <A extends RespOrErrors, B>(apiReq: TEWithEffect<A, B>) => {
      clearRowStatus();
      updateRow(rowDisable)(rowId)(O.none);
      apiReq(
        (e: RespOrErrors) => updateRow(rowError(rowId))(rowId)(O.some(e)),
        () => {
          clearRowStatus();
          onSuccess();
        }
      )();
    };

export const rowStatusFold = <A>(def: Lazy<A>, disabled: Lazy<A>, err: (e: RowError) => A) => O.fold<RowStatus, A>(
  def,
  E.fold(
    err,
    disabled,
  )
);

// useTableRowStatus provides the basic functionality needed to keep track of and update
// row status within a table.
//
// getRowStatus: (rowId) => Option<Either<RowError, "disabled">> :: Is passed to a table and used by each row to
// determine that rows' status
// updateRowStatus: (Option<Either<RowError, "disabled">>) => (rowId[]) :: Is used to update one or multiple rows to a specific status
// clearRowStatus :: Clears all previously set row status
//
// This hook shouldn't be used if the table requires error handling / draggable support
export const useTableRowStatus = () => {
  const [rowIdsStatus, setRowIdsStatus] = useState<DisabledRows>({});

  const modify = (ids: ReadonlyArray<number>, status: RowStatus) => pipe(
    ids, RA.map((id): [string, RowStatus] => [id.toString(), status]), RR.fromEntries
  );

  const getRowStatus = (rowId: number) => R.lookup(rowId.toString())(rowIdsStatus);

  const updateRowStatus = (status: RowStatus) => (ids: RNA.ReadonlyNonEmptyArray<number>) => setRowIdsStatus(() => modify(ids, status));

  const clearRowStatus = () => setRowIdsStatus({});

  return [getRowStatus, updateRowStatus, clearRowStatus] as const;
};

// useTableRowErrorBase is composed with useTableRowStatus to add error handling
export const useTableRowErrorBase = (
  notificationStore: NotificationStore, dispatch: Dispatch<NotificationAction>, updateRowsRaw: UpdateRowStatus, enableAll: ClearRowStatus
): [UpdateRowErrorStatus, ClearRowStatus] => {
  const [tableErrorListeners, setTableErrorListeners] = useState<Listeners>({});
  const [errorRowId, setErrorRowId] = useStableO<number>(O.none);
  useNotificationHandler(tableErrorListeners, notificationStore);

  const clearRowStatus = () => {
    O.map((id: number) => dispatch(notificationRemove(id.toString())))(errorRowId);
    setErrorRowId(O.none);
    enableAll();
  };

  const updateRowErrorStatus = (status: RowStatus) => (rowId: number) => (err: O.Option<RespOrErrors>) => E.fold(
    () => {
      setErrorRowId(O.some(rowId));
      updateRowsRaw(rowError(rowId))([rowId]);
      setTableErrorListeners({
        [rowId.toString()]: {
          onAddAction: constVoid,
          onRemoveAction: () => {
            setErrorRowId(O.none);
            enableAll();
          },
        },
      });
      dispatch(notificationAdd({
        id: rowId.toString(),
        title: "Error Updating Table",
        type: "danger",
        children: O.fold(
          () => defaultErrorMsgEl,
          (e: RespOrErrors) => show403Error(e) ? error403MsgEl : defaultErrorMsgEl
        )(err),
      }));
    },
    () => updateRowsRaw(status)([rowId])
  )(status);

  return [updateRowErrorStatus, clearRowStatus];
};

// useDraggableRowStatusBase is composed with useTableRowStatus to support error handling / disabling for draggable table rows
export const useDraggableRowStatusBase = <A, MetaData, SortType extends SortItemsC | SortDocCategoriesC>(
  dispatch: Dispatch<NotificationAction>,
  notifications: NotificationStore,
  clearRowStatus: ClearRowStatus,
  tableMeta: DataMetaBase<string>,
  onDrag: (rows: RNA.ReadonlyNonEmptyArray<TableColumnRow<A, MetaData, string[]>["Row"]>) => ApiSortType<SortType>,
  onSuccess: ApiSortOnSuccess,
): ApiSort<A, MetaData, SortType> => {

  const [error, setError] = useState(false);

  useNotificationHandler({
    [tableMeta.type]: {
      onRemoveAction: () => setError(false),
      onAddAction: () => setError(true),
    },
  }, notifications);

  return {
    kind: "api-sort",
    error: error,
    onDrag: onDrag,
    onFailure: () => {
      clearRowStatus();
      dispatch(notificationAdd({
        id: tableMeta.type,
        title: "Error Updating Table",
        type: "danger",
        children: defaultErrorMsgEl,
      }));
    },
    onSuccess: () => {
      clearRowStatus();
      onSuccess();
    },
  };
};
