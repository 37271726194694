import type { PropsWithChildren, ReactElement } from "react";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import type * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";

import type { SVGString } from "*.svg";

import type { KlassProp } from "@scripts/react/util/classnames";
import { emptyKlasses, klass, klassNullableToList, klassPropO } from "@scripts/react/util/classnames";

import { mapOrEmpty } from "./Empty";
import { Svg } from "./Svg";
import type { TooltipProps } from "./Tooltip";
import { TooltipInfo } from "./Tooltip";

type VariantU = "accent" | "bold" | "bold-h2";

export type DataVerticalVariant = {
  orientation: "vertical" | "sidebar";
  variant?: VariantU;
};

export type DataHorizontalVariant = {
  orientation: "horizontal" | "row";
  variant?: VariantU | "small";
};

type DataListVariantU = DataVerticalVariant | DataHorizontalVariant;

type DataTitleProps = {
  title: string;
  tooltipProps?: PropsWithChildren<TooltipProps>;
};

export const DataTitle = (props: DataTitleProps) =>
  <div {...klass("d-flex", "align-items-baseline", "data-title")}>
    <dt>{props.title}</dt>
    {pipe(
      O.fromNullable(props.tooltipProps),
      mapOrEmpty(tp => <TooltipInfo {...tp} addClassToTargetNode={"ml-025"} />)
    )}
  </div>;

type DataValueProps = {
  value: string | number | ReactElement;
  icon?: SVGString;
  valueKlasses?: KlassProp;
};

export const DataValue = (props: DataValueProps) =>
  <dd {...klassPropO("data-value")(props.valueKlasses)}>{pipe(props.icon, O.fromNullable, mapOrEmpty(_ => <Svg src={_} className="mr-025" />))}{props.value}</dd>;

type DataListProps = PropsWithChildren<DataListVariantU>;

const DataList = (props: DataListProps) =>
  <dl {...klassPropO(`data-${props.orientation}`)(props.variant)}>
    {props.children}
  </dl>;

type DataListVariantProps<A extends DataListVariantU> = PropsWithChildren<Pick<A, "variant">>;

export const DataHorizontal = (props: DataListVariantProps<DataHorizontalVariant>) => <DataList orientation="horizontal" variant={props.variant}>{props.children}</DataList>;

export const DataVertical = (props: DataListVariantProps<DataVerticalVariant>) => <DataList orientation="vertical" variant={props.variant}>{props.children}</DataList>;

export const DataSidebar = (props: DataListVariantProps<DataVerticalVariant>) => <DataList orientation="sidebar" variant={props.variant}>{props.children}</DataList>;

export type DataPointBaseProps = { klasses?: KlassProp, reverse?: true } & DataTitleProps;

export type DataPointProps = DataPointBaseProps & DataValueProps;

export const DataPoint = (props: DataPointProps) =>
  <div {...klassPropO("data-point")(props.klasses)}>
    <DataTitle title={props.title} tooltipProps={props.tooltipProps} />
    <DataValue value={props.value} valueKlasses={props.valueKlasses} icon={props.icon} />
  </div>;

const DataPointRowBase = (props: PropsWithChildren<DataPointBaseProps>) =>
  <div {...klassPropO("data-point-row")(props.klasses)}>
    <DataTitle title={props.title} tooltipProps={props.tooltipProps} />
    {props.children}
  </div>;

export const DataPointRow = (props: DataPointProps) =>
  <DataPointRowBase {...props}>
    <DataValue value={props.value} valueKlasses={props.valueKlasses} icon={props.icon} />
  </DataPointRowBase>;

export const DataPointRowO = (p: { title: string, value: O.Option<string> }) => pipe(
  p.value,
  mapOrEmpty(v => <DataPointRow title={p.title} value={v} />)
);

export const DataPointSmall = (props: DataPointProps) =>
  DataPoint({ ...props, klasses: emptyKlasses.concat("data-point-small", klassNullableToList(props.klasses)) });

/** @knipignore */
export const DataPointMedium = (props: DataPointProps) =>
  DataPoint({ ...props, klasses: emptyKlasses.concat("data-point-medium", klassNullableToList(props.klasses)) });

type DataPointAProps = DataPointBaseProps & { data: RNEA.ReadonlyNonEmptyArray<DataValueProps & { key: string }> };

export const DataPointA = (props: DataPointAProps) =>
  <div {...klassPropO("data-point")(props.klasses)}>
    <DataTitle title={props.title} tooltipProps={props.tooltipProps} />
    <div className="d-flex flex-col gap-025">
      {props.data.map(({ value, valueKlasses, key }) => <DataValue key={key} value={value} valueKlasses={valueKlasses} />)}
    </div>
  </div>;

export const DataPointLarge = (props: DataPointProps) =>
  DataPoint({ ...props, klasses: emptyKlasses.concat("data-point-large", klassNullableToList(props.klasses)) });

export const DataPointO = (p: { title: string, value: O.Option<string> }) => pipe(
  p.value,
  mapOrEmpty(v => <DataPoint title={p.title} value={v} />)
);
