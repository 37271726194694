import type { BLConfigWithLog } from "@scripts/bondlink";
import type { DateRangeAny } from "@scripts/codecs/routing";
import { identity, Mn, N, O, Ord, pipe, R, RA, RM } from "@scripts/fp-ts";
import type * as dpp from "@scripts/generated/models/dealPortalActivity";
import type { ActivityByType } from "@scripts/generated/models/dealPortalAnalytics";
import type * as dpt from "@scripts/generated/models/dealPortalInvestorActivityTable";
import type * as ipt from "@scripts/generated/models/investorActivityTable";
import type * as ipp from "@scripts/generated/models/investorProfile";
import { type Joda } from "@scripts/syntax/date/joda";
import { LocalDateEq } from "@scripts/syntax/date/jodaSyntax";

export type EngagementLevel = "veryLow" | "low" | "medium" | "high" | "veryHigh";

export type EngagementThresholds = { [K in EngagementLevel]: number };

export type EngagementScore = { score: number, level: EngagementLevel };

const mkScore = (thresholds: EngagementThresholds) => (score: number): EngagementScore => {
  // Sort the thresholds in descending order and find the first one where the given score is >= the threshold
  const level = pipe(
    R.toEntries(thresholds),
    RA.sort(Ord.contramap((_: [EngagementLevel, number]) => _[1])(Ord.reverse(N.Ord))),
    RA.findFirstMap(([l, i]) => score >= i ? O.some(l) : O.none),
    O.getOrElse((): EngagementLevel => "veryLow"),
  );

  return { score, level };
};

export const userActivityEngagementThresholds: EngagementThresholds = {
  veryLow: 0,
  low: 1,
  medium: 4,
  high: 8,
  veryHigh: 16,
};

const mkUserActivityScore = mkScore(userActivityEngagementThresholds);

type UserActivityRow = dpt.InvestorActivityRow | ipt.InvestorActivityRow;

type DistributeActivities<T extends UserActivityRow = UserActivityRow> = T extends T ? keyof T["activities"] : never;
type UserActivityType = DistributeActivities;

type UserActivityWeight = 1 | 2 | 3;

const userActivityCount = (x: number | ReadonlyMap<number, number>, weight: UserActivityWeight): number =>
  (typeof x === "number" ? x : RM.foldMap(N.Ord)(N.MonoidSum)(identity<number>)(x)) * weight;

const userActivityWeight = (config: BLConfigWithLog) => (tpe: UserActivityType, isBLP: boolean): O.Option<UserActivityWeight> => {
  switch (tpe) {
    case "downloadedDocument":
    case "viewedDocument":
    case "viewedRoadshow":
      return O.some(isBLP ? 3 : 1);

    case "submittedContactForm":
      return O.some(isBLP ? 2 : 1);

    case "viewedOffering":
    case "viewedRfp":
    case "subscribedToNotifications":
    case "clickedEmail":
      return O.some(1);

    case "visitedSite":
    case "receivedEmail":
    case "openedEmail":
    case "clickedLink":
    case "didAnythingOtherThanEmailAction":
    // We don't include specific content subscriptions because they're covered by `subscribedToNotifications`
    // Including them would mean we were double counting
    case "subscribedToEvents":
    case "subscribedToBonds":
    case "subscribedToRfps":
    case "subscribedToDocs":
    case "subscribedToNews":
    case "subscribedToRatings":
      return O.none;

    default: return config.exhaustive(tpe);
  }
};

export const userActivityEngagementScore = (config: BLConfigWithLog) => (
  row: UserActivityRow,
  dateRange: DateRangeAny,
  isBLP: boolean,
): EngagementScore => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const activities = row.activities as Record<UserActivityType, number | ReadonlyMap<number, number>>;
  const total = pipe(
    R.keys(activities),
    RA.foldMap(N.MonoidSum)(k => pipe(
      userActivityWeight(config)(k, isBLP),
      O.fold(() => 0, weight => userActivityCount(activities[k], weight))
    )),
  );

  const startYear = dateRange.start.year();
  const startMonth = dateRange.start.monthValue();
  const endYear = dateRange.end.year();
  const endMonth = dateRange.end.monthValue();
  const monthsActive = Math.max(((endYear - startYear) * 12) + (endMonth - startMonth + 1), 1);
  return mkUserActivityScore(total / monthsActive);
};

type UserProfileEngagementScoresF<A> = {
  overall: A;
  byMonth: ReadonlyMap<Joda.LocalDate, A>;
};

export type UserProfileEngagementScores = UserProfileEngagementScoresF<EngagementScore>;

type UserProfile = dpp.InvestorProfileInfo | ipp.InvestorProfileInfo;

type DistributeActivityTypes<T extends UserProfile = UserProfile> = T extends T ? T["investorActivities"][number] : never;
type UserProfileActivityType = DistributeActivityTypes;

const localDateMonth = (d: Joda.LocalDate): Joda.LocalDate => d.withDayOfMonth(1);

const userProfileActivityToUserActivityType = (config: BLConfigWithLog) => (activity: UserProfileActivityType): UserActivityType => {
  switch (activity._tag) {
    case "DocumentDownloaded": return "downloadedDocument";
    case "DocumentViewed": return "viewedDocument";
    case "OfferingViewed": return "viewedOffering";
    case "RfpViewed": return "viewedRfp";
    case "ContactFormSubmitted": return "submittedContactForm";
    case "NotificationSubscriptionsChanged": return "subscribedToNotifications";
    case "RoadShowViewed": return "viewedRoadshow";
    case "EmailLinkClicked": return "clickedEmail";
    case "EmailOpened": return "openedEmail";
    case "EmailReceived": return "receivedEmail";
    case "IssuerLinkClicked":
    case "LinkClicked":
      return "clickedLink";
    // It might be nice to include news & project views in the engagement score but we lump them together
    // with `pageViews` on the main user activity page, so we can't include them on the user profile without risking
    // mismatches in the engagement scores between the two pages
    case "NewsViewed":
    case "ProjectViewed":
    case "SiteViewed":
      return "visitedSite";
    default: return config.exhaustive(activity);
  }
};

export const userProfileEngagementScores = (config: BLConfigWithLog) => (
  profile: UserProfile,
  dateRange: DateRangeAny,
  isBLP: boolean,
): UserProfileEngagementScores => {
  const activities: ReadonlyArray<UserProfileActivityType> = profile.investorActivities;
  const scores = pipe(
    activities,
    RA.foldMap<UserProfileEngagementScoresF<number>>(Mn.struct({
      overall: N.MonoidSum,
      byMonth: RM.getUnionMonoid<Joda.LocalDate, number>(LocalDateEq, N.SemigroupSum),
    }))(activity => pipe(
      userActivityWeight(config)(userProfileActivityToUserActivityType(config)(activity), isBLP),
      O.fold<UserActivityWeight, UserProfileEngagementScoresF<number>>(
        () => ({ overall: 0, byMonth: new Map() }),
        weight => ({
          overall: weight,
          byMonth: RM.singleton(localDateMonth(activity.details.dateTime.toLocalDate()), weight),
        }),
      )
    )),
  );
  // Ensure we include all months between the start and end dates
  const byMonth: Array<[Joda.LocalDate, EngagementScore]> = [];
  const firstMonth = localDateMonth(dateRange.start);
  let currMonth = localDateMonth(dateRange.end);
  while (currMonth.isAfter(firstMonth) || currMonth.isEqual(firstMonth)) {
    byMonth.push([
      currMonth,
      // It's important that we use `RM.lookup` instead of just `scores.byMonth.get`.
      // `RM.lookup` uses the given `Eq` instance to lookup the key while `.get` just compares using basic equality,
      // and since `LocalDate`s are objects, it uses referential equality and won't find a match
      mkUserActivityScore(O.getOrElse(() => 0)(RM.lookup(LocalDateEq)(currMonth, scores.byMonth))),
    ]);
    currMonth = currMonth.minusMonths(1);
  }

  return {
    overall: mkUserActivityScore(scores.overall / byMonth.length),
    byMonth: new Map(byMonth),
  };
};

export const dealViewEngagementThresholds: EngagementThresholds = {
  veryLow: 0,
  low: 1,
  medium: 11,
  high: 21,
  veryHigh: 41,
};

export const dealViewEngagementScore = (config: BLConfigWithLog) => (activity: ActivityByType): EngagementScore => {
  const score = pipe(
    R.keys(activity),
    RA.foldMap(N.MonoidSum)(k => {
      switch (k) {
        case "pageViews":
        case "documentDownloads":
        case "roadshowViews":
        case "infoRequests":
        case "emailClicks":
          return activity[k];

        case "emailOpens": return 0;

        default: return config.exhaustive(k);
      }
    }),
  );
  return mkScore(dealViewEngagementThresholds)(score);
};
