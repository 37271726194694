import { formatS3CdnUrl } from "@scripts/bondlink";
import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import { infrastructureProjects } from "@scripts/generated/domaintables/pages";
import type { PartnerU } from "@scripts/generated/domaintables/partners";
import type { Media } from "@scripts/generated/models/media";
import type { ProjectWithPhotos } from "@scripts/generated/models/project";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Grid, GridCol } from "@scripts/react/components/layout/Grid";
import { Markdown } from "@scripts/react/components/Markdown";
import { useConfig } from "@scripts/react/context/Config";
import { getCustomTitleOrName } from "@scripts/syntax/pageTitles";

import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { useIssuerSitesSelector } from "../../state/store";
import { PartnerLanderLink } from "./PartnerLanderLink";

const ProjectImg = (props: { photo: WithStatusU<Media> }) => {
  const config = useConfig();

  return <img
    src={formatS3CdnUrl(config)(props.photo.data.record.uploadResponse.uri)}
    alt={O.toUndefined(props.photo.data.record.altText)}
  />;
};

const PartnerLanderProject = (props: { partner: PartnerU, project: WithStatusU<TaggedContent<ProjectWithPhotos>> }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const firstPhoto = RA.head(props.project.data.record.data.photos);

  return <Grid
    attrs={O.some(".grid-sx-1")}
    klasses="borders-y py-1"
  >
    <GridCol cols={[`.c-md-${pipe(firstPhoto, O.fold((): "16" | "24" => "24", (): "16" | "24" => "16"))}`]} klasses={O.none}>
      {pipe(firstPhoto, mapOrEmpty(_ => <div className="d-md-none"><ProjectImg photo={_.data} /></div>))}
      <h3 className="mt-0">
        {props.project.data.record.data.project.projectTitle}
        <IssuerSitesLeafIcon taggedContent={props.project.data.record} />
      </h3>
      <div className="truncate truncate-5 mb-05">
        <Markdown content={props.project.data.record.data.project.projectText} />
      </div>
      <PartnerLanderLink
        issuer={O.some(issuer)}
        partner={props.partner}
        url={SR.issuersitesAboutControllerProjectItem({ issuerId: issuer.id, issuerSlug: issuer.slug, projectId: props.project.data.id })}
        arrowType="right"
      >View project</PartnerLanderLink>
    </GridCol>
    {pipe(firstPhoto, mapOrEmpty(_ =>
      <GridCol cols={[".c-md-8"]} klasses="d-none d-md-block"><ProjectImg photo={_.data} /></GridCol>
    ))}
  </Grid>;
};

export const PartnerLanderProjects = (props: {
  partner: PartnerU;
  projects: ReadonlyArray<WithStatusU<TaggedContent<ProjectWithPhotos>>>;
}) => {
  const pages = useIssuerSitesSelector("pages");

  return pipe(
    props.projects,
    RNEA.fromReadonlyArray,
    mapOrEmpty(projects => <>
      <h2>{getCustomTitleOrName(infrastructureProjects)(pages)}</h2>
      <div>{projects.map(_ => <PartnerLanderProject key={_.data.id} partner={props.partner} project={_} />)}</div>
    </>)
  );
};
