import { E } from "@scripts/fp-ts";
import * as p from "@scripts/generated/domaintables/pages";
import type { BondProgram } from "@scripts/generated/models/bondProgramBase";
import type { IssuerAndProgramRatingsByAgency } from "@scripts/generated/models/rating";
import type { WithId } from "@scripts/generated/models/threadThrough";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";

export const allStatic: ReadonlyArray<SitesJumpLink> = [SitesJumpLink("Ratings", [E.right(p.bondRatings)])];

export const issuerRatings = (issRatings: IssuerAndProgramRatingsByAgency["issuerRatings"]): SitesJumpLink =>
  SitesJumpLink("Issuer Ratings", [E.right(p.issuerRatings)], { hidden: issRatings.length === 0 });

export const programRatings = (prog: WithId<BondProgram>): SitesJumpLink =>
  SitesJumpLink(prog.record.name, [], { overrideAnchor: `bond-program-${prog.id}` });

export const all = (
  issRatings: IssuerAndProgramRatingsByAgency["issuerRatings"],
  programs: ReadonlyArray<WithId<BondProgram>>
): ReadonlyArray<SitesJumpLink> =>
  [issuerRatings(issRatings), ...programs.map(programRatings)];
