import { O, pipe } from "@scripts/fp-ts";
import { bankCalendar } from "@scripts/generated/domaintables/featureFlags";
import type { BLPDealUnavailablePageData } from "@scripts/generated/models/issuerPageData";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { Anchor, ContactBLPAnchor } from "@scripts/react/components/Anchor";
import { trueOrEmpty } from "@scripts/react/components/Empty";
import { ErrorLayout } from "@scripts/react/components/error/ErrorLayout";
import { blpDealUnavailableDescription } from "@scripts/routes/routing/ssr/issuersites";
import { isFFEnabled } from "@scripts/syntax/featureFlags";

export const BLPDealUnavailable = (props: { data: BLPDealUnavailablePageData }) => {
  return <ErrorLayout
    header={blpDealUnavailableDescription}
    content={<>
      {pipe(isFFEnabled(bankCalendar)(props.data.bankFeatureFlags), trueOrEmpty(
        <p className="large mb-1">
          To see all active deals, <Anchor
            target="_self"
            route={{
              title: "view our full calendar",
              route: V2Router.bankSitesBankControllerCalendar({ bankId: props.data.bank.id, bankSlug: props.data.bank.slug }),
            }}
          />.
        </p>
      ))}
      <p className="large">
        If you need help, please <ContactBLPAnchor title="contact BondLink support" />.
      </p>
    </>}
    debugContent={O.none}
  />;
};
