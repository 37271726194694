import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import { Faq } from "@scripts/react/components/Faq";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { klass } from "@scripts/react/util/classnames";
import { faq as faqRoute, type FAQPageData } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { ContactUsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

const FaqGroup = (props: { faqGroup: FAQPageData["faqs"][number] }) => {
  const pages = useIssuerSitesSelector("pages");
  return <AccentDividerSection jumpLink={jl.faq.section(props.faqGroup[0])} pages={pages}>
    {pipe(
      props.faqGroup[1],
      RA.map((faq) =>
        <Faq
          faq={{ question: faq.data.record.question, answer: faq.data.record.answer }}
          key={faq.data.record.question}
          klass={O.none}
        />
      )
    )}
  </AccentDividerSection>;
};

export const FaqPage = (props: { faqs: FAQPageData["faqs"] }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");
  const faqPageMeta = faqRoute({ issuerId: issuer.id, issuerSlug: issuer.slug });
  const description = faqPageMeta.description(pages, iffs, issuer);

  const faqGroupJumpLinks = makeSitesJumpLinks(iffs, pages)(jl.faq.all(props.faqs.map(_ => _[0])));

  return (
    <DirectSitesPageDescriptionLayout
      description={description}
      sidebarContent={
        <>
          <SidebarLinksSites
            jumpLinks={faqGroupJumpLinks}
            headerLinkAnchorContent={faqPageMeta.title()}
            routeMeta={faqPageMeta}
          />
          <ContactUsActionSection containerKlass={"d-none-until-md"} />
        </>
      }
    >
      <div {...klass("accent-border-top", "pt-0")}>
        {pipe(
          RNEA.fromReadonlyArray(props.faqs),
          O.fold(
            () => <div {...klass("mt-1")}>There are currently no items in this FAQ.</div>,
            _ =>
            <>
              {pipe(
                _,
                RA.map(__ =>
                  <FaqGroup
                    faqGroup={__}
                    key={`${__[0].record.name}${__[0].id}`}
                  />
                )
              )}
            </>
          )
        )}
      </div>
      <ContactUsActionSection containerKlass={"d-md-none"} />
    </DirectSitesPageDescriptionLayout>
  );
};
