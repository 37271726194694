import type { PageLink } from "@scripts/codecs/linkable";
import { O } from "@scripts/fp-ts";
import type { PartnerU } from "@scripts/generated/domaintables/partners";
import { AnchorButtonWithChildren } from "@scripts/react/components/Anchor";
import { useConfig } from "@scripts/react/context/Config";
import { toPageRouteLinkBasic } from "@scripts/syntax/linkable";

import { issuerLogoRoute } from "@scripts-ssr/components/IssuerLogo";

import { useIssuerSitesSelector } from "../../state/store";
import { PartnerLanderLink, partnerLanderLinkUrl } from "./PartnerLanderLink";

export const PartnerLanderLinks = (props: { partner: PartnerU, links: ReadonlyArray<PageLink> }) => {
  const config = useConfig();
  const issuer = useIssuerSitesSelector("issuer");
  const homeRoute = issuerLogoRoute(issuer);

  return <>
    {props.links.map(_ => <div key={_.url}>
      <PartnerLanderLink
        issuer={O.some(issuer)}
        partner={props.partner}
        url={toPageRouteLinkBasic(_).route}
        arrowType="right"
      >{_.displayName}</PartnerLanderLink>
    </div>)}
    <AnchorButtonWithChildren
      variant="primary"
      target="_blank"
      externalLinkLocation="none"
      route={{
        ...homeRoute,
        route: partnerLanderLinkUrl(config, O.some(issuer), props.partner, homeRoute.route),
      }}
    >Visit full site</AnchorButtonWithChildren>
  </>;
};
