import { flow, pipe } from "fp-ts/lib/function";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { Eq, N, O, RA, RNEA, s } from "@scripts/fp-ts";
import type { RatingAgencyU } from "@scripts/generated/domaintables/ratingAgencies";
import type { RatingOutlookU } from "@scripts/generated/domaintables/ratingOutlook";
import type { Analyst } from "@scripts/generated/models/analyst";
import type { BondOfferingRelatedContent } from "@scripts/generated/models/bondOffering";
import type { RatingRelatedContent, RatingWithRelatedContent } from "@scripts/generated/models/rating";
import type { BondRating, RatingU } from "@scripts/generated/models/ratingBase";
import type { RelatedRatingLink } from "@scripts/generated/models/relatedContent";
import type { HasManyLink, WithStatusU } from "@scripts/generated/models/threadThrough";

import { LocalDateEq } from "./date/jodaSyntax";
import { eqMarkdown } from "./markdown";
import { mediaEq } from "./media";
import { hasManyLinkDataEq, parentIdOrId, taggedContentDataEq, withStatusValueEq } from "./threadThrough";

export type CommonRatingsByAgency = RNEA.ReadonlyNonEmptyArray<[RatingAgencyU, RNEA.ReadonlyNonEmptyArray<WithStatusU<RatingWithRelatedContent<RatingU>>>]>;

export const mapRatingData = (rating: BondOfferingRelatedContent["ratings"][number][1][number]) => ({
  ...rating,
  data: {
    record: { relatedContent: rating.data.record.relatedContent, rating: rating.data.record.rating.data },
    id: parentIdOrId(rating),
  },
});

const relatedRatingToCommonRating = (rating: BondOfferingRelatedContent["ratings"][number]): CommonRatingsByAgency[number] => [rating[0], pipe(
  rating[1],
  RNEA.map(a => ({
    ...a,
    data: {
      record: { relatedContent: a.data.record.relatedContent, rating: a.data.record.rating.data },
      id: a.data.id,
    },
  }))
)];

export const relatedRatingsToCommonRatings: (ratings: BondOfferingRelatedContent["ratings"]) =>
  O.Option<RNEA.ReadonlyNonEmptyArray<CommonRatingsByAgency[number]>> = flow(
    RA.map(relatedRatingToCommonRating),
    RNEA.fromReadonlyArray
  );

const ratingAgencyUEq = Eq.struct<RatingAgencyU>({
  _tag: s.Eq,
  agencyName: s.Eq,
  id: N.Eq,
  shortName: s.Eq,
});

const ratingOutlookUEq = Eq.struct<RatingOutlookU>({
  _tag: s.Eq,
  id: N.Eq,
  ratingOutlook: s.Eq,
});

const bondRatingEq = Eq.struct<BondRating>({
  _tag: s.Eq,
  ratingAgency: ratingAgencyUEq,
  ratingOutlook: ratingOutlookUEq,
  ratingDate: LocalDateEq,
  rating: s.Eq,
  ratingNotes: O.getEq(eqMarkdown),
});

const ratingRelatedDocumentsEq = flow(
  mediaEq,
  taggedContentDataEq,
  withStatusValueEq,
  hasManyLinkDataEq,
  RA.getEq
);

const analystEq = Eq.struct<Analyst>({
  firstName: s.Eq,
  lastName: s.Eq,
  email: O.getEq(s.Eq),
  phoneNumber: O.getEq(s.Eq),
});

const ratingRelatedAnalystsEq = RA.getEq(
  hasManyLinkDataEq(withStatusValueEq(analystEq))
);

const bondRatingWithRelatedContentEq =
  (config: BLConfigWithLog) => Eq.struct<RatingWithRelatedContent<HasManyLink<BondRating, RelatedRatingLink>>>({
    rating: hasManyLinkDataEq(bondRatingEq),
    relatedContent: Eq.struct<RatingRelatedContent>({
      analysts: ratingRelatedAnalystsEq,
      documents: ratingRelatedDocumentsEq(config),
    }),
  });


export const ratingArrayEq = (config: BLConfigWithLog) => RA.getEq<[
  RatingAgencyU,
  RNEA.ReadonlyNonEmptyArray<WithStatusU<RatingWithRelatedContent<HasManyLink<BondRating, RelatedRatingLink>>>>,
]>(Eq.tuple(
  ratingAgencyUEq,
  pipe(config, bondRatingWithRelatedContentEq, withStatusValueEq, RNEA.getEq)
));
