import * as t from "io-ts";

import { _apiFetchWithCredRespUnsafe, type ApiRespData } from "@scripts/api/methods";
import type { BLConfigWithLog } from "@scripts/bondlink";
import type { EmptyObject } from "@scripts/generated/models/emptyObject";
import type { MediaPreview } from "@scripts/generated/models/media";
import type { S3CompleteMultipartUploadPost, S3CompleteMultipartUploadResponse, S3CreateMultipartUploadPost, S3ListMultipartUploadPartsPost, S3ListMultipartUploadPartsResponse, S3MultipartUploadInfo, S3PresignedMultipartUploadParts, S3PresignMultipartUploadPartsPost, S3SuccessPost } from "@scripts/generated/models/s3Upload";
import * as V2Router from "@scripts/generated/routers/v2Router";

export const createS3Upload =
  (config: BLConfigWithLog): (data: S3CreateMultipartUploadPost) => ApiRespData<S3MultipartUploadInfo> =>
    _apiFetchWithCredRespUnsafe(config)(V2Router.baseS3CreateMultipartUpload());

export const presignS3UploadParts =
  (config: BLConfigWithLog): (data: S3PresignMultipartUploadPartsPost) => ApiRespData<S3PresignedMultipartUploadParts> =>
    _apiFetchWithCredRespUnsafe(config)(V2Router.baseS3PresignMultipartUploadParts());

export const completeS3Upload =
  (config: BLConfigWithLog): (data: S3CompleteMultipartUploadPost) => ApiRespData<S3CompleteMultipartUploadResponse> =>
    _apiFetchWithCredRespUnsafe(config)(V2Router.baseS3CompleteMultipartUpload());

export const listS3UploadParts =
  (config: BLConfigWithLog): (data: S3ListMultipartUploadPartsPost) => ApiRespData<S3ListMultipartUploadPartsResponse> =>
    _apiFetchWithCredRespUnsafe(config)(V2Router.baseS3ListMultipartUploadParts());

export const abortS3MultipartUpload =
  (config: BLConfigWithLog): (data: S3MultipartUploadInfo) => ApiRespData<EmptyObject> =>
    _apiFetchWithCredRespUnsafe(config)(V2Router.baseS3AbortMultipartUpload());

export const postS3UploadSuccess =
  (config: BLConfigWithLog): (data: S3SuccessPost) => ApiRespData<MediaPreview> =>
    _apiFetchWithCredRespUnsafe(config)(V2Router.baseS3Success());

/**
 * Values we require from Uppy in order to post s3 success data
 */
export const uploadedUppyFileResponse = t.type({
  name: t.string,
  response: t.type({
    body: t.type({
      bucket: t.string,
      key: t.string,
      location: t.string,
    }),
  }),
  size: t.number,
});

export type UploadedUppyFileResponse = t.TypeOf<typeof uploadedUppyFileResponse>;
