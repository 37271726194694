import { E } from "@scripts/fp-ts";
import * as f from "@scripts/generated/domaintables/featureFlags";
import * as p from "@scripts/generated/domaintables/pages";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import { irmaMeta } from "@scripts/routes/routing/issuerportal/dataMeta";

export const downloads = SitesJumpLink("Downloads", [E.left(f.documents)]);

export const archivedDocuments = SitesJumpLink("Archived Documents", [E.left(f.showArchivedDocs)]);

export const categories = SitesJumpLink("Document Categories", [E.left(f.documents)]);

export const archivedCategories = SitesJumpLink("Archived Document Categories", [E.left(f.showArchivedDocs)]);

export const irmaLetter = SitesJumpLink(irmaMeta, [E.right(p.irmaLetter)]);

export const allStatic = [categories, archivedCategories, irmaLetter];
