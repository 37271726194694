import { AnchorButton } from "@scripts/react/components/Anchor";
import { useConfig } from "@scripts/react/context/Config";
import { issuerHomeUrl } from "@scripts/syntax/issuer";

import { useIssuerSitesSelector } from "../../state/store";

export const GoToHomePage = () => {
  const config = useConfig();
  const issuer = useIssuerSitesSelector("issuer");

  return <div className="d-flex justify-content-center">
    <AnchorButton
      klasses="mt-0"
      variant="primary"
      target="_self"
      route={{ title: "Go to home page", route: issuerHomeUrl(config)(issuer) }}
    />
  </div>;
};
