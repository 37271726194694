import { E, O, RA } from "@scripts/fp-ts";
import * as p from "@scripts/generated/domaintables/pages";
import type { IssuerEsgPageData } from "@scripts/generated/models/issuerPageData";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import { getCustomTitleOrName } from "@scripts/syntax/pageTitles";

export const allStatic: ReadonlyArray<SitesJumpLink> = [SitesJumpLink("ESG Program", [E.right(p.esgProgram)])];

export const overview = (data: IssuerEsgPageData): SitesJumpLink =>
  SitesJumpLink("About Our Program", [], {
    hidden: O.isNone(data.overview) && RA.isEmpty(data.callouts) && RA.isEmpty(data.photos),
  });

export const offerings = (os: IssuerEsgPageData["bondOfferings"]): SitesJumpLink =>
  SitesJumpLink("Related Bond Offerings", [], { hidden: RA.isEmpty(os) });

export const rfps = (rs: IssuerEsgPageData["rfps"]): SitesJumpLink =>
  SitesJumpLink("Related RFPs", [], {
    hidden: RA.isEmpty(rs),
    overrideText: pages => `Related ${getCustomTitleOrName(p.rfpsPage)(pages)}`,
  });

export const roadshows = (rs: IssuerEsgPageData["roadshows"]): SitesJumpLink =>
  SitesJumpLink("Related Roadshows", [], { hidden: RA.isEmpty(rs) });

export const projects = (ps: IssuerEsgPageData["projects"]): SitesJumpLink =>
  SitesJumpLink("Related Projects", [], {
    hidden: RA.isEmpty(ps),
    overrideText: pages => `Related ${getCustomTitleOrName(p.infrastructureProjects)(pages)}`,
  });

export const documents = (ds: IssuerEsgPageData["documents"]): SitesJumpLink =>
  SitesJumpLink("Related Documents", [], { hidden: RA.isEmpty(ds) });

export const news = (ns: IssuerEsgPageData["news"]): SitesJumpLink =>
  SitesJumpLink("Related News", [], { hidden: RA.isEmpty(ns) });

export const all = (data: IssuerEsgPageData): ReadonlyArray<SitesJumpLink> => [
  overview(data),
  offerings(data.bondOfferings),
  rfps(data.rfps),
  roadshows(data.roadshows),
  projects(data.projects),
  documents(data.documents),
  news(data.news),
];
