import type { ReactElement } from "react";

import type { PageLink } from "@scripts/codecs/linkable";
import { O, RA } from "@scripts/fp-ts";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { IssuerPartnerLanderPageData } from "@scripts/generated/models/issuerPageData";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import { useConfig } from "@scripts/react/context/Config";
import * as rm from "@scripts/routes/routing/ssr/issuersites";
import { pageLink } from "@scripts/routes/routing/ssr/issuersites/navLinks";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";

import { Footer } from "@scripts-ssr/components/Footer";

import { useIssuerSitesSelector } from "../../state/store";
import { PartnerLanderFrame } from "./PartnerLanderFrame";
import { PartnerLanderPage } from "./PartnerLanderPage";

const mkLink = (issuer: Issuer, pages: ReadonlyArray<PageConfig<PageU>>) =>
  <A, O, T>(m: rm.IssuerSitesRouteMeta<A, O, T> & { title: rm.BaseTitleFn }): PageLink =>
    pageLink(m.title(pages, issuer), m.url(), O.none, true);

const mkLinks = (
  issuer: Issuer,
  ffs: ClientFeatureFlags,
  pages: ReadonlyArray<PageConfig<PageU>>,
): ReadonlyArray<PageLink> => {
  const p = { issuerId: issuer.id, issuerSlug: issuer.slug } as const;
  return [
    ...RA.fromPredicate(() => jl.about.team.enabled(ffs))(mkLink(issuer, pages)(rm.team(p))),
    mkLink(issuer, pages)(rm.documentCategories(p)),
    ...RA.fromPredicate(() => jl.bonds.ratings.enabled(ffs))(mkLink(issuer, pages)(rm.ratings(p))),
    mkLink(issuer, pages)(rm.bonds(p)),
    ...RA.fromPredicate(() => jl.about.news.enabled(ffs))(mkLink(issuer, pages)(rm.newsAndEvents(p))),
    mkLink(issuer, pages)(rm.resources(p)),
    ...RA.fromPredicate(() => jl.emma.cusip6.enabled(ffs) || jl.emma.cusip9.enabled(ffs))(
      mkLink(issuer, pages)(rm.emmaLinks({ ...p, cusip9: O.none })),
    ),
  ];
};

export const PartnerLander = (props: { data: IssuerPartnerLanderPageData }) => {
  const config = useConfig();
  const issuer = useIssuerSitesSelector("issuer");
  const iffs = useIssuerSitesSelector("iffs");
  const pages = useIssuerSitesSelector("pages");
  const links = mkLinks(issuer, iffs, pages);
  const partner = props.data.partner;
  const [klass, render] = ((): [`lander-${"page" | "frame"}`, () => ReactElement] => {
    switch (partner._tag) {
      case "BondNav":
      case "Fidelity":
      case "ICE":
        return ["lander-page", () => <PartnerLanderPage partner={partner} data={props.data} links={links} />];

      case "Ipreo":
        return ["lander-frame", () => <PartnerLanderFrame partner={partner} data={props.data} links={links} />];

      default: return config.exhaustive(partner);
    }
  })();

  return <>
    <div className={klass}>{render()}</div>
    <div className="mt-3"><Footer disclaimerType="general" /></div>
  </>;
};
