
import type { Markdown as MarkdownType } from "@scripts/codecs/markdown";
import { constVoid, E, O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import type { BankRfpSitesData, DealFileRfpDetails } from "@scripts/generated/models/rfp";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { ButtonPrimary } from "@scripts/react/components/Button";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { FactsColumn, FactsRow, type FactType, makeFactO } from "@scripts/react/components/Facts";
import { RFPQuestionsAndAnswers } from "@scripts/react/components/Faq";
import { AccentDividerSection, DividerSection } from "@scripts/react/components/layout/Section";
import { LeafIcon } from "@scripts/react/components/LeafIcon";
import { Markdown } from "@scripts/react/components/Markdown";
import { SummaryRelatedContent } from "@scripts/react/components/offering-pages/RelatedContent";
import { TooltipO } from "@scripts/react/components/Tooltip";
import { RfpBadge } from "@scripts/react/rfp/rfpBadge";
import { klass } from "@scripts/react/util/classnames";
import { humanDateFull } from "@scripts/syntax/date/joda";
import { dateWithOptionalTimeToString } from "@scripts/syntax/dateWithOptionalTime";
import { currency } from "@scripts/util/currency";
import { prop } from "@scripts/util/prop";

import { DealViewDetailsBase } from "../offering-pages/BankOfferingContent";
type BankRFPData = BankRfpSitesData["rfp"]["data"]["data"]["record"]["data"]["rfp"];
const makeRowFacts = (rfp: BankRFPData) => RA.compact([
  makeFactO("Total Loan Amount", rfp.loanAmount, currency),
  makeFactO("Service Type", rfp.typeOfServicing, st => st.name),
  makeFactO("Bids Due", rfp.bidsDue, E.fold(dateWithOptionalTimeToString(humanDateFull), _ => _.format)),
]);

const makeColumnFacts = (rfp: BankRFPData) => RA.compact<FactType>([
  O.some({ title: "Sector", value: rfp.sector.name }),
  O.some({ title: "State", value: rfp.state.name }),
  makeFactO("Listing Date", rfp.dateListed, humanDateFull),
  makeFactO("Deadline for Questions", rfp.deadlineForQuestions, dateWithOptionalTimeToString(humanDateFull)),
  makeFactO(`${rfp.awardDateTentative ? "Tentative " : ""}Award Date`, rfp.awardDate, humanDateFull),
  makeFactO(`${rfp.contractStartDateTentative ? "Tentative " : ""}Contract Start Date`, rfp.contractStartDate, humanDateFull),
]);

export const RfpFacts = (props: { rfp: Rfp }) => {
  const rowFacts = pipe(props.rfp, makeRowFacts, RNEA.fromReadonlyArray);
  const colFacts = pipe(props.rfp, makeColumnFacts, RNEA.fromReadonlyArray);

  return <div {...klass("d-flex", "flex-col", "gap-1")}>
    {mapOrEmpty((facts: ReadonlyArray<FactType>) => <FactsRow variant="bold" items={facts} />)(rowFacts)}
    {mapOrEmpty((facts: ReadonlyArray<FactType>) => <FactsColumn items={facts} />)(colFacts)}
  </div>;
};

const RfpSummary = (props: {
  details: DealFileRfpDetails;
}) => {
  const { rfp, relatedContent } = props.details.rfpData.rfp.data.data.record.data;

  return <AccentDividerSection title={O.none} klasses={["pt-0"]}>
    <DividerSection title={O.none}>
      <div {...klass("mb-05")}><RfpBadge biddingState={rfp.biddingState} /></div>
      <h2>Summary</h2>
      <RfpFacts rfp={rfp} />
    </DividerSection>
    {mapOrEmpty((md: MarkdownType) =>
      <DividerSection
        klasses={O.none}
        title={O.some("Project Description")}
      >
        <Markdown content={md} />
      </DividerSection>
    )(rfp.projectDescription)}
    {mapOrEmpty((md: MarkdownType) =>
      <DividerSection
        klasses={O.none}
        title={O.some("Submission Requirements")}
      >
        <Markdown content={md} />
      </DividerSection>
    )(rfp.submissionRequirements)}
    <SummaryRelatedContent
      documentsO={RNEA.fromReadonlyArray(relatedContent.documents.map(prop("data")))}
      documentsHeadline="Documents"
      documentDownloadRoute={(issuerId, mediaId) => V2Router.investorPortalRfpsControllerDownloadDocument({ issuerId, rfpId: props.details.rfpData.rfp.data.data.id, mediaId })}
      issuer={props.details.issuer}
      linksO={RNEA.fromReadonlyArray(relatedContent.externalLinks)}
      variant="page"
      leafIcon={LeafIcon}
    />
  </AccentDividerSection>;
};

const DisabledContactUsCallout = () => <TooltipO tooltip={O.some({
  offset: [0, 24],
  delay: "default",
  description: {
    type: "DescriptionContent",
    text: "If this deal is live, this button is functional for site visitors on your deal page.",
  },
})}
>
  <ButtonPrimary
    disabled
    {...klass("mt-0")}
    onClick={constVoid}
  >
    Contact Us
  </ButtonPrimary>
</TooltipO>;

export const DealViewRfpDetails = (props: {
  details: DealFileRfpDetails;
  pages: ReadonlyArray<PageConfig<PageU>>;
}) => {
  const {
    officers,
    participants,
    issuerAboutText,
    projects,
  } = props.details.rfpData;

  return <DealViewDetailsBase
    header={O.none}
    appendedContent={O.some(<RFPQuestionsAndAnswers
      rfpData={props.details.rfpData.rfp.data.data.record.data}
      pages={props.pages}
      contactCallout={<DisabledContactUsCallout />}
    />)}
    issuer={props.details.issuer}
    pages={props.pages}
    leafIcon={LeafIcon}
    officers={RNEA.fromReadonlyArray(officers)}
    projects={RNEA.fromReadonlyArray(projects)}
    participants={pipe(
      participants,
      RNEA.fromReadonlyArray,
      O.map((a) => ({
        arr: a,
        title: "Participants",
      }))
    )}
    roadshowsDataO={pipe(
      props.details.rfpData.rfp.data.data.record.data.relatedContent.roadShows,
      RA.map(prop("data")),
      RNEA.fromReadonlyArray)
    }
    ratings={O.none}
    issuerAboutText={issuerAboutText}
    summary={<AccentDividerSection title={O.none} suppressDivider="xs">
      <RfpSummary details={props.details} />
    </AccentDividerSection>}
  />;
};
