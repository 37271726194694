import { type BLConfigWithLog, formatS3CdnUrl } from "@scripts/bondlink";
import { b, E, O, Ord, pipe, RA, RNEA, s } from "@scripts/fp-ts";
import { exitDisclaimer as exitDisclaimerFF } from "@scripts/generated/domaintables/featureFlags";
import { homepage } from "@scripts/generated/domaintables/pages";
import type { Issuer } from "@scripts/generated/models/issuer";
import { type RelatedIssuerWithIssuer } from "@scripts/generated/models/relatedIssuer";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { Anchor } from "@scripts/react/components/Anchor";
import { ButtonLink } from "@scripts/react/components/Button";
import { Card } from "@scripts/react/components/card/Card";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Grid, GridCol } from "@scripts/react/components/layout/Grid";
import { Svg } from "@scripts/react/components/Svg";
import { Tooltip } from "@scripts/react/components/Tooltip";
import { useConfig } from "@scripts/react/context/Config";
import { type Klass, klass, type KlassProp, klassPropO } from "@scripts/react/util/classnames";
import { useModal } from "@scripts/react/util/useModal";
import { openInNewTab } from "@scripts/routes/router";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { urlInterface } from "@scripts/routes/urlInterface";
import { isFFEnabled } from "@scripts/syntax/featureFlags";
import { issuerHomeUrl } from "@scripts/syntax/issuer";
import { tagEq } from "@scripts/util/compare";

import question from "@svgs/question.svg";

import { ExitDisclaimerModal } from "../../components/disclaimer/exitDisclaimerModal";
import { useIssuerSitesSelector } from "../../state/store";

type RelatedIssuer = E.Either<WithStatusU<RelatedIssuerWithIssuer>, Issuer>;

const foldRelatedIssuer = <A,>(
  onLeft: (issuer: WithStatusU<RelatedIssuerWithIssuer>) => A,
  onRight: (issuer: Issuer) => A
) => (relatedIssuer: RelatedIssuer) =>
    pipe(
      relatedIssuer,
      E.fold(
        onLeft,
        onRight
      )
    );

const relatedIssuerOrd = Ord.contramap(
  foldRelatedIssuer(
    _ => _.data.record.related.name,
    _ => _.name
  )
)(s.Ord);

const relatedIssuerUrl = (
  config: BLConfigWithLog,
  currentIssuer: Issuer,
  relatedIssuer: WithStatusU<RelatedIssuerWithIssuer>
): O.Option<string> =>
  pipe(
    relatedIssuer.data.record.issuer,
    O.fold(
      () => relatedIssuer.data.record.related.outsideLink,
      _ => pipe(
        _.published || !currentIssuer.published,
        b.fold(
          () => O.none,
          () => O.some(issuerHomeUrl(config)(_).url),
        )
      )
    )
  );

const RelatedIssuerCard = (props: { relatedIssuer: RelatedIssuer }) => {
  const config = useConfig();
  const iffs = useIssuerSitesSelector("iffs");
  const headerPhotos = useIssuerSitesSelector("headerPhotos");
  const issuer = useIssuerSitesSelector("issuer");

  const [modalOpen, openModal, closeModal] = useModal("Exit Disclaimer Modal");
  const getKlasses = (name: string): Klass[] => ["related-issuer"].concat(name.length > 70 ? ["long"] : []);

  return pipe(
    props.relatedIssuer,
    foldRelatedIssuer(
      _ => {
        const name = _.data.record.related.name;
        const klasses = getKlasses(name);
        return pipe(
          relatedIssuerUrl(config, issuer, _),
          O.fold(
            () => <Card klasses={["card-related-issuer inverted disabled"]}>
              <div {...klass(...klasses)}>
                <span {...klass("mr-025")}>{name}</span>
                <Tooltip
                  delay="default"
                  description={{
                    type: "DescriptionContent",
                    text: `${name} is a related issuer but does not currently have a public BondLink website.`,
                  }}
                >
                  <Svg src={question} {...klass("inverted")} />
                </Tooltip></div>
            </Card>,
            url => {
              const isExitDisclaimerEnabled = isFFEnabled(exitDisclaimerFF)(iffs);
              const route = urlInterface("GET", url);

              return <>
                <ExitDisclaimerModal
                  modalOpen={modalOpen}
                  dismissAction={closeModal}
                  outsideLink={url}
                />
                <Card
                  klasses={["card-related-issuer inverted card-hover card-link"]}
                  onClick={isExitDisclaimerEnabled ? openModal : () => openInNewTab(route.url)}
                >
                  {isExitDisclaimerEnabled
                    ? <ButtonLink {...klass(...klasses)} onClick={openModal}>{_.data.record.related.name}</ButtonLink>
                    : <Anchor
                      externalLinkLocation="none"
                      klasses={klasses}
                      route={{
                        title: _.data.record.related.name,
                        route,
                      }}
                      target="_blank"
                    />
                  }
                </Card>
              </>;
            }
          )
        );
      },
      _ => {
        const name = _.name;
        const klasses = getKlasses(name);
        return <div
          {...klass("card card-related-issuer inverted current")}
          style={pipe(
            headerPhotos,
            RA.findFirst(([page]) => tagEq().equals(page, homepage)),
            O.map(([, photo]) => photo),
            O.fold(
              () => ({}),
              image => ({
                backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${formatS3CdnUrl(config)(image.data.record.media.data.record.uploadResponse.uri)})`,
              })
            )
          )}
        >
          <div {...klass(...klasses)}>{_.name}</div>
        </div>;
      }
    )
  );
};

const RelatedIssuersGrid = (props: {
  relatedIssuers: RNEA.ReadonlyNonEmptyArray<RelatedIssuer>;
  klass?: KlassProp;
  variant: "xs" | "md" | "xl";
}) => <Grid
  attrs={O.some(props.variant === "xs" ? ".grid-sa-1" : props.variant === "md" ? ".grid-sa-15" : ".grid-sa-2")}
  klasses={klassPropO("")(props.klass).className}
>{props.relatedIssuers.map(relatedIssuer =>
  <GridCol
    key={pipe(relatedIssuer, foldRelatedIssuer(_ => `related-${_.data.id}`, _ => `current-${_.id}`))}
    cols={[".c-12", ".c-md-8", ".c-xl-6"]}
    klasses={O.none}
  >
    <RelatedIssuerCard relatedIssuer={relatedIssuer} />
  </GridCol>
)}</Grid>;

export const RelatedIssuers = () => {
  const pages = useIssuerSitesSelector("pages");
  const issuer = useIssuerSitesSelector("issuer");
  const relatedIssuers = useIssuerSitesSelector("relatedIssuers");

  return pipe(
    relatedIssuers,
    RNEA.fromReadonlyArray,
    O.map(RNEA.map(E.left)),
    O.map(RNEA.concat([E.right(issuer)])),
    O.map(RNEA.sort(relatedIssuerOrd)),
    mapOrEmpty(_ => <section {...klass("container")} id={jl.home.relatedIssuers.sectionId}>
      <h2 {...klass("title")}>{jl.home.relatedIssuers.text(pages)}</h2>
      <RelatedIssuersGrid klass={"d-md-none"} relatedIssuers={_} variant="xs" />
      <RelatedIssuersGrid klass={"d-none-until-md d-xl-none"} relatedIssuers={_} variant="md" />
      <RelatedIssuersGrid klass={"d-none-until-xl"} relatedIssuers={_} variant="xl" />
    </section>)
  );
};
