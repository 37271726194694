import type { ReactElement } from "react";
import { StrictMode } from "react";
import { Provider } from "react-redux";
import type { Store } from "redux";

import type { BLConfigWithLog } from "@scripts/bondlink";
import type { PhotoEditableU } from "@scripts/generated/domaintables/pages";
import { ErrorBoundary } from "@scripts/react/components/error/ErrorBoundary";
import { ConfigProvider } from "@scripts/react/context/Config";

import { parseLocation } from "../router";
import type { IssuerSitesState } from "../state/state";
import type { IssuerSitesActions } from "../state/store";
import { Page, type PageProps } from "./Page";

type PageLoaderProps = {
  config: BLConfigWithLog;
  render: () => ReactElement;
  pageTitle: string;
  photoPage: PhotoEditableU;
  store: Store<IssuerSitesState, IssuerSitesActions>;
  url: string;
  blpLoginRequired: PageProps["blpLoginRequired"];
};

export const PageLoader = (
  props: PageLoaderProps
) => {
  const sitesPageMeta = parseLocation(props.url);

  return (
    <StrictMode>
      <ErrorBoundary config={props.config}>
        <ConfigProvider value={props.config}>
          <Provider store={props.store}>
            <Page
              title={props.pageTitle}
              url={props.url}
              photoPage={props.photoPage}
              routeMeta={sitesPageMeta}
              blpLoginRequired={props.blpLoginRequired}
            >
              {props.render()}
            </Page>
          </Provider>
        </ConfigProvider>
      </ErrorBoundary>
    </StrictMode>
  );
};
