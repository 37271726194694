/* eslint-disable curly-quotes/no-straight-quotes */
import type { Eq } from "fp-ts/lib/Eq";
import * as Id from "fp-ts/lib/Identity";
import DOMPurify from "isomorphic-dompurify";
import * as HTMLParser from "node-html-parser";
import { regexp } from "regex-prepared-statements";
import * as MDtoHTML from "showdown";
import HTMLtoMD from "turndown";

import type { BLConfigWithLog } from "@scripts/bondlink";
import type { Html } from "@scripts/codecs/html";
import { htmlC } from "@scripts/codecs/html";
import type { Markdown } from "@scripts/codecs/markdown";
import { markdownC } from "@scripts/codecs/markdown";
import { E, flow, pipe, RA, s as S } from "@scripts/fp-ts";
import { declareTagNames } from "@scripts/react/util/dom";
import { htmlEmpty, markdownEmpty } from "@scripts/syntax/brandedStrings";
import { coerceHtmlAsString } from "@scripts/syntax/html";

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const eqMarkdown: Eq<Markdown> = S.Eq as unknown as Eq<Markdown>;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const coerceMarkdownAsString = (m: Markdown): string => m as unknown as string;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const coerceStringAsMarkdown = (s: string): Markdown => s as unknown as Markdown;

export const decodeMarkdownOrEmpty: (_: string) => Markdown = flow(
  markdownC.decode,
  E.getOrElse(() => markdownEmpty)
);

export const decodeHtmlOrEmpty: (_: string) => Html = flow(
  htmlC.decode,
  E.getOrElse(() => htmlEmpty)
);

export const siteLinks = (blConfig: BLConfigWithLog) => regexp(`^<_>.*`)(blConfig.baseUrl);
export const documentLinkRegex = /\/documents\/view-file\/i\d*(\?mediaId=.*)/;

const options: MDtoHTML.ConverterOptions = {
  simplifiedAutoLink: true,
  smoothLivePreview: true,
  // This is needed for tables to be parsed correctly:
  simpleLineBreaks: true,
  /*
  * Extensions are the last spot in the conversion process you can hook into and do post processing. The subparsers and cleanup
  * phase run after the extensions.
  * Valid values for type: "lang", "language", "output", "html", or "listener"
  */
  extensions: RA.toArray([
    /*
    * Prevent showdown from indenting html tags
    * Tinymce(TextEditor component) does not indent
    * the html to reduce the output
    */
    {
      type: "output",
      regex: /\s+</g,
      replace: "\n<",
    },
    /**
     * bl-image hack:
     *
     * During subparsing and cleanup the bl-image attribute was being removed.
     * To get around this we encode bl-image as a class so it doesn't get removed.
     */
    {
      type: "output",
      regex: /(bl-image=")(?=full|half|small)/g,
      replace: `class="`,
    },
  ]),
} as const;

const whitelist = {
  global: declareTagNames(
    "audio",
    "hr",
    "img",
    "video",
    "source",
  ),
  table: declareTagNames(
    "a",
    "blockquote",
    "br",
    "em",
    "h3",
    "h4",
    "hr",
    "img",
    "li",
    "ol",
    "p",
    "strong",
    "table",
    "tbody",
    "td",
    "th",
    "thead",
    "tr",
    "ul",
  ),
} as const;

const converter = pipe(
  new MDtoHTML.Converter(options),
  Id.chainFirst(c => c.setFlavor("github")),
);

// type "output" extensions for showdown are called the latest (right before cleanup). Sometimes the cleanup modifies the output with things we want to process out.
const restoreImageAttrs = flow(
  S.replace(/(<p><\/p>)/g, ""),
  /**
   * bl-image hack:
   *
   * After showdown hands us our html, we need to convert our bl-image classes back to a bl-image tag. Note that this
   * could get messy in the future if we have a use case in the future for classes AND bl-image.
   */
  S.replace(
    /(class=")(?=full|half|small)/g,
    `bl-image="`
  ),
);

const addExternalLinkTags = (htmlString: string, config: BLConfigWithLog) => {
  const doc = HTMLParser.parse(htmlString);
  const links = Array.from(doc.getElementsByTagName("a"));
  links.filter(
    link => !(siteLinks(config).test(link.getAttribute("href") || ""))
  ).forEach(
    link => {
      link.setAttribute("rel", "noopener noreferrer");
      link.setAttribute("target", "_blank");
    }
  );
  return doc.innerHTML;
};

const service = new HTMLtoMD()
  .addRule("keep", {
    filter: whitelist.global,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    replacement: (_, node) => (node as HTMLElement).outerHTML,
  })
  .addRule("table", {
    filter: "table",
    // inserting the table on a newline makes the RTE resilient to future changes
    replacement: (_, node) => S.prefix(`\n`)(parseTable(node)),
  });

const makeRow = (row: Node[]) => row.reduce(
  (markdown: string, curr: Node) => `${markdown} ${DOMPurify.sanitize(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (curr as HTMLElement).innerHTML.replace(/\|/g, "\\|"),
    { ALLOWED_TAGS: whitelist.table }
  )} |`,
  "|"
);

const parseTable = (tableNode: HTMLtoMD.Node) => {
  const thead = tableNode.childNodes[0];
  const tbody = tableNode.childNodes[1];

  const headMarkdown = `${makeRow(Array.from(thead?.childNodes[0]?.childNodes || []))}\n`;
  const separator = `${Array.from(thead?.childNodes[0]?.childNodes || []).reduce(
    (markdown: string) => `${markdown} --- |`,
    "|"
  )}\n`;
  const bodyMarkdown = Array.from(tbody?.childNodes || []).reduce(
    (markdown: string, curr: Node) => `${markdown}${makeRow(Array.from(curr.childNodes))}\n`,
    ""
  );

  return headMarkdown + separator + bodyMarkdown;
};

// Matches strings with the format "!![<CDN_URL>/<CLIENT_ID>/<FILE_NAME>.(mp3|mp4)]"
const audioVideoRegex = (ext: "mp3" | "mp4") => (cdn: string) =>
  regexp("!!\\[(<_>\\/\\d+\\/[^\\/]+\\.<_>)\\]", "gi")(cdn, ext);

const audioRegex = audioVideoRegex("mp3");
const videoRegex = audioVideoRegex("mp4");

const parseAudio = (config: BLConfigWithLog) => (html: string): string =>
  html.replace(audioRegex(config.s3.cdn), `<audio controls><source src="$1" type="audio/mpeg"></audio>`);

const parseVideos = (config: BLConfigWithLog) => (html: string): string =>
  html.replace(videoRegex(config.s3.cdn), `<video width="100%" controls><source src="$1" type="video/mp4"></video>`);

export const htmlToMarkdown = (config: BLConfigWithLog): (html: Html) => Markdown => flow(
  coerceHtmlAsString,
  parseAudio(config),
  parseVideos(config),
  service.turndown.bind(service),
  decodeMarkdownOrEmpty,
);

// Got the following error when using pipe (TypeError: Cannot read property '_dispatch' of undefined)
export const markdownToHtml = (config: BLConfigWithLog) => (markdown: Markdown): Html =>
  decodeHtmlOrEmpty(
    addExternalLinkTags(
      restoreImageAttrs(
        DOMPurify.sanitize(
          converter.makeHtml(
            coerceMarkdownAsString(markdown)
          ))), config));
