import { O, pipe } from "@scripts/fp-ts";
import { activeRfps, archivedRfps } from "@scripts/generated/domaintables/featureFlags";
import { rfpsPage } from "@scripts/generated/domaintables/pages";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { Anchor } from "@scripts/react/components/Anchor";
import { noneOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { RoadshowCard } from "@scripts/react/components/RoadshowCardGrid";
import type { RoadshowLanderData } from "@scripts/routes/routing/ssr/issuersites";
import { ifAnyEnabled } from "@scripts/syntax/featureFlags";
import { getCustomTitleOrName } from "@scripts/syntax/pageTitles";

import { DirectSitesPageLayoutBase } from "../../../components/DirectSitesPageLayout";
import { IssuerSitesLeafIcon } from "../../../components/LeafIcon";
import { useIssuerSitesSelector } from "../../../state/store";

const SidebarLink = (props: { contentType: "bonds" | "rfps" }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");

  return <div className={props.contentType === "rfps" ? "mt-05" : ""}>
    <Anchor
      target="_self"
      route={{
        title: props.contentType === "bonds" ? "View bonds" : `View ${getCustomTitleOrName(rfpsPage)(pages)}`,
        route: (props.contentType === "bonds"
          ? SR.issuersitesBondOfferingsControllerIndex
          : SR.issuersitesRfpsControllerRfps
        )({ issuerId: issuer.id, issuerSlug: issuer.slug }),
      }}
      arrowType="right"
    />
  </div>;
};

const SidebarLinks = () => {
  const iffs = useIssuerSitesSelector("iffs");

  return <>
    <SidebarLink contentType="bonds" />
    {pipe(ifAnyEnabled([activeRfps, archivedRfps])(iffs), trueOrEmpty(<SidebarLink contentType="rfps" />))}
  </>;
};

export const RoadshowLander = (props: { show: RoadshowLanderData }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const bank = useIssuerSitesSelector("bank");

  return <DirectSitesPageLayoutBase headerComponent={O.none} sidebarContent={pipe(bank, noneOrEmpty(SidebarLinks))}>
    <h2>{props.show.record.data.show.title}</h2>
    <RoadshowCard issuer={issuer} roadshow={props.show} leafIcon={IssuerSitesLeafIcon} />
  </DirectSitesPageLayoutBase>;
};
