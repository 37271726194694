import { O, pipe, RNEA } from "@scripts/fp-ts";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { BondOfferingWithRelatedContent, DirectOfferingData } from "@scripts/generated/models/bondOffering";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { DividerSection } from "@scripts/react/components/layout/Section";
import { Participants } from "@scripts/react/components/offering-pages/OfferingParticipants";
import { OfferingSummary } from "@scripts/react/components/offering-pages/OfferingSummary";
import { RatingsBlockSection } from "@scripts/react/components/offering-pages/Ratings";
import { makeRoadshowDataO, parseRoadshows, RoadshowsSection } from "@scripts/react/components/offering-pages/RelatedContent";
import type { DocumentDownloadRoute } from "@scripts/react/components/ratings/RatingBlock";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { offeringPage } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { relatedRatingsToCommonRatings } from "@scripts/syntax/rating";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";
import { isDrafted } from "@scripts/syntax/threadThrough";

import { useBondSubscribe } from "../../api/watchlist";
import { DirectSitesPageLayoutJumpLinks } from "../../components/DirectSitesPageLayout";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { GeneralOfferingInfo } from "../../components/offering-pages/GeneralOfferingInfo";
import { HeaderWithSubscription } from "../../components/offering-pages/Header";
import { BondOfferingContactModal, CusipsTable, makeEventsDataO, makeNewsDataO, makeProjectsDataO, RelatedContentSection, SharedRelatedContent } from "../../components/offering-pages/RelatedContent";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";
import { useOnSubscribe } from "../../syntax/onSubscribe";

const setupSidebarLinkSections = (
  offering: BondOfferingWithRelatedContent,
  ffs: ClientFeatureFlags,
  pages: ReadonlyArray<PageConfig<PageU>>,
): ReadonlyArray<JumpLink> =>
  makeSitesJumpLinks(ffs, pages)(jl.bondOffering.all(offering));

export const DirectOfferingPage = (props: DirectOfferingData) => {
  const iffs = useIssuerSitesSelector("iffs");
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");

  const makeOnSubscribe = useBondSubscribe();

  const [isSubscribed, onSubscribe] = useOnSubscribe(props.offering.subscribed, makeOnSubscribe, props.offering.data.data.id);

  const page = offeringPage({ issuerSlug: issuer.slug, issuerId: issuer.id, offeringId: props.offering.data.data.id });

  const roadshowDataO = makeRoadshowDataO(iffs, parseRoadshows(props.offering.data.data.record.data.relatedContent.roadShows));
  const offeringParticipantsDataO = RNEA.fromReadonlyArray(props.participants);
  const projectsDataO = makeProjectsDataO(iffs, props.offering.data.data.record.data.relatedContent.projects);
  const eventsDataO = makeEventsDataO(iffs, props.offering.data.data.record.data.relatedContent.events);
  const newsDataO = makeNewsDataO(iffs, props.offering.data.data.record.data.relatedContent.news);
  const cusipsDataO = RNEA.fromReadonlyArray(props.offering.data.data.record.data.relatedContent.cusip9s);
  const documentsDataO = RNEA.fromReadonlyArray(props.offering.data.data.record.data.relatedContent.documents.map(_ => _.data));
  const linksDataO = RNEA.fromReadonlyArray(props.offering.data.data.record.data.relatedContent.externalLinks);

  const relatedContentJL = jl.bondOffering.relatedContent(props.offering.data.data.record.data.relatedContent);

  const sidebarLinks = setupSidebarLinkSections(props.offering.data.data.record.data, iffs, pages);

  const documentDownloadRoute: DocumentDownloadRoute = (id) => SR.issuersitesReportsControllerDownloadRedirect({ mediaId: id, issuerId: issuer.id, issuerSlug: issuer.slug });

  return <DirectSitesPageLayoutJumpLinks
    headerComponent={
      <HeaderWithSubscription
        isSubscribed={isSubscribed}
        onSubscribe={onSubscribe}
        taggedContent={O.some(props.offering.data.data.record)}
        title={props.offering.data.data.record.data.offering.name}
        viewAllRoute={O.some({
          urlInterface: SR.issuersitesBondOfferingsControllerIndex({ issuerSlug: issuer.slug, issuerId: issuer.id }),
          itemType: O.some("Bonds"),
        })}
        subscribeDisabled={isDrafted(props.offering.data)}
      />
    }
    sidebarContent={<>
      <SidebarLinksSites
        headerLinkAnchorContent={props.offering.data.data.record.data.offering.name}
        jumpLinks={sidebarLinks}
        routeMeta={page}
      />
      <GetAlertsActionSection containerKlass={"d-none-until-md"} />
    </>}
  >
    <OfferingSummary
      offering={props.offering}
      sectionId={jl.bondOffering.offeringSummary.sectionId}
      documentsSectionId={jl.relatedDocumentsJumplinkId}
      issuer={issuer}
      contactModal={O.some(<BondOfferingContactModal offering={props.offering} />)}
      documentsO={documentsDataO}
      linksO={linksDataO}
      klasses={"accent-border-top"}
      leafIcon={IssuerSitesLeafIcon}
      pages={pages}
    />
    <RatingsBlockSection
      sectionId={jl.bondOffering.ratings(props.offering.data.data.record.data.relatedContent.ratings).sectionId}
      bondRatings={relatedRatingsToCommonRatings(props.offering.data.data.record.data.relatedContent.ratings)}
      program={props.offering.data.data.record.data.relatedContent.program}
      issuer={issuer}
      pages={pages}
      isBLP={false}
      documentDownloadRoute={documentDownloadRoute}
      issuerRatings={props.issuerRatings}
    />
    <RoadshowsSection
      issuer={issuer}
      pages={pages}
      roadshows={roadshowDataO}
      sidebarLinkHandle={jl.bondOffering.roadshows(props.offering.data.data.record.data.relatedContent.roadShows).sectionId}
      leafIcon={IssuerSitesLeafIcon}
    />
    {pipe(
      offeringParticipantsDataO,
      mapOrEmpty(offeringParticipants => {
        const l = jl.bondOffering.participants(props.offering.data.data.record.data.relatedContent.participants);
        return <Participants
          participants={offeringParticipants}
          sidebarLinkHandle={l.sectionId}
          title={l.text(pages)}
        />;
      }
      )
    )}
    {pipe(
      relatedContentJL.enabled(iffs),
      trueOrEmpty(
        <RelatedContentSection>
          {pipe(
            cusipsDataO,
            mapOrEmpty(cusips =>
              <DividerSection title={O.some("CUSIPs")}>
                <CusipsTable cusips={cusips} />
              </DividerSection>
            )
          )}
          <SharedRelatedContent
            events={eventsDataO}
            news={newsDataO}
            projects={projectsDataO}
          />
        </RelatedContentSection>
      )
    )}
    {pipe(
      props.offeringInfo,
      mapOrEmpty(offeringInfo => <GeneralOfferingInfo offeringInfo={offeringInfo} />)
    )}
  </DirectSitesPageLayoutJumpLinks>;
};
