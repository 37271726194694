import type { O } from "@scripts/fp-ts";
import { pipe } from "@scripts/fp-ts";
import type { EmmaLinksPageData } from "@scripts/generated/models/cusip";
import { trueOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { emmaLinks } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { ContactUsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";
import { Cusip6Table } from "./Cusip6Table";
import { Cusip9Table } from "./Cusip9Table";

export const EmmaLinks = (props: {
  data: EmmaLinksPageData;
  searchedCusip9: O.Option<string>;
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");
  const page = emmaLinks({ issuerSlug: issuer.slug, issuerId: issuer.id, cusip9: props.searchedCusip9 });
  const description = page.description(pages, iffs, issuer);
  const jumpLinks = makeSitesJumpLinks(iffs, pages)(jl.emma.allWithCustomTitles);

  return <DirectSitesPageDescriptionLayout
    description={description}
    sidebarContent={
      <>
        <SidebarLinksSites
          headerLinkAnchorContent={page.title(pages)}
          jumpLinks={jumpLinks}
          routeMeta={page}
        />
        <ContactUsActionSection containerKlass="d-none-until-md" />
      </>
    }
  >
    {pipe(jl.emma.cusip6.enabled(iffs), trueOrEmpty(<AccentDividerSection jumpLink={jl.emma.cusip6} pages={pages} klasses="accent-border-top">
      <Cusip6Table cusip6s={props.data.cusip6s} />
    </AccentDividerSection>))}
    {pipe(jl.emma.cusip9.enabled(iffs), trueOrEmpty(<AccentDividerSection jumpLink={jl.emma.cusip9} pages={pages} klasses="accent-border-top">
      <Cusip9Table cusip9s={props.data.cusip9s} searchedCusip9={props.data.searchedCusip9} />
    </AccentDividerSection>))}
    <ContactUsActionSection containerKlass="mt-2 d-md-none" />
  </DirectSitesPageDescriptionLayout>;
};
