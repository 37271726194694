import { useCallback } from "react";

import { gtagClickEvent } from "@scripts/analytics";
import type { ErrorHandlerApiReq, TERespWithEffect } from "@scripts/api/methods";
import { apiFetchWithCredResp } from "@scripts/api/methods";
import type { TE } from "@scripts/fp-ts";
import type { EmptyObject } from "@scripts/generated/models/emptyObject";
import * as V2Router from "@scripts/generated/routers/v2Router";
import type { OnSubscribe } from "@scripts/react/components/SubscriptionToggle";
import type { TableRowModel } from "@scripts/react/components/table/tableSyntax";
import { useConfig } from "@scripts/react/context/Config";

export type OnSubscribeFromRow<RowModel, MetaData> =
  (row: TableRowModel<RowModel, MetaData>) => OnSubscribe;

export const gaWatchlist = (isSubscribing: boolean): void => {
  gtagClickEvent("button")(`Subscribe Clicked: ${isSubscribing ? "add" : "remove"}`);
};

const useUpdateOfferingSubscription = (): (params: V2Router.InvestorPortalOfferingsControllerUpdateSubscriptionParams) => TERespWithEffect<EmptyObject> => {
  const config = useConfig();

  const updateOfferingSubscription = useCallback((params: V2Router.InvestorPortalOfferingsControllerUpdateSubscriptionParams) =>
    apiFetchWithCredResp(config)(
      V2Router.investorPortalOfferingsControllerUpdateSubscription(params),
      { headers: { "Csrf-Token": config.csrf } }
    )({}), [config]);

  return updateOfferingSubscription;
};

const useUpdateRfpSubscription = (): (params: V2Router.InvestorPortalRfpsControllerUpdateSubscriptionParams) => TERespWithEffect<EmptyObject> => {
  const config = useConfig();

  const updateRfpSubscription = useCallback((params: V2Router.InvestorPortalRfpsControllerUpdateSubscriptionParams) =>
    apiFetchWithCredResp(config)(
      V2Router.investorPortalRfpsControllerUpdateSubscription(params),
      { headers: { "Csrf-Token": config.csrf } }
    )({}), [config]);

  return updateRfpSubscription;
};

const useUpdateSectorSubscription = (): (params: V2Router.InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionParams) => TERespWithEffect<EmptyObject> => {
  const config = useConfig();

  const updateSectorSubscription = useCallback((params: V2Router.InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionParams) =>
    apiFetchWithCredResp(config)(
      V2Router.investorPortalNotificationSubscriptionsControllerUpdateSectorSubscription(params)
    )({}), [config]);

  return updateSectorSubscription;
};

const useUpdateStateSubscription = (): (params: V2Router.InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionParams) => TERespWithEffect<EmptyObject> => {
  const config = useConfig();

  const updateStateSubscription = useCallback((params: V2Router.InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionParams) =>
    apiFetchWithCredResp(config)(
      V2Router.investorPortalNotificationSubscriptionsControllerUpdateStateSubscription(params)
    )({}), [config]);

  return updateStateSubscription;
};

const useUpdateIssuerSubscription = (): (params: V2Router.InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionParams) => TERespWithEffect<EmptyObject> => {
  const config = useConfig();

  const updateIssuerSubscription = useCallback((params: V2Router.InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionParams) =>
    apiFetchWithCredResp(config)(
      V2Router.investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscription(params)
    )({}), [config]);

  return updateIssuerSubscription;
};

const useUpdateAllIssuerSubscriptions = (): (issuerIds: ReadonlyArray<number>, enabled: boolean) => TERespWithEffect<EmptyObject> => {
  const config = useConfig();

  const updateAllIssuerSubscriptions = useCallback((issuerIds: ReadonlyArray<number>, enabled: boolean) =>
    apiFetchWithCredResp(config)(
      V2Router.investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptions()
    )({ issuerIds, enabled }), [config]);

  return updateAllIssuerSubscriptions;
};

export type ErrorHandler = <A >(apiReq: ErrorHandlerApiReq<A>) => TE.TaskEither<unknown, unknown>;

export type MakeDealSubscribeRequest<IdKey extends string> = (
  params: { [K in IdKey]: number } & { enabled: boolean },
  handleError: ErrorHandler
) => TE.TaskEither<unknown, unknown>;

export const useRfpSubscribeRequest = (): MakeDealSubscribeRequest<"rfpId"> => {
  const updateRfpSubscription = useUpdateRfpSubscription();

  const makeRfpSubscribeRequest = useCallback((
    params: V2Router.InvestorPortalRfpsControllerUpdateSubscriptionParams,
    handleError: ErrorHandler,
  ) => handleError(updateRfpSubscription(params)), [updateRfpSubscription]);

  return makeRfpSubscribeRequest;
};

export const useBondSubscribeRequest = (): MakeDealSubscribeRequest<"offeringId"> => {
  const updateOfferingSubscription = useUpdateOfferingSubscription();

  const makeBondSubscribeRequest = useCallback((
    params: V2Router.InvestorPortalOfferingsControllerUpdateSubscriptionParams,
    handleError: ErrorHandler
  ) => handleError(updateOfferingSubscription(params)), [updateOfferingSubscription]);

  return makeBondSubscribeRequest;
};

export const useIssuerSubscribeRequest = (): (
  params: V2Router.InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionParams,
  handleError: ErrorHandler,
) => TE.TaskEither<unknown, unknown> => {
  const updateIssuerSubscription = useUpdateIssuerSubscription();

  const makeIssuerSubscribeRequest = useCallback((
    params: V2Router.InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionParams,
    handleError: ErrorHandler,
  ) => handleError(updateIssuerSubscription(params)), [updateIssuerSubscription]);

  return makeIssuerSubscribeRequest;
};

export const useIssuerSubscribeAllRequest = (): (
  issuerIds: ReadonlyArray<number>,
  isSubscribing: boolean,
  handleError: ErrorHandler,
) => TE.TaskEither<unknown, unknown> => {
  const updateAllIssuerSubscription = useUpdateAllIssuerSubscriptions();

  const makeIssuerSubscribeAllRequest = useCallback((
    issuerIds: ReadonlyArray<number>,
    isSubscribing: boolean,
    handleError: ErrorHandler,
  ) => handleError(updateAllIssuerSubscription(issuerIds, isSubscribing)), [updateAllIssuerSubscription]);

  return makeIssuerSubscribeAllRequest;
};

export const useSectorSubscribeRequest = (): (
  params: V2Router.InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionParams,
  handleError: ErrorHandler,
) => TE.TaskEither<unknown, unknown> => {
  const updateSectorSubscription = useUpdateSectorSubscription();

  const makeSectorSubscribeRequest = useCallback((
    params: V2Router.InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionParams,
    handleError: ErrorHandler,
  ) => handleError(updateSectorSubscription(params)), [updateSectorSubscription]);

  return makeSectorSubscribeRequest;
};

export const useStateSubscribeRequest = (): (
  params: V2Router.InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionParams,
  handleError: ErrorHandler,
) => TE.TaskEither<unknown, unknown> => {
  const updateStateSubscription = useUpdateStateSubscription();

  const makeStateSubscribeRequest = useCallback((
    params: V2Router.InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionParams,
    handleError: ErrorHandler,
  ) => handleError(updateStateSubscription(params)), [updateStateSubscription]);

  return makeStateSubscribeRequest;
};
