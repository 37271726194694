import type { BLConfigWithLog } from "@scripts/bondlink";
import type { RelativeUrl } from "@scripts/codecs/relativeUrl";
import { O, pipe } from "@scripts/fp-ts";
import type { Bank } from "@scripts/generated/models/bank";
import type { Issuer } from "@scripts/generated/models/issuer";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { absoluteUrl } from "@scripts/util/url";

export const clientPortalUrl = (
  config: BLConfigWithLog,
  bank: O.Option<Bank>,
  issuer: Issuer,
  redirect: O.Option<RelativeUrl>,
) => pipe(
  bank,
  O.fold(
    () => V2Router.issuerPortalIssuerControllerSwitchTo({ issuerId: issuer.id, redirect }),
    b => V2Router.dealPortalBankerControllerSwitchTo({ bankId: b.id, redirect }),
  ),
  absoluteUrl(config),
);

export const addQueryParams = (url: URL, query: URLSearchParams): URL => {
  query.forEach((value, key) => {
    if (!url.searchParams.has(key)) {
      url.searchParams.append(key, value);
    }
  });
  return url;
};
