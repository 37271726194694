import { formatS3CdnUrl } from "@scripts/bondlink";
import { O, pipe } from "@scripts/fp-ts";
import { AnchorMailTo } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { useConfig } from "@scripts/react/context/Config";
import { fullName } from "@scripts/syntax/fullName";

import { useIssuerSitesSelector } from "../../state/store";

export const PartnerLanderOfficer = () => {
  const config = useConfig();
  const prefs = useIssuerSitesSelector("prefs");
  const primaryOfficer = useIssuerSitesSelector("primaryOfficer");
  const show = pipe(prefs, O.exists(_ => O.isSome(_.customHeaderText1))) || pipe(primaryOfficer, O.exists(_ => _.data.record.officerPageVisibility));

  return O.fromPredicate(() => show)(
    <div className="contact-info">
      {pipe(
        O.Do,
        O.apS("o", primaryOfficer),
        O.bind("m", ({ o }) => o.data.record.media),
        mapOrEmpty(({ o, m }) =>
          <div className="mb-1">
            <img
              id="officer-img"
              src={formatS3CdnUrl(config)(m.uploadResponse.uri)}
              alt={O.getOrElse(() => fullName(o.data.record))(m.altText)}
            />
          </div>
        )
      )}
      {pipe(
        prefs,
        O.chain(_ => _.customHeaderText1),
        O.fold(
          () => pipe(primaryOfficer, mapOrEmpty(_ =>
            <>
              <strong>{fullName(_.data.record)}</strong>
              <p>{_.data.record.title}</p>
              {pipe(_.data.record.phoneNumber, mapOrEmpty(p =>
                <p>{p}{pipe(_.data.record.phoneExtension, O.fold(() => "", e => ` ext: ${e}`))}</p>
              ))}
              {pipe(_.data.record.email, mapOrEmpty(e => <p><AnchorMailTo email={e} /></p>))}
            </>
          )),
          _ => <strong>{_}</strong>,
        )
      )}
    </div>
  );
};
