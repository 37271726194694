import { useCallback, useMemo, useState } from "react";
import { Carousel as RRCarousel, type CarouselProps as RRCarouselProps } from "react-responsive-carousel";

import { constEmpty } from "./Empty";


export const useCarouselCurrentIndex = (total: number) => {
  const [currentItem, setCurrentItem] = useState(0);

  const hasPrev = useMemo(() => currentItem > 0, [currentItem]);
  const hasNext = useMemo(() => currentItem < total - 1, [currentItem, total]);

  const next = useCallback(() => {
    if (hasNext) {
      setCurrentItem(currentItem + 1);
    }
  }, [currentItem, hasNext]);

  const prev = useCallback(() => {
    if (hasPrev) {
      setCurrentItem(currentItem - 1);
    }
  }, [currentItem, hasPrev]);

  return [currentItem, prev, next, hasPrev, hasNext] as const;
};
type CarouselControlledProps = Pick<RRCarouselProps, "children"> & {
  currentItem: number;
};

export const CarouselControlled = (props: CarouselControlledProps) => {
  return <RRCarousel
    renderArrowNext={constEmpty}
    renderArrowPrev={constEmpty}
    selectedItem={props.currentItem}
    showArrows={false}
    showIndicators={false}
    showStatus={false}
    showThumbs={false}
  >
    {props.children}
  </RRCarousel>;
};
