import type { PropsWithChildren } from "react";

import type { SVGString } from "*.svg";

import type { ReactChild } from "@scripts/react/syntax/react";
import { klass, type KlassProp, klassPropO } from "@scripts/react/util/classnames";

import { Svg } from "../Svg";

type ContentTypeAccentColor = "default" | "green" | "red" | "accent-2";

type ContentTypeIconBackgroundColor = "green" | "red" | "white" | "bl-gradient";

export type ContentTypeIconProps = {
  backgroundColor: ContentTypeIconBackgroundColor;
  icon: SVGString;
  klass?: KlassProp;
};

export const ContentTypeIcon = (props: ContentTypeIconProps) => (
  <div {...klassPropO(["icon-circle", "content-type-details-badge", `bg-${props.backgroundColor}`])(props.klass)}>
    <Svg src={props.icon} />
  </div>
);

type ContentTypeDetailsProps = PropsWithChildren<{
  subheader: string;
  date: ReactChild;
  icon: SVGString;
  accentColor: ContentTypeAccentColor;
  iconBackgroundColor: ContentTypeIconBackgroundColor;
}>;

export const ContentTypeDetails = (props: ContentTypeDetailsProps) => {
  return (
    <div {...klass("content-type-details", `accent-${props.accentColor}`)}>
      <div {...klass("d-flex", "flex-grow", "title-container")}>
        <ContentTypeIcon icon={props.icon} backgroundColor={props.iconBackgroundColor} />
        <div>
          <h4 {...klass("date")}>{props.date}</h4>
          <div {...klass("content-type")}>{props.subheader}</div>
        </div>
      </div>
      {props.children}
    </div>
  );
};
