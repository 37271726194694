import { formatS3CdnUrl } from "@scripts/bondlink";
import { O } from "@scripts/fp-ts";
import { useConfig } from "@scripts/react/context/Config";
import type { IrmaLetterPageData } from "@scripts/routes/routing/ssr/issuersites";

import { DirectSitesPageLayoutBase } from "../../components/DirectSitesPageLayout";
import { FileViewerWrapped } from "../../components/FileViewer";
import { DocumentSidebarContentBase } from "./ViewFile";

export const IrmaLetterPage = (props: { data: IrmaLetterPageData }) => {
  const config = useConfig();

  return <DirectSitesPageLayoutBase
    headerComponent={O.none}
    sidebarContent={<DocumentSidebarContentBase document={props.data.data} />}
    appendRightGrid
  >
    <FileViewerWrapped url={formatS3CdnUrl(config)(props.data.data.record.uploadResponse.uri)} />
  </DirectSitesPageLayoutBase>;
};
