import type { PropsWithChildren, ReactElement } from "react";
import Skeleton from "react-loading-skeleton";

import { E, O, pipe } from "@scripts/fp-ts";
import { Anchor } from "@scripts/react/components/Anchor";
import { ButtonLink } from "@scripts/react/components/Button";
import { Card, CardBody } from "@scripts/react/components/card/Card";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { klass, type KlassProp, klassPropO } from "@scripts/react/util/classnames";
import type { PageRouteLink } from "@scripts/routes/routing/base";

type ButtonAction = { text: string, onClick: () => void };

type Action = E.Either<PageRouteLink, ButtonAction>;

type FeaturedItemCardProps = {
  badge: ReactElement;
  date: O.Option<string>;
  klass?: KlassProp;
  action: O.Option<Action>;
};

export const FeaturedItemCard = (props: PropsWithChildren<FeaturedItemCardProps>) =>
  <Card klasses={klassPropO(["card-hover card-link"])(props.klass).className}>
    <div {...klass("content-type-details")}>
      {props.badge}
    </div>
    <CardBody>
      <div {...klass("mb-05", "truncate", "truncate-3")}>
        {props.children}
      </div>
      {pipe(props.date, mapOrEmpty(_ => <p {...klass("small gray-600 mb-1")}>{_}</p>))}
    </CardBody>
    {mapOrEmpty((a: Action) => <div>
      {E.fold(
        (r: PageRouteLink) => <Anchor klasses={["mt-0"]} target={"_self"} route={r} arrowType="right" />,
        (b: ButtonAction) => <ButtonLink {...klass("link-arrow-right")} onClick={b.onClick}>{b.text}</ButtonLink>
      )(a)}
    </div>)(props.action)}
  </Card>;

export const FeaturedItemCardSkeleton = () =>
  <Card klasses={O.some("card-border")}>
    <Skeleton {...klass("mb-1")} width="6rem" />
    <CardBody>
      <Skeleton count={3} />
    </CardBody>
    <Skeleton {...klass("mt-1")} width="10rem" />
  </Card>;
