import type { Dispatch, PropsWithChildren, ReactElement, SetStateAction } from "react";
import { useCallback, useEffect, useState } from "react";
import type { Column, IntegratedSorting, Sorting, SortingDirection } from "@devexpress/dx-react-grid";
import type { PagingPanel, Table as TableGrid, TableHeaderRow } from "@devexpress/dx-react-grid-bootstrap4";
import type { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import * as A from "fp-ts/lib/Array";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import type { Ord } from "fp-ts/lib/Ord";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as RNA from "fp-ts/lib/ReadonlyNonEmptyArray";
import * as TE from "fp-ts/lib/TaskEither";
import * as TH from "fp-ts/lib/These";
import type * as t from "io-ts";
import type { Lens } from "monocle-ts";

import type { ErrorHandlerApiReq } from "@scripts/api/methods";
import type { RespOrErrors } from "@scripts/fetch";
import { R, Struct, Th } from "@scripts/fp-ts";
import type { Without } from "@scripts/fp-ts/struct";
import type { SortDocCategoriesC } from "@scripts/generated/models/document";
import type { SortItemsC } from "@scripts/generated/models/sortItems";
import type { ReactChild, ReactText } from "@scripts/react/syntax/react";
import type { Klass } from "@scripts/react/util/classnames";
import { debounce } from "@scripts/util/debounce";
import { tap } from "@scripts/util/tap";
import type { ExcelExportColumn } from "@scripts/util/xlsx/syntax";

import { mapOrEmpty } from "../Empty";
import type { SelectValueOnChange } from "../form/Select";
import { Paginator } from "../paginator/Paginator";
import type { ExportType } from "./DownloadDataButton";
import type { TableExporterRef } from "./TableExporter";

export type ExpandedRowIds = number[];

export type SortColumnOption<SB> = Sorting & { title: string, value: SB, default: boolean };

type TableColumnExtension = TableGrid.ColumnExtension & { wbrEnabled?: true };

export type TableColumnState = {
  columns: ReadonlyArray<Column>;
  columnExtensions: TableColumnExtension[];
};

export type HeaderCellProps<A, ColumnType, MetaData> =
  PropsWithChildren<TableHeaderRow.CellProps & { column: TableColumnUnion<A, ColumnType, MetaData> }>;

export type TableColumnDataCell<A, MetaData> = {
  dataCellComponent: (t: TableRowModel<A, MetaData>, expandedRowIds: number[]) => ReactChild;
  excludeFromSearch?: true;
};

export type HeaderComponent<A, MetaData> = "empty" | "title" | ((p: TableRowModel<A, MetaData>) => ReactElement);

export type CustomTableComponent<A, MetaData> = {
  title: string;
  dataCellKlass: Klass;
  headerCellKlass?: Klass;
  headerComponent: HeaderComponent<A, MetaData>;
  columnExtension?: Omit<TableColumnExtension, "columnName">;
} & Partial<TableColumnDataCell<A, MetaData>>;

type CustomTableComponentSortable<A, ColumnType, MetaData> = CustomTableComponent<A, MetaData> & {
  sort: { direction: SortingDirection | "both", ord: Ord<ColumnType>, customSortTitle?: string };
};

export type TableSortColumnName<ColName extends string> = `${ColName}-${SortingDirection}`;

type TableColumnUnion<A, ColumnType, MetaData> =
  & Omit<Column, "name">
  & (CustomTableComponent<A, MetaData> | CustomTableComponentSortable<A, ColumnType, MetaData>);

type TableRowKlass = "disabled" | "muted" | "draft" | "row-selected" | "row-input" | "matured" | "rejected" | "pending";
export type TableRowKlassOption = O.Option<TableRowKlass>;

export type TableRowMeta<MetaData> = {
  __metadata: MetaData;
  __klass: TableRowKlass | TableRowKlassOption | ReadonlyArray<TableRowKlass | TableRowKlassOption>;
  __rowId: number;
};

export type TableRowModel<A, MetaData> = A & TableRowMeta<MetaData>;
type TableColumnSchema<A, ExtraColumns extends string[]> = A & { [ECK in ExtraColumns[number]]: unknown };

type TableColsExcludedFromSearch = "actions" | "details";

type TableColumnRules<K, ColumnType, Row> = (
  K extends TableColsExcludedFromSearch
  ? { excludeFromSearch: true, dataCellComponent: (t: Row) => ReactChild }
  : ColumnType extends ReactText
  ? object
  : { dataCellComponent: (t: Row) => ReactChild }
);

type TableColumn<A, MetaData, ExtraColumns extends string[]> = {
  [K in keyof TableColumnSchema<A, ExtraColumns>]: TableColumnRules<K, TableColumnSchema<A, ExtraColumns>[K], TableRowModel<A, MetaData>> & TableColumnUnion<A, TableColumnSchema<A, ExtraColumns>[K], MetaData>
};

export type TableColumnRow<A, MetaData, ExtraColumns extends string[]> = {
  Columns: TableColumn<A, MetaData, ExtraColumns>;
  Row: TableRowModel<A, MetaData>;
};

export type GetCellValue<A, MetaData> =
  (row: A & TableRowMeta<MetaData>, columnName: string, type?: ExportType) => string | number;

export type TableColumnsExport<
  A,
  MetaData,
  ExportExtraColumns extends string[],
  ExportIgnoreColumns extends Array<keyof A>,
  _ColumnSchema = TableColumnSchema<A, ExportExtraColumns>,
  ColumnSchema = Without<_ColumnSchema, ExportIgnoreColumns[number]>,
> = {
    [K in keyof ColumnSchema]: ColumnSchema[K] extends ReactText
      ? Omit<Column, "name" | "getCellValue"> & { getCellValue?: GetCellValue<A, MetaData> } & ExcelExportColumn
      : Omit<Column, "name" | "getCellValue"> & { getCellValue: GetCellValue<A, MetaData> } & ExcelExportColumn
  };

export type RowSortApi<A extends t.Mixed | SortItemsC, R extends t.Mixed> = {
  body: t.TypeOf<A>;
  request: (data: t.TypeOf<A>) => ErrorHandlerApiReq<t.TypeOf<R>>;
};

export type OnParamsChanged<SB extends string, P extends object = object> = (p: TableUrlParams<SB, P>) => void;

export type TableUrlParams<SB extends string, P extends object = object> = {
  search?: string;
  page?: number;
  sortBy?: SB;
} & Partial<P>;

export type ExporterBaseProps<
  A,
  MetaData,
  ExportExtraColumns extends string[],
  ExportIgnoreColumns extends Array<keyof A>,
> = {
  setExporter: (exporter: TableExporterRef) => void;
  fileName: string;
  columns: TableColumnsExport<A, MetaData, ExportExtraColumns, ExportIgnoreColumns>;
};

export type ExporterCustomProps<
  A,
  MetaData,
  ExportExtraColumns extends string[],
  ExportIgnoreColumns extends Array<keyof RowParserOutput>,
  RowParserOutput,
> = {
  rowsParser: (rows: ReadonlyArray<TableColumnRow<A, MetaData, ExportExtraColumns>["Row"]>) => ReadonlyArray<RowParserOutput>;
  setExporter: (exporter: TableExporterRef) => void;
  fileName: string;
  columns: TableColumnsExport<RowParserOutput, MetaData, ExportExtraColumns, ExportIgnoreColumns>;
};

export type SortBy<ColumnName> = ColumnName extends string & ColumnName ? `${string & ColumnName}-asc` | `${string & ColumnName}-desc` : never;
export type SortByVal<SB extends SortBy<keyof A>, A> = { sortBy?: SB };

export type TableExporter<A, MetaData, ExportExtraColumns extends string[], RowParserOutput> =
  ExporterBaseProps<A, MetaData, ExportExtraColumns, Array<keyof A>> |
  ExporterCustomProps<A, MetaData, ExportExtraColumns, Array<keyof RowParserOutput>, RowParserOutput>;

export type TableSharedProps<
  A,
  MetaData,
  ExtraColumns extends string[],
  ExportExtraColumns extends string[],
  RowParserOutput,
> = {
  columns: TableColumnRow<A, MetaData, ExtraColumns>["Columns"];
  isHeaderless?: true;
  exporter: O.Option<TableExporter<A, MetaData, ExportExtraColumns, RowParserOutput>>;
};
export type ApiSortType<A extends t.Mixed | SortItemsC> = RowSortApi<A, t.Mixed>;
type ApiSortOnFailure = () => void;
export type ApiSortOnSuccess = () => void;
export type ApiSort<A, MetaData, SortType extends SortItemsC | SortDocCategoriesC> = {
  onDrag: (list: RNA.ReadonlyNonEmptyArray<TableRowModel<A, MetaData>>) => ApiSortType<SortType>;
  onFailure: ApiSortOnFailure;
  onSuccess: ApiSortOnSuccess;
  kind: "api-sort";
  error: boolean;
};

export type SetData<A, MetaData> = (list: ReadonlyArray<TableRowModel<A, MetaData>>) => void;

export const parseTableColumns = <T, MetaData, ExportExtraColumns extends string[], RowParserOutput>(
  cols: TableExporter<T, MetaData, ExportExtraColumns, RowParserOutput>["columns"]
): ReadonlyArray<Column & ExcelExportColumn> => pipe(
  Object.entries<ExcelExportColumn & Omit<Column, "name">>(cols),
  A.map(([k, v]) => ({ ...v, name: k }))
);

export const formatColumns = <
  A,
  MetaData,
  ExtraColumns extends string[],
  >(columns: TableColumnRow<A, MetaData, ExtraColumns>["Columns"]): TableColumnState => pipe(
  columns,
  R.toEntriesNoSort,
  c => ({
    columns: pipe(c, RA.map(([k, v]) => ({ name: k, ...v }))),
    columnExtensions: pipe(
      c,
      A.filterMap(([k, v]) => pipe(
        O.fromNullable(v.columnExtension),
        O.map(e => ({ columnName: k, ...e }))
      ))
    ),
  })
);

export const columnValueFormat = <K extends string>(col: { columnName: K, direction: SortingDirection }): `${K}-${SortingDirection}` => `${col.columnName}-${col.direction}`;

export const sortLabelFormat = <SB,>(option: SortColumnOption<SB>, _: number, allOptions: Array<SortColumnOption<SB>>) => {
  const label = allOptions.filter(ao => ao.columnName === option.columnName).length > 1
    ? `${option.title} ${option.direction === "asc" ? "↑" : "↓"}`
    : option.title;
  return { value: option.value, label: label };
};

const isCustomTableComponentSortable = (c: unknown): c is CustomTableComponentSortable<unknown, unknown, unknown> => {
  return Struct.is(c) && "sort" in c && typeof c["sort"] === "object";
};

export const isDataCellComponent = <A, MetaData>(column: Partial<TableColumnDataCell<A, MetaData>>): column is TableColumnDataCell<A, MetaData> =>
  "dataCellComponent" in column && column.dataCellComponent != null;

function TablePagingPanel<SB extends string, P extends object = object>(
  props: {
    params: TableUrlParams<SB, P>;
    p: PropsWithChildren<PagingPanel.ContainerProps>;
    navigate: OnParamsChanged<SB, P>;
  }): ReactElement {
  useEffect(() => {
    const page = props.params.page || 1;
    if (props.p.totalPages > 0 && page > props.p.totalPages) {
      props.navigate({ ...props.params, page: props.p.currentPage + 1 });
    }
  });
  const nav = useCallback((n: number) => props.navigate({ ...props.params, page: n + 1 }), [props]);
  return <Paginator
    move={props.p.onCurrentPageChange}
    startIndex={1}
    totalPages={props.p.totalPages}
    pageSize={props.p.pageSize}
    currentPage={props.p.currentPage}
    navigate={nav}
  />;
}

export const onExpandedRowIdsChange = (setExpandedRowIds: Dispatch<SetStateAction<ExpandedRowIds>>) =>
  (expandedRowIds: Array<string | number>) =>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setExpandedRowIds(O.fold<string | number, ExpandedRowIds>(() => [], (r) => [r as number])(RA.last(expandedRowIds)));

export const createColumnSortList = <
  A,
  MetaData,
  SB extends SortBy<keyof A>,
  ExtraColumns extends string[],
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  Columns extends TableColumn<A, MetaData, ExtraColumns> = TableColumn<A, MetaData, ExtraColumns>,
>(columns: Columns, sortBy?: SB): Array<SortColumnOption<SB>> =>
  Object.values(
    pipe(
      // The mapped type doesnt evaluate to the same type as a Record cast as that other type
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      columns as Record<keyof Columns, Columns[keyof Columns]>,
      R.filterMapWithIndex(
        (k, v) => pipe(
          O.some(v),
          O.filter(isCustomTableComponentSortable),
          O.map((e) => ({ columnName: k, title: e.sort.customSortTitle ?? v.title, default: sortBy === k, ...e.sort }))
        )
      )
    )
  ).reduce((acc: Array<SortColumnOption<SB>>, curr) => {
    return curr.direction === "both"
      ? [...acc,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      { ...curr, value: columnValueFormat({ columnName: curr.columnName, direction: "asc" }), direction: "asc" } as unknown as SortColumnOption<SB>,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      { ...curr, value: columnValueFormat({ columnName: curr.columnName, direction: "desc" }), direction: "desc" } as unknown as SortColumnOption<SB>]
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      : [...acc, { ...curr, value: `${curr.columnName}-${curr.direction}`, direction: curr.direction } as unknown as SortColumnOption<SB>];
  }, []);

export const defaultSortState = <SB extends SortBy<keyof A>, A>(columnSortList: Array<SortColumnOption<SB>>, sortBy?: SB) => pipe(
  columnSortList,
  RA.findFirst(q => q.value === sortBy),
  O.fold(() => [], c => [c])
);

export const onSortChanged = <SB extends string, P extends object = object>(
  columnSortList: Array<SortColumnOption<SB>>,
  setSorting: Dispatch<SetStateAction<Sorting[]>>,
  searchVal: string,
  onParamsChanged: OnParamsChanged<SB, P>,
  params: Partial<P>,
) => (value: SelectValueOnChange<SB>) => {
  const col = pipe(
    columnSortList,
    RA.findFirst(q => columnValueFormat(q) === value.value),
    O.fold(() => [], (v) => [v])
  );
  setSorting(col);
  onParamsChanged({ ...params, search: searchVal, sortBy: value.value, page: 1 });
};

export const formatCustomSortValueExtension = <
  A,
  MetaData,
  ExtraColumns extends string[],
>(columns: TableColumnRow<A, MetaData, ExtraColumns>["Columns"]) => pipe(
  columns,
  R.filterMapWithIndex((k: string, v: TableColumnUnion<A, unknown, MetaData>): O.Option<IntegratedSorting.ColumnExtension> =>
    isCustomTableComponentSortable(v)
      ? O.some({ columnName: k, compare: v.sort.ord.compare })
      : O.none
  ),
  Object.values<IntegratedSorting.ColumnExtension>
);

export const onSearchChanged = <SB extends SortBy<keyof A>, A, P extends object = object>(
  sortByVal: SortByVal<SB, A>,
  onParamsChanged: OnParamsChanged<SB, P>,
  params: Partial<P>,
) => debounce((search: string) => onParamsChanged({ ...params, search, page: 1, sortBy: sortByVal.sortBy }), 250);

export const pagingPanelOrEmpty = <SB extends SortBy<keyof A>, A, P extends object = object>(
  sortByVal: SortByVal<SB, A>,
  onParamsChanged: OnParamsChanged<SB, P>,
  paginate: O.Option<number>,
  searchVal: string,
  p: PropsWithChildren<PagingPanel.ContainerProps>,
  params: Partial<P>,
) => (
  mapOrEmpty(() =>
    <TablePagingPanel
      params={{ ...params, page: O.toUndefined(paginate), search: searchVal, ...sortByVal }}
      p={p}
      navigate={onParamsChanged}
    />
  )(paginate)
);

export const updateRows = <A, MetaData>(tableDataRows: ReadonlyArray<TableRowModel<A, MetaData>>, tableRows: ReadonlyArray<TableRowModel<A, MetaData>>) => {
  const indexRowMap: Record<number, { index: O.Option<number>, row: TableRowModel<A, MetaData> }> = {};

  tableDataRows.map(d => indexRowMap[d.__rowId] = { index: O.none, row: d });
  tableRows.map((d, i) => pipe(
    O.fromNullable(indexRowMap[d.__rowId]),
    O.map(c => indexRowMap[d.__rowId] = { index: O.some(i), row: c.row })
  ));

  return Object.values(indexRowMap)
    .sort(flow((a, b) =>
      TH.fromOptions(a.index, b.index),
      O.fold(
        () => 0,
        TH.fold(() => -1, () => 1, (first, second) => first - second)
      )
    ))
    .map(d => d.row);
};

export type OnDragEnd<A, MetaData> = O.Option<[rows: RNA.ReadonlyNonEmptyArray<TableRowModel<A, MetaData>>, expandedRowIds: number[]]>;
export const onDragSortEnd = <A, MetaData>(
  rows: ReadonlyArray<TableRowModel<A, MetaData>>,
  expandedRowIds: number[],
) => (event: DragEndEvent): OnDragEnd<A, MetaData> => {
  const { active, over } = event;
  if (over && active.id !== over.id) {
    const oldIndex = rows.findIndex(r => r.__rowId.toString() === active.id);
    const newIndex = rows.findIndex(r => r.__rowId.toString() === over.id);
    const newList = arrayMove(Array.from(rows), oldIndex, newIndex);
    const newExpandedRows = pipe(
      expandedRowIds,
      A.map((v) => {
        if (v === oldIndex) {
          return newIndex;
        } else if (v < oldIndex && v >= newIndex) {
          return v + 1;
        } else if (v > oldIndex && v <= newIndex) {
          return v - 1;
        } else if (v === newIndex) {
          return oldIndex;
        } else {
          return v;
        }
      }));
    return pipe(newList, RNA.fromArray, O.map(a => [a, newExpandedRows]));
  } else {
    return O.none;
  }
};

export type SortOrderLens<A, MetaData> = Lens<TableRowModel<A, MetaData>, number> | "orderedList";

export const onDragApi = <A, MetaData, SortType extends SortItemsC | SortDocCategoriesC>(
  oldList: ReadonlyArray<TableRowModel<A, MetaData>>,
  api: ApiSort<A, MetaData, SortType>,
  setRows: (newRows: ReadonlyArray<TableRowModel<A, MetaData>>) => void,
  setExpandedRows: React.Dispatch<SetStateAction<ExpandedRowIds>>,
  lens: SortOrderLens<A, MetaData>
) => (onDragEnd: OnDragEnd<A, MetaData>) => pipe(
  onDragEnd,
  TE.fromOption((): RespOrErrors => Th.left(O.none)),
  TE.chain(([rows, expandedRowIds]) => pipe(
    lens === "orderedList" ? rows : pipe(rows, RNA.mapWithIndex((i, a) => lens.set(i)(a))),
    tap<RNA.ReadonlyNonEmptyArray<TableRowModel<A, MetaData>>>(setRows),
    TE.right,
    TE.chain(
      flow(
        api.onDrag,
        d => d.request(d.body)(
          () => {
            api.onFailure();
            setRows(oldList);
          },
          () => {
            api.onSuccess();
            setExpandedRows(expandedRowIds);
          }
        )
      )
    ),
  ))
)();

export type SetTableState<Row extends TableRowModel<unknown, unknown>> = (row: Row, modify: (row: Row) => Row) => void;
export const useTableState = <Row extends TableRowModel<unknown, unknown>>(rows: Row[]) => {
  const [data, setData] = useState(rows);

  const updateAt = (row: Row, modify: (row: Row) => Row) => {
    pipe(
      data,
      A.findIndex(r => r.__rowId === row.__rowId),
      O.chain(i => A.modifyAt(i, modify)(data)),
      O.map(setData)
    );
  };
  return [data, updateAt, setData] as const;
};
