import { O, pipe, RA } from "@scripts/fp-ts";
import * as AssetsRouter from "@scripts/generated/routers/assetsRouter";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { AnchorButtonUnsafe } from "@scripts/react/components/Anchor";
import { Button } from "@scripts/react/components/Button";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import type { RoadshowPlayerData } from "@scripts/routes/routing/ssr/issuersites";

import { useIssuerSitesSelector } from "../../../state/store";

export const Header = (props: {
  data: RoadshowPlayerData;
  slideListOpen: boolean;
  setSlideListOpen: (open: boolean) => void;
}) => {
  const bank = useIssuerSitesSelector("bank");
  const issuer = useIssuerSitesSelector("issuer");
  const baseParams: SitesRouter.IssuersitesIssuerControllerIndexParams = { issuerId: issuer.id, issuerSlug: issuer.slug };

  return <header className="roadshow-nav-2020 inverted">
    <div className="roadshow-nav-2020-container">
      <div className="roadshow-logo-container">
        <img
          src={AssetsRouter.assetImgLogosBondlinkRoadshowLogoSvg().url}
          alt="BondLink Roadshow"
          className="roadshow-logo"
        />
      </div>
      <div className="roadshow-nav-buttons">
        <Button
          variant="primary"
          className="btn-small"
          onClick={() => props.setSlideListOpen(!props.slideListOpen)}
        >{props.slideListOpen ? "← Hide Navigation" : "Show Navigation →"}</Button>
        <img
          src={AssetsRouter.assetImgLogosBondlinkRoadshowLogoSvg().url}
          alt="BondLink Roadshow"
          className="roadshow-logo roadshow-logo-large"
        />

        {pipe(
          bank,
          O.fold(
            () => O.some(SitesRouter.issuersitesIssuerControllerIndex(baseParams)),
            () => pipe(
              props.data.offerings,
              RA.head,
              O.map(o => SitesRouter.issuersitesBondOfferingsControllerOffering({ ...baseParams, offeringId: o.data.data.id })),
              O.alt(() => pipe(
                props.data.rfps,
                RA.head,
                O.map(r => SitesRouter.issuersitesRfpsControllerRfp({ ...baseParams, rfpId: r.data.data.id })),
              )),
            ),
          ),
          mapOrEmpty(url =>
            <AnchorButtonUnsafe title="Back to Full Site" target="_self" href={url.url} variant="primary" klasses="btn-small" />
          ),
        )}
      </div>
    </div>
  </header>;
};
