import { O } from "@scripts/fp-ts";
import type { PartnerU } from "@scripts/generated/domaintables/partners";
import { klassNullableProp, type KlassProp } from "@scripts/react/util/classnames";

import { IssuerLogoImg, issuerLogoRoute } from "@scripts-ssr/components/IssuerLogo";

import { useIssuerSitesSelector } from "../../state/store";
import { PartnerLanderLink } from "./PartnerLanderLink";

export const PartnerLanderIssuerHeader = (props: { partner: PartnerU, klass?: KlassProp }) => {
  const issuer = useIssuerSitesSelector("issuer");

  return <div id="issuer-header" {...klassNullableProp(props.klass)}>
    <PartnerLanderLink
      id="default-issuer-header"
      klasses="d-flex align-items-center no-decoration h-100 relative"
      issuer={O.some(issuer)}
      partner={props.partner}
      url={issuerLogoRoute(issuer).route}
      aria-label={`Homepage for ${issuer.name}`}
    >
      <IssuerLogoImg issuer={issuer} />
      <h1 className="ml-1">{issuer.name}</h1>
    </PartnerLanderLink>
  </div>;
};
