import { useCallback } from "react";
import { setIsSubscribedToIssuer } from "ssr/issuersites/assets/scripts/state/subscription";

import { type MakeDealSubscribeRequest, useBondSubscribeRequest, useIssuerSubscribeRequest, useRfpSubscribeRequest } from "@scripts/api/subscription";
import type { O } from "@scripts/fp-ts";
import type { UserWithRoles } from "@scripts/generated/models/user";
import { makeHandleSubscribeError, makeOnSubscribeHandler, type OnSubscribe } from "@scripts/react/components/SubscriptionToggle";

import { handleUser, type LoginParams, type MakeDealSubscribeRedirect, useBondSubscribeRedirect, useIssuerSubscribeRedirect, useRfpSubscribeRedirect } from "@scripts-ssr/api/watchlist";

import { useIssuerSitesDispatch } from "../state/store";

export type MakeDealOnSubscribe = (
  loginParams: LoginParams,
  isSubscribing: boolean,
  subscriptionItemId: number,
  setter: (isSubscribing: boolean) => void,
  user: O.Option<UserWithRoles>,
  userSubscribedToIssuer: boolean
) => OnSubscribe;

const useMakeDealOnSubscribe = <IdKey extends string>(
  subscribeIdKey: IdKey,
  makeSubscribeRequest: MakeDealSubscribeRequest<IdKey>,
  makeSubscribeRedirect: MakeDealSubscribeRedirect,
): MakeDealOnSubscribe => {
  const dispatch = useIssuerSitesDispatch();
  const makeOnSubscribe = useCallback(
    (
      loginParams: LoginParams,
      isSubscribing: boolean,
      itemId: number,
      setter: (isSubscribing: boolean) => void,
      user: O.Option<UserWithRoles>,
      userSubscribedToIssuer: boolean
    ) => handleUser(
      makeOnSubscribeHandler(
        isSubscribing,
        () => {
          setter(isSubscribing);
          // The server is going to subscribe the user to the issuer for the offering if they weren't already,
          // we optimistically do the same here because we aren't going to reload the data.
          if (isSubscribing) {
            dispatch(setIsSubscribedToIssuer(true));
          }
        },
        () => {
          setter(!isSubscribing);

          if (!userSubscribedToIssuer) {
            dispatch(setIsSubscribedToIssuer(false));
          }
        },
        makeSubscribeRequest(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          { ...({ [subscribeIdKey]: itemId } as Record<IdKey, number>), enabled: isSubscribing },
          makeHandleSubscribeError(dispatch)
        )
      ),
      makeSubscribeRedirect(
        loginParams,
        itemId
      ),
      user
    ), [dispatch, subscribeIdKey, makeSubscribeRedirect, makeSubscribeRequest]);

  return makeOnSubscribe;
};

export const useRfpSubscribe = (): MakeDealOnSubscribe => {
  const makeRfpSubscribeRequest = useRfpSubscribeRequest();
  const makeRfpSubscribeRedirect = useRfpSubscribeRedirect();
  const makeOnSubscribe = useMakeDealOnSubscribe("rfpId", makeRfpSubscribeRequest, makeRfpSubscribeRedirect);

  return makeOnSubscribe;
};

export const useBondSubscribe = (): MakeDealOnSubscribe => {
  const makeBondSubscribeRequest = useBondSubscribeRequest();
  const makeBondSubscribeRedirect = useBondSubscribeRedirect();
  const makeOnSubscribe = useMakeDealOnSubscribe("offeringId", makeBondSubscribeRequest, makeBondSubscribeRedirect);

  return makeOnSubscribe;
};

export const useIssuerSubscribe = (): (isSubscribing: boolean, issuerId: number, user: O.Option<UserWithRoles>) => OnSubscribe => {
  const dispatch = useIssuerSitesDispatch();
  const makeIssuerSubscribeRequest = useIssuerSubscribeRequest();
  const makeIssuerSubscribeRedirect = useIssuerSubscribeRedirect();

  const makeOnSubscribe = useCallback(
    (
      isSubscribing: boolean,
      issuerId: number,
      user: O.Option<UserWithRoles>
    ) => handleUser(
      makeOnSubscribeHandler(
        isSubscribing,
        () => dispatch(setIsSubscribedToIssuer(isSubscribing)),
        () => dispatch(setIsSubscribedToIssuer(!isSubscribing)),
        makeIssuerSubscribeRequest(
          { issuerId, enabled: isSubscribing },
          makeHandleSubscribeError(dispatch)
        )
      ),
      makeIssuerSubscribeRedirect(issuerId),
      user
    ),
    [dispatch, makeIssuerSubscribeRedirect, makeIssuerSubscribeRequest]
  );

  return makeOnSubscribe;
};
