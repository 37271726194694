import { makeJumplinkId } from "@scripts/syntax/sidebarLinks";

export * as about from "./issuersites/aboutJumpLinks";
export * as archivedBonds from "./issuersites/archivedBondsJumpLinks";
export * as bonds from "./issuersites/bondsJumpLinks";
export * as bondOffering from "./issuersites/bondOfferingJumpLinks";
export * as documents from "./issuersites/documentsJumpLinks";
export * as emma from "./issuersites/emmaJumpLinks";
export * as esgProgram from "./issuersites/esgProgramJumpLinks";
export * as faq from "./issuersites/faqJumpLinks";
export * as home from "./issuersites/homeJumpLinks";
export * as links from "./issuersites/linksJumpLinks";
export * as newsAndEvents from "./issuersites/newsAndEventsJumpLinks";
export * as program from "./issuersites/programJumpLinks";
export * as programs from "./issuersites/programsJumpLinks";
export * as project from "./issuersites/projectJumpLinks";
export * as projects from "./issuersites/projectsJumpLinks";
export * as ratings from "./issuersites/ratingsJumpLinks";
export * as resources from "./issuersites/resourcesJumpLinks";
export * as rfp from "./issuersites/rfpJumpLinks";
export * as rfps from "./issuersites/rfpsJumpLinks";
export * as team from "./issuersites/teamJumpLinks";

export { customPage } from "./issuersites/customPageJumpLinks";
export { relatedContent } from "./issuersites/relatedContentJumpLinks";

export const relatedDocumentsJumplinkId = makeJumplinkId("related-documents");
