import { useMemo } from "react";

import { type JumpLink, type SidebarJumpLinks, SidebarLinks } from "@scripts/react/components/SidebarLinks";
import { makePageHeaderSideBarLinkHandle } from "@scripts/syntax/sidebarLinks";
import type { HasTag } from "@scripts/util/codecTypeGuards";

export const SidebarLinksSites = (props: SidebarJumpLinks & {
  routeMeta: HasTag;
  headerLinkAnchorContent: string;
}) => {
  const headerLink: JumpLink = useMemo(() => ({
    link: `#${makePageHeaderSideBarLinkHandle(props.routeMeta)}`,
    anchorContent: props.headerLinkAnchorContent,
  }), [props]);

  return <SidebarLinks {...props} defaultNavOffset={132} headerLink={headerLink} />;
};
