import { useEffect } from "react";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";

import type { DeepLinkOptions, DeepLinkTarget } from "@scripts/codecs/deepLinkTarget";
import { not } from "@scripts/fp-ts/boolean";
import type { DeepLinkModalIdU, DeepLinkTabIdU } from "@scripts/generated/domaintables/deepLinkTypes";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { equalsParentIdOrId } from "@scripts/syntax/threadThrough";

import { clearModalTarget, clearTabTarget, deepLinkTarget } from "../../globals";
import { defaultErrorMsgEl } from "../components/error/errorMessages";
import type { Tab } from "../components/tab-control/tabControlSyntax";
import type { NotificationAdd } from "../state/notifications";
import { notificationAdd } from "../state/notifications";
import type { OpenModalFn, OpenModalO } from "./useModal";

const hasDataId = () => deepLinkTarget.dataId != null;
const hasOptions = (a: DeepLinkTarget) => a.options != null;
const isModalTarget = (modalId: DeepLinkModalIdU) => deepLinkTarget.modal === modalId.id;

export const isDeepLinkWithData = (modalId: DeepLinkModalIdU) => isModalTarget(modalId) && hasDataId();
export const isDeepLinkWithoutData = (modalId: DeepLinkModalIdU) => isModalTarget(modalId) && not(hasDataId());
export const isDeepLinkOptions = (a: DeepLinkTarget): a is { options: DeepLinkOptions } => hasOptions(a);

export const useModalDeepLink = (modalId: DeepLinkModalIdU, openFn: OpenModalFn) => {
  useEffect(() => {
    if (isDeepLinkWithoutData(modalId)) {
      openFn();
      clearModalTarget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export type UserSettingsTabLabels = DeepLinkTabIdU["id"] | "alert-preferences" | "security" | "account-info";

export const useTabDeepLink = (tabs: ReadonlyArray<Tab<UserSettingsTabLabels>>, selectTab: (tabId: string) => void) => {
  useEffect(() => {
    const deepLinkTab = deepLinkTarget.tab && tabs.find(tab => tab.id === deepLinkTarget.tab);
    if (deepLinkTab) {
      selectTab(deepLinkTab.id);
      clearTabTarget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const handleDeepLinkErr = (dispatch: (action: NotificationAdd) => void) => {
  dispatch(notificationAdd({
    id: "Deep Link Error",
    title: "Error Opening Modal",
    type: "warning",
    children: defaultErrorMsgEl,
  }));
};

export const useModalDeepLinkWithData = <A, T extends WithStatusU<A>>(
  modalId: DeepLinkModalIdU,
  openFn: OpenModalO<T>,
  data: ReadonlyArray<T>,
  dispatch: (action: NotificationAdd) => void,
  isData?: (t: T) => boolean,
) => {
  useEffect(() => {
    if (isDeepLinkWithData(modalId)) {
      pipe(
        deepLinkTarget.dataId,
        O.fromNullable,
        O.chain(dataId => RA.findFirst((item: T) => equalsParentIdOrId(dataId)(item))(data)),
        O.filter(_ => isData ? isData(_) : true),
        O.foldW(
          () => handleDeepLinkErr(dispatch),
          openFn
        )
      );
      clearModalTarget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
