import { type ReactElement, useCallback, useEffect, useState } from "react";
import { pipe } from "fp-ts/lib/function";

import { TE } from "@scripts/fp-ts";

import { klass } from "../util/classnames";
import { LazyTippy, tooltipDelayDefault } from "./Tooltip";

export const CopyText = (props: { trigger: (onClick: () => void) => ReactElement, value: string }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [timeoutToCleanup, setTimeoutToCleanup] = useState(0);
  const [tooltipText, setTooltipText] = useState("Copied to clipboard!");

  const showTooltip = useCallback(() => {
    setIsTooltipOpen(true);
    setTimeoutToCleanup(window.setTimeout(
      () => setIsTooltipOpen(false),
      1500
    ));
  }, []);

  const copyText = useCallback(() => pipe(
    TE.tryCatch(() => window.navigator.clipboard.writeText(props.value), () => setTooltipText("Error copying to clipboard")),
    TE.mapBoth(() => showTooltip, () => { setTooltipText("Copied to clipboard!"); showTooltip(); })
  )(), [props.value, showTooltip]);

  useEffect(() => {
    return () => { if (timeoutToCleanup) window.clearTimeout(timeoutToCleanup); };
  }, [timeoutToCleanup]);

  const tooltipContent = useCallback(() => <div {...klass("p-05")}>{tooltipText}</div>, [tooltipText]);

  return <LazyTippy
    appendTo={"parent"}
    delay={tooltipDelayDefault}
    visible={isTooltipOpen}
    offset={[0, 20]}
    interactive
    maxWidth="27rem"
    content={tooltipContent}
    placement="top"
  >
    {props.trigger(copyText)}
  </LazyTippy>;
};
