import type { IssuerPartnerLanderPageData } from "@scripts/generated/models/issuerPageData";

export const PartnerLanderQuickFacts = (props: { quickFacts: IssuerPartnerLanderPageData["quickFacts"] }) =>
  <>
    {props.quickFacts.map(_ =>
      <div key={_.data.id} className="data-point-medium">
        <dt>{_.data.record.fieldHeader}</dt>
        <dd>{_.data.record.fieldValue}</dd>
      </div>
    )}
  </>;
