import { pipe } from "@scripts/fp-ts";
import { Anchor } from "@scripts/react/components/Anchor";
import { noneOrEmpty } from "@scripts/react/components/Empty";
import { toPageRouteLinkBasic } from "@scripts/syntax/linkable";

import { useIssuerSitesSelector } from "../../state/store";
import { GoToHomePage } from "./GoToHomePage";

export const NotFoundLinks = () => {
  const issuer = useIssuerSitesSelector("issuer");
  const navLinks = useIssuerSitesSelector("navLinks");

  return pipe(issuer.bankId, noneOrEmpty(() => <>
    <p className="large my-1">Try one of the links below to get back on track:</p>
    <GoToHomePage />
    <div className="d-sm-flex justify-content-center mt-15">
      {navLinks.map(([l]) =>
        <p key={l.url} className="large mb-1">
          <Anchor klasses="mx-1" target="_self" route={toPageRouteLinkBasic(l)} />
        </p>
      )}
    </div>
  </>));
};
