import { E } from "@scripts/fp-ts";
import * as p from "@scripts/generated/domaintables/pages";
import type { Issuer } from "@scripts/generated/models/issuer";
import { esgProgramMeta } from "@scripts/meta/dataMeta";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import { newsMeta, projectsMeta, teamMeta } from "@scripts/routes/routing/issuerportal/dataMeta";

export const aboutMain = (i: Issuer): SitesJumpLink => SitesJumpLink(`About ${i.name}`, [E.right(p.aboutPage)]);

export const esgProgram = SitesJumpLink(esgProgramMeta, [E.right(p.esgProgram)]);

export const news = SitesJumpLink(newsMeta, [E.right(p.news)]);

export const projects = SitesJumpLink(projectsMeta, [E.right(p.infrastructureProjects)]);

export const team = SitesJumpLink(teamMeta, [E.right(p.financeTeam)]);

export const allStatic = (i: Issuer): ReadonlyArray<SitesJumpLink> => [aboutMain(i), esgProgram, news, projects, team];
