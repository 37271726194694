import type * as t from "io-ts";

import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import { omit } from "@scripts/fp-ts/struct";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { Anchor } from "@scripts/react/components/Anchor";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { FactsRow, type FactType, makeFactTooltipLinkPropsO } from "@scripts/react/components/Facts";
import { ImageCarouselModal } from "@scripts/react/components/ImageCarouselModal";
import { AccentDividerSection, DividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { type IssuerSitesRouteMeta, project, type ProjectPageData, type ProjectPageDataC } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";

import { DirectSitesPageLayoutBase } from "../../components/DirectSitesPageLayout";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { AllRelatedContentSection } from "../../components/offering-pages/RelatedContent";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { ContactUsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

const ProjectSidebarLinks = (props: {
  jumplinks: ReadonlyArray<JumpLink>;
  projectRouteMeta: IssuerSitesRouteMeta<ProjectPageData, t.OutputOf<ProjectPageDataC>, "project">;
  project: ProjectPageData;
}) =>
  <SidebarLinksSites
    headerLinkAnchorContent={props.project.data.record.data.project.projectTitle}
    jumpLinks={props.jumplinks}
    routeMeta={props.projectRouteMeta}
  />;

const ProjectQuickFactsAndText = (props: { project: ProjectPageData }) =>
  <DividerSection title={O.none}>
    {pipe(
      props.project.data.record.data.relatedContent.quickFacts,
      RA.map((_): FactType => ({
        title: _.data.data.record.fieldHeader,
        value: _.data.data.record.fieldValue,
        tooltipProps: O.toUndefined(makeFactTooltipLinkPropsO(_.data.data.record.fieldUrl, _.data.data.record.linkText)),
      })),
      RNEA.fromReadonlyArray,
      mapOrEmpty((facts) =>
        <div className="mb-1">
          <FactsRow variant="bold" items={facts} />
        </div>
      )
    )}
    <Markdown content={props.project.data.record.data.project.projectText} klasses={["last-child-mb-0"]} />
  </DividerSection>;

const ProjectImageGallery = (props: { project: ProjectPageData }) =>
  pipe(
    props.project.data.record.data.relatedContent.photos,
    RA.map(_ => _.data.data.record),
    RNEA.fromReadonlyArray,
    mapOrEmpty(photos =>
      <DividerSection title={O.some("Image Gallery")}>
        <ImageCarouselModal images={photos} isMulti />
      </DividerSection>
    )
  );


export const ProjectPage = (props: { project: ProjectPageData }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const iffs = useIssuerSitesSelector("iffs");
  const pages = useIssuerSitesSelector("pages");

  const projectRouteMeta = project({
    issuerSlug: issuer.slug,
    issuerId: issuer.id,
    projectId: props.project.data.id,
  });

  const relatedContentJL = jl.project.relatedContent(props.project.data.record.data.relatedContent);
  const sidebarLinks = makeSitesJumpLinks(iffs, pages)(jl.project.all(props.project.data.record.data));

  return <DirectSitesPageLayoutBase
    headerComponent={O.none}
    sidebarContent={
      <>
        <ProjectSidebarLinks
          jumplinks={sidebarLinks}
          project={props.project}
          projectRouteMeta={projectRouteMeta}
        />
        <ContactUsActionSection containerKlass="d-none-until-md" />
      </>
    }
  >
    <h2 className="mb-0">
      {props.project.data.record.data.project.projectTitle}
      <IssuerSitesLeafIcon taggedContent={props.project.data.record} />
    </h2>
    <Anchor
      target="_self"
      route={{ title: "View all", route: SR.issuersitesAboutControllerProjects({ issuerId: issuer.id, issuerSlug: issuer.slug }) }}
      arrowType="right"
      klasses="d-inline-block mb-1"
    />
    <AccentDividerSection title={O.none} klasses="accent-border-top">
      <ProjectQuickFactsAndText project={props.project} />
      <ProjectImageGallery project={props.project} />
    </AccentDividerSection>
    {pipe(
      relatedContentJL.enabled(iffs),
      trueOrEmpty(<AllRelatedContentSection
        relatedContent={omit("photos", "quickFacts")(props.project.data.record.data.relatedContent)}
      />)
    )}
    <ContactUsActionSection containerKlass="d-md-none" />
  </DirectSitesPageLayoutBase>;
};
