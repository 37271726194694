import type { SVGString } from "*.svg";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { makeDataModalParentIdOrIdUrl } from "@scripts/codecs/deepLinkTarget";
import { b, E, O, pipe } from "@scripts/fp-ts";
import { sequenceS } from "@scripts/fp-ts/Apply";
import { sitesEventModal } from "@scripts/generated/domaintables/deepLinkTypes";
import * as fi from "@scripts/generated/models/featuredItem";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { pageIssuerSiteAbsoluteUrl } from "@scripts/routes/routing/ssr/issuersites/syntax";
import { humanDateLongWithShortMonth } from "@scripts/syntax/date/joda";
import { dateWithOptionalTimeToString } from "@scripts/syntax/dateWithOptionalTime";
import { saleDateFormat } from "@scripts/syntax/offering";

import offeringIcon from "@svgs/bond.svg";
import eventIcon from "@svgs/calendar.svg";
import projectIcon from "@svgs/construction.svg";
import documentIcon from "@svgs/document.svg";
import newsIcon from "@svgs/newspaper.svg";
import rfpIcon from "@svgs/rfp.svg";
import roadshowIcon from "@svgs/roadshow.svg";
import pageIcon from "@svgs/star-empty.svg";

import { eventHasModal } from "./event";
import { issuerSiteAbsUrl } from "./issuer";

export type FeaturedItemDetails = {
  type: fi.FeaturedItemTypeU;
  itemId: number;
  badgeIcon: SVGString;
  badgeText: string;
  routeTitleName: string;
  taggedContent: TaggedContent<unknown>;
  header: string;
  date: O.Option<string>;
  url: string;
};

const noTaggedContent: TaggedContent<unknown> = { data: null, tags: new Set() };

export const toFeaturedItemDetails = (config: BLConfigWithLog, issuer: Issuer, item: fi.FeaturedItemU): FeaturedItemDetails => {
  const mkUrl = issuerSiteAbsUrl(config)(issuer, O.none);

  switch (item._tag) {
    case "FeaturedDocument":
      return {
        type: fi.document,
        itemId: item.document.data.id,
        badgeIcon: documentIcon,
        badgeText: "Document",
        routeTitleName: "Document",
        taggedContent: item.document.data.record.data,
        header: item.document.data.record.data.data.document.uploadResponse.viewName,
        date: O.some(`Posted: ${humanDateLongWithShortMonth(O.getOrElse(() => item.document.data.record.created)(item.document.data.record.published))}`),
        url: mkUrl(SR.issuersitesReportsControllerViewFile)({ mediaId: item.document.data.id }).url,
      };
    case "FeaturedEvent":
      return {
        type: fi.event,
        itemId: item.event.data.id,
        badgeIcon: eventIcon,
        badgeText: "Event",
        routeTitleName: "Event",
        taggedContent: noTaggedContent,
        header: item.event.data.record.data.eventTitle,
        date: (() => {
          const start = O.map(humanDateLongWithShortMonth)(item.event.data.record.data.eventDate);
          const end = O.map(humanDateLongWithShortMonth)(item.event.data.record.data.endDate);

          return pipe(
            sequenceS(O.Apply)({ start, end }),
            O.fold(
              () => O.alt(() => end)(start),
              ({ start: s, end: e }) => O.some(s === e ? s : `${s} - ${e}`),
            ),
          );
        })(),
        url: b.fold(
          () => mkUrl(SR.issuersitesAboutControllerNewsEvents)({}).url,
          () => makeDataModalParentIdOrIdUrl(
            mkUrl(SR.issuersitesAboutControllerNewsEvents)({}),
            {
              modalId: sitesEventModal,
              data: item.event,
            },
            []
          )
        )(eventHasModal(item.event.data.record.data)),
      };
    case "FeaturedNews":
      return {
        type: fi.news,
        itemId: item.news.data.id,
        badgeIcon: newsIcon,
        badgeText: "News Update",
        routeTitleName: "News",
        taggedContent: item.news.data.record.data,
        header: item.news.data.record.data.data.newsTitle,
        date: O.map(humanDateLongWithShortMonth)(item.news.data.record.data.data.newsDate),
        url: mkUrl(SR.issuersitesAboutControllerNewsItem)({ newsId: item.news.data.id }).url,
      };
    case "FeaturedBond":
      return {
        type: fi.offering,
        itemId: item.bond.data.id,
        badgeIcon: offeringIcon,
        badgeText: "Bond Offering",
        routeTitleName: "Offering",
        taggedContent: item.bond.data.record.data,
        header: item.bond.data.record.data.data.name,
        date: pipe(
          item.bond.data.record.data.data.firstSaleDate,
          O.map(_ => `Sale Date: ${saleDateFormat(config)(O.some(_), humanDateLongWithShortMonth)}`),
        ),
        url: mkUrl(SR.issuersitesBondOfferingsControllerOffering)({ offeringId: item.bond.data.id }).url,
      };
    case "FeaturedPage":
      return {
        type: fi.page,
        itemId: item.page.id,
        badgeIcon: pageIcon,
        badgeText: "Featured",
        routeTitleName: "Page",
        taggedContent: noTaggedContent,
        header: item.header,
        date: O.none,
        url: pageIssuerSiteAbsoluteUrl(config, item.page, issuer),
      };
    case "FeaturedProject":
      return {
        type: fi.project,
        itemId: item.project.data.id,
        badgeIcon: projectIcon,
        badgeText: "Project",
        routeTitleName: "Project",
        taggedContent: item.project.data.record.data,
        header: item.project.data.record.data.data.projectTitle,
        date: O.none,
        url: mkUrl(SR.issuersitesAboutControllerProjectItem)({ projectId: item.project.data.id }).url,
      };
    case "FeaturedRfp":
      return {
        type: fi.rfp,
        itemId: item.rfp.data.id,
        badgeIcon: rfpIcon,
        badgeText: "RFP",
        routeTitleName: "RFP",
        taggedContent: item.rfp.data.record.data,
        header: item.rfp.data.record.data.data.name,
        date: pipe(
          item.rfp.data.record.data.data.bidsDue,
          O.map(d => `Bids Due: ${pipe(d, E.fold(dateWithOptionalTimeToString(humanDateLongWithShortMonth), _ => _.format))}`),
        ),
        url: mkUrl(SR.issuersitesRfpsControllerRfp)({ rfpId: item.rfp.data.id }).url,
      };
    case "FeaturedRoadshow":
      return {
        type: fi.roadShow,
        itemId: item.roadshow.id,
        badgeIcon: roadshowIcon,
        badgeText: "Road Show",
        routeTitleName: "Road Show",
        taggedContent: item.roadshow.record.data,
        header: item.roadshow.record.data.data.title,
        date: O.none,
        url: mkUrl(SR.issuersitesRoadShowControllerRoadShow)({ roadShowId: item.roadshow.id }).url,
      };
  }

  return config.exhaustive(item);
};
