import { formatS3CdnUrl } from "@scripts/bondlink";
import { O, pipe } from "@scripts/fp-ts";
import type { IssuerPartnerLanderPageData } from "@scripts/generated/models/issuerPageData";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { useConfig } from "@scripts/react/context/Config";

export const PartnerLanderMainImg = (props: { mainImg: IssuerPartnerLanderPageData["mainImg"] }) => {
  const config = useConfig();

  return pipe(props.mainImg, mapOrEmpty(m => <img
    className="box-shadow-2"
    src={formatS3CdnUrl(config)(m.data.record.uploadResponse.uri)}
    alt={O.toUndefined(m.data.record.altText)}
  />));
};
