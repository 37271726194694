import { useStore } from "react-redux";

import { O } from "@scripts/fp-ts";
import { ButtonLink } from "@scripts/react/components/Button";
import { Table, type TableProps } from "@scripts/react/components/table/Table";
import type * as tableSyntax from "@scripts/react/components/table/tableSyntax";
import type { TableSortColumnName } from "@scripts/react/components/table/tableSyntax";
import { klass } from "@scripts/react/util/classnames";

import type { IssuerSitesState } from "../state/state";
import type { IssuerSitesActions } from "../state/store";

export const IssuerSitesTable = <
  SB extends tableSyntax.SortBy<keyof A>,
  A,
  MetaData,
  RowParserOutput,
  ExtraColumns extends string[],
  ExportExtraColumns extends string[],
  Params extends object,
>(props: Omit<TableProps<SB, A, MetaData, RowParserOutput, ExtraColumns, ExportExtraColumns, Params>, "store">) => {
  const store = useStore<IssuerSitesState, IssuerSitesActions>();
  return <Table {...props} store={O.some(store)} pageSize={50} />;
};

export type IssuerSitesTableSortProps<ColName extends string> = {
  sortBy: TableSortColumnName<ColName>;
  setSortBy: (sort: TableSortColumnName<ColName>) => void;
};

export const IssuerSitesTableColumnHeader = <ColName extends string>(props: IssuerSitesTableSortProps<ColName> & {
  colName: ColName;
  colText?: string;
}) =>
  <ButtonLink
    {...klass("no-decoration table-header-button", props.sortBy === `${props.colName}-asc` ? "asc" : props.sortBy === `${props.colName}-desc` ? "desc" : "")}
    onClick={() => props.setSortBy(props.sortBy === `${props.colName}-asc` ? `${props.colName}-desc` : `${props.colName}-asc`)}
  >
    {props.colText ?? props.colName}
  </ButtonLink>;
