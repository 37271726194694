import type * as O from "fp-ts/lib/Option";

import { black as _black, gray, lime, white as _white } from "@scripts/ui/colors";

export type ExcelColor = {
  argb: string;
};

export const excelNumberFormats = {
  currency: "$#,##0.00",
  percent1: "0.0%",
};

type NumberFormat = keyof typeof excelNumberFormats;

export type ExcelExportColumn = {
  conditionalFormatting?: "colorScale" | "dataBar";
  decimalPlaces?: number;
  numberFormat?: NumberFormat;
  title: string;
  width?: number;
};

type FilterDescription = { name: string, value: O.Option<string> };
export type FilterSection = { title: string, filters: FilterDescription[] };

// The mock spreadsheet I was working from has a different ratio of pixels to excel units - BS
export const widthConversionFactor = 222 / 168;

const alpha = "FF";

const toExcelColor = (rgb: string) => {
  return alpha + rgb.replace("#", "");
};

export const white = {
  argb: toExcelColor(_white),
};

export const black = {
  argb: toExcelColor(_black),
};

export const colorScaleMin = {
  argb: "FFFCFCFF",
};

export const colorScaleMax = {
  argb: "FFAEDAEA",
};

export const gray200 = {
  argb: toExcelColor(gray[200]),
};

export const gray400 = {
  argb: toExcelColor(gray[400]),
};

export const gray600 = {
  argb: toExcelColor(gray[600]),
};

const gray800 = {
  argb: toExcelColor(gray[800]),
};

export const lime700 = {
  argb: toExcelColor(lime[700]),
};

export const darkGrayFill = {
  type: "pattern" as const,
  pattern: "solid" as const,
  bgColor: gray800,
  fgColor: gray800,
};

export const defaultFont = {
  name: "Arial",
  size: 12,
};

export const filterBorderBottom = {
  bottom: {
    color: {
      argb: toExcelColor(gray[400]),
    },
    style: "thin" as const,
  },
};
