import { E } from "@scripts/fp-ts";
import * as f from "@scripts/generated/domaintables/featureFlags";
import * as p from "@scripts/generated/domaintables/pages";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";

export const upcomingEvents = SitesJumpLink("Upcoming Events", [E.left(f.financeCalendar)]);

export const newsAndPressReleases = SitesJumpLink("News & Press Releases", [E.right(p.news)]);

export const allStatic: ReadonlyArray<SitesJumpLink> = [upcomingEvents, newsAndPressReleases];
