import type { EqualityFn, NoInfer, UseDispatch, UseSelector } from "react-redux";
import { useDispatch, useSelector } from "react-redux"; // eslint-disable-line @typescript-eslint/no-restricted-imports
import { configureStore } from "@reduxjs/toolkit";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import type { Dispatch, Reducer, Store } from "redux";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { footer } from "@scripts/generated/domaintables/hrefLocations";
import type { BLIssuerSitesSession } from "@scripts/generated/models/blWindow";
import type { FlashAction } from "@scripts/react/state/flash";
import { flashReducer } from "@scripts/react/state/flash";
import type { NotificationAction } from "@scripts/react/state/notifications";
import { notificationReducer } from "@scripts/react/state/notifications";
import type { ReduxAction, ReduxDevtoolAction } from "@scripts/react/state/store";
import { isDevtoolAction, isFlashAction, isNotificationAction, isReduxAction, isUserAction } from "@scripts/react/state/store";
import type { UserAction } from "@scripts/react/state/user";
import { userReducer } from "@scripts/react/state/user";
import { makeNavLinks } from "@scripts/routes/routing/ssr/issuersites/navLinks";
import { filterByHrefLocation } from "@scripts/syntax/href";

import type { DueDisclaimerAction } from "./dueDisclaimer";
import { dueDisclaimerReducer, isDueDisclaimerAction } from "./dueDisclaimer";
import type { IssuerSitesInitialState, IssuerSitesState } from "./state";
import { emptyState } from "./state";
import { isSubscriptionAction, type SubscriptionAction } from "./subscription";

export type IssuerSitesActions = NotificationAction | FlashAction | UserAction | ReduxAction | ReduxDevtoolAction | DueDisclaimerAction | SubscriptionAction;

const reducers = (config: BLConfigWithLog) =>
  (initialState: IssuerSitesInitialState): Reducer<IssuerSitesState, IssuerSitesActions> =>
    (state: IssuerSitesState = emptyState(initialState), action: IssuerSitesActions): IssuerSitesState => {
      if (isFlashAction(action)) {
        return {
          ...state,
          flash: flashReducer(config)(state.flash, action),
        };
      } else if (isNotificationAction(action)) {
        return {
          ...state,
          notifications: notificationReducer(config)(state.notifications, action),
        };
      } else if (isUserAction(action)) {
        return {
          ...state,
          user: pipe(state.user, O.map(u => userReducer(config)(u, action))),
        };
      } else if (isDueDisclaimerAction(action)) {
        return {
          ...state,
          dueDisclaimer: dueDisclaimerReducer(config)(state.dueDisclaimer, action),
        };
      } else if (isReduxAction(action) || isDevtoolAction(action)) {
        return state;
      } else if (isSubscriptionAction(action)) {
        return {
          ...state,
          userSubscribed: action.isSubscribedToIssuer,
        };
      }
      return config.exhaustive(action);
    };

type IssuerSitesStore = Store<IssuerSitesState, IssuerSitesActions>;

export const createIssuerSitesStore = (config: BLConfigWithLog) =>
  (initialState: IssuerSitesInitialState): IssuerSitesStore =>
    configureStore({
      reducer: reducers(config)(initialState),
      preloadedState: emptyState(initialState),
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
      }),
      devTools: {
        name: "Issuer Sites",
        serialize: true,
      },
    });

export const makeIssuerSitesInitialState = (sess: BLIssuerSitesSession): IssuerSitesInitialState => ({
  ...sess,
  navLinks: makeNavLinks(sess.issuer, sess.iffs, sess.pages, sess.prefs, sess.hrefs, sess.hasIrmaLetter),
  footerLinks: filterByHrefLocation(footer)(sess.hrefs),
});

const useIssuerSitesSelectorUnsafe: UseSelector<IssuerSitesState> = useSelector;
export const useIssuerSitesSelector = <K extends keyof IssuerSitesState>(
  k: K,
  equalityFn?: EqualityFn<NoInfer<IssuerSitesState[K]>>
): IssuerSitesState[K] => useIssuerSitesSelectorUnsafe(_ => _[k], equalityFn);

type IssuerSitesDispatch = Dispatch<IssuerSitesActions>;
export const useIssuerSitesDispatch: UseDispatch<IssuerSitesDispatch> = useDispatch;
