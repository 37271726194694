import type { BLConfigWithLog } from "@scripts/bondlink";
import { O } from "@scripts/fp-ts";
import type { BLIssuerSitesSession } from "@scripts/generated/models/blWindow";
import { type IssuerSitesRouteMetaWithRender, normalizePhotoPageFn } from "@scripts/routes/routing/ssr/issuersites";

import { reactHydrate } from "@scripts-ssr/client";
import type { PageData } from "@scripts-ssr/syntax/pageData";

import { PageLoader } from "../components/PageLoader";
import type { IssuerSitesInitialState } from "../state/state";
import { createIssuerSitesStore } from "../state/store";

export const hydratePage =
  (config: BLConfigWithLog) =>
    <PropsA, PropsO, Tag>(
      pageWithRender: IssuerSitesRouteMetaWithRender<PropsA, PropsO, Tag>
  ) =>
      (pageData: PageData<BLIssuerSitesSession, unknown, IssuerSitesInitialState, unknown, PropsA, PropsO>) => {
      const store = createIssuerSitesStore(config)(pageData.initialState);

      reactHydrate(
        <PageLoader
          config={config}
          pageTitle={pageData.title}
          photoPage={normalizePhotoPageFn(pageWithRender.photoPage)(pageData.props)(pageData.initialState.pages)}
          render={() => pageWithRender.render(pageData.props)}
          store={store}
          url={pageWithRender.url()}
          blpLoginRequired={O.fromNullable(pageWithRender.blpLoginRequired?.(pageData.props)(pageData.initialState.issuer))}
        />
      );
    };
