import type { RNEA } from "@scripts/fp-ts";
import { O } from "@scripts/fp-ts";
import type { BondProgramWithRelatedContent } from "@scripts/generated/models/bondProgram";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { type RatingBlockProps, RatingBlockSection } from "@scripts/react/components/ratings/RatingBlock";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";

import { useIssuerSitesSelector } from "../../../state/store";

export const RatingsSection = (props: {
  ratings: RNEA.ReadonlyNonEmptyArray<BondProgramWithRelatedContent["relatedContent"]["ratings"][number]>;
  programName: string;
} & RatingBlockProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  return (
    <AccentDividerSection jumpLink={jl.program.ratings(props.ratings)} pages={pages}>
      <RatingBlockSection documentDownloadRoute={props.documentDownloadRoute} name={O.none} modalName={props.programName} issuer={issuer} ratings={props.ratings} />
    </AccentDividerSection>
  );
};
