import { end, parse, Route, zero } from "fp-ts-routing";
import type * as t from "io-ts";

import type { Html } from "@scripts/codecs/html";
import { E, O, pipe } from "@scripts/fp-ts";
import type { EmmaLinksPageData, EmmaLinksPageDataC } from "@scripts/generated/models/cusip";
import type { SitesCustomPageData, SitesCustomPageDataC } from "@scripts/generated/models/customPages";
import type { NewsAndEventsData, NewsAndEventsDataC } from "@scripts/generated/models/issuerNews";
import type { BLPDealUnavailablePageData, BLPDealUnavailablePageDataC, IssuerHomepageData, IssuerHomepageDataC, IssuerPartnerLanderPageData, IssuerPartnerLanderPageDataC, SitesErrorPageData, SitesErrorPageDataC } from "@scripts/generated/models/issuerPageData";
import type { RfpsSitesList, RfpsSitesListC } from "@scripts/generated/models/rfp";
import type * as SR from "@scripts/generated/routers/sitesRouter";
import { NotFound } from "@scripts/react/components/error/NotFound";
import type {
  AboutPageData,
  AboutPageDataC,
  BondArchivePageDataC,
  BondsPageDataC,
  DocumentCategoriesPageData,
  DocumentCategoriesPageDataC,
  DownloadsPageData,
  DownloadsPageDataC,
  EsgProgramPageDataC,
  FAQPageData,
  FAQPageDataC,
  HomeUrlParams,
  IrmaLetterPageData,
  IrmaLetterPageDataC,
  IssuerSitesRouteMetaWithRender,
  LinksPageData,
  LinksPageDataC,
  NewsAndEventsUrlParams,
  NewsItemData,
  NewsItemDataC,
  OfferingPageDataC,
  ProgramPageDataC,
  ProgramsPageDataC,
  ProjectPageData,
  ProjectPageDataC,
  ProjectsPageData,
  ProjectsPageDataC,
  RatingsPageDataC,
  ResourcePageData,
  ResourcePageDataC,
  RfpPageDataC,
  RfpsUrlParams,
  RfpUrlParams,
  RoadshowLanderData,
  RoadshowLanderDataC,
  RoadshowPlayerData,
  RoadshowPlayerDataC,
  TeamPageData,
  TeamPageDataC,
  ViewFilePageData,
  ViewFilePageDataC,
} from "@scripts/routes/routing/ssr/issuersites";
import {
  about,
  aboutMatch,
  archivedDocuments,
  archivedDownloadsMatch,
  blpDealUnavailable,
  blpDealUnavailableMatch,
  bondArchive,
  bondArchiveMatch,
  bonds,
  bondsMatch,
  customPage,
  customPageMatch,
  documentCategories,
  documentCategoriesMatch,
  downloads,
  downloadsMatch,
  emmaLinks,
  emmaLinksMatch,
  esgProgram,
  esgProgramMatch,
  expectedNotFound,
  expectedNotFoundMatch,
  faq,
  faqMatch,
  forbidden,
  forbiddenMatch,
  home,
  homeMatch,
  irmaLetter,
  irmaLetterMatch,
  links,
  linksMatch,
  newsAndEvents,
  newsAndEventsMatch,
  newsItem,
  newsItemMatch,
  offeringPage,
  offeringPageMatch,
  partnerLander,
  partnerLanderMatch,
  program,
  programMatch,
  programs,
  programsMatch,
  project,
  projectMatch,
  projects,
  projectsMatch,
  ratings,
  ratingsMatch,
  resources,
  resourcesMatch,
  rfp,
  rfpMatch,
  rfps,
  rfpsMatch,
  roadshowLander,
  roadshowLanderMatch,
  roadshowPlayer,
  roadshowPlayerMatch,
  serverError,
  serverErrorMatch,
  team,
  teamMatch,
  unexpectedNotFound,
  viewFile,
  viewFileMatch,
} from "@scripts/routes/routing/ssr/issuersites";

import { AboutPage } from "../pages/about/about-page/About";
import { NewsAndEvents } from "../pages/about/NewsAndEvents";
import { NewsItem } from "../pages/about/NewsItem";
import { ProjectPage } from "../pages/about/Project";
import { ProjectsPage } from "../pages/about/Projects";
import { BankOfferingPage } from "../pages/bank-offering/BankOfferingPage";
import { BankRfpPage } from "../pages/bank-rfp/BankRfpPage";
import { BondArchive } from "../pages/bonds/BondArchive";
import { Bonds } from "../pages/bonds/Bonds";
import { CustomPage } from "../pages/CustomPage";
import { DirectOfferingPage } from "../pages/direct-offering/DirectOfferingPage";
import { DirectRfp } from "../pages/direct-rfp/DirectRfp";
import { Rfps } from "../pages/direct-rfp/Rfps";
import { DocumentCategoriesPage } from "../pages/documents/DocumentCategoriesPage";
import { DocumentsPage } from "../pages/documents/DocumentsPage";
import { IrmaLetterPage } from "../pages/documents/IrmaLetter";
import { ViewFilePage } from "../pages/documents/ViewFile";
import { EmmaLinks } from "../pages/emma-links/EmmaLinks";
import { BLPDealUnavailable } from "../pages/errors/BLPDealUnavailable";
import { Forbidden } from "../pages/errors/Forbidden";
import { NotFoundLinks } from "../pages/errors/NotFoundLinks";
import { ServerError } from "../pages/errors/ServerError";
import { EsgProgram } from "../pages/esg-program/EsgProgram";
import { Home } from "../pages/home";
import { PartnerLander } from "../pages/partner-lander/PartnerLander";
import { ProgramPage } from "../pages/programs/Program";
import { ProgramsPage } from "../pages/programs/Programs";
import { RatingsPage } from "../pages/ratings/RatingsPage";
import { FaqPage } from "../pages/resources/FaqPage";
import { LinksPage } from "../pages/resources/LinksPage";
import { ResourcesPage } from "../pages/resources/Resources";
import { RoadshowLander } from "../pages/roadshows/lander/RoadshowLander";
import { RoadshowPlayer } from "../pages/roadshows/player/RoadshowPlayer";
import { TeamPage } from "../pages/team/TeamPage";

const homeWithRender = (reqParams: SR.IssuersitesAboutControllerNewsEventsParams): IssuerSitesRouteMetaWithRender<IssuerHomepageData, t.OutputOf<IssuerHomepageDataC>, "home"> => ({
  ...home(reqParams),
  render: (pageProps) => <Home data={pageProps} {...reqParams} />,
});

const newsAndEventsWithRender = (reqParams: SR.IssuersitesAboutControllerNewsEventsParams): IssuerSitesRouteMetaWithRender<NewsAndEventsData, t.OutputOf<NewsAndEventsDataC>, "news-and-events"> => ({
  ...newsAndEvents(reqParams),
  render: (pageProps) => <NewsAndEvents data={pageProps} {...reqParams} />,
});

const newsItemWithRender = (reqParams: SR.IssuersitesAboutControllerNewsItemParams): IssuerSitesRouteMetaWithRender<NewsItemData, t.OutputOf<NewsItemDataC>, "news-item"> => ({
  ...newsItem(reqParams),
  render: (pageProps) => <NewsItem news={pageProps} />,
});

const rfpsWithRender = (reqParams: SR.IssuersitesRfpsControllerRfpsParams): IssuerSitesRouteMetaWithRender<RfpsSitesList, t.OutputOf<RfpsSitesListC>, "rfps"> => ({
  ...rfps(reqParams),
  render: (pageProps) => <Rfps rfps={pageProps} {...reqParams} />,
});

const rfpWithRender = (reqParams: SR.IssuersitesRfpsControllerRfpParams): IssuerSitesRouteMetaWithRender<t.TypeOf<RfpPageDataC>, t.OutputOf<RfpPageDataC>, "rfp"> => ({
  ...rfp(reqParams),
  render: (pageProps) => pipe(pageProps, E.fold(
    bankRfp => <BankRfpPage {...bankRfp} />,
    directRfp => <DirectRfp {...directRfp} {...reqParams} />)),
});

const bondsWithRender = (reqParams: SR.IssuersitesBondOfferingsControllerIndexParams): IssuerSitesRouteMetaWithRender<t.TypeOf<BondsPageDataC>, t.OutputOf<BondsPageDataC>, "bonds"> => ({
  ...bonds(reqParams),
  render: (d) => <Bonds {...d} />,
});

const bondArchiveWithRender = (reqParams: SR.IssuersitesBondOfferingsControllerArchivedBondsParams): IssuerSitesRouteMetaWithRender<t.TypeOf<BondArchivePageDataC>, t.OutputOf<BondArchivePageDataC>, "bond-archive"> => ({
  ...bondArchive(reqParams),
  render: d => <BondArchive {...d} />,
});

const offeringWithRender = (reqParams: SR.IssuersitesBondOfferingsControllerOfferingParams): IssuerSitesRouteMetaWithRender<t.TypeOf<OfferingPageDataC>, t.OutputOf<OfferingPageDataC>, "offering-page"> => ({
  ...offeringPage(reqParams),
  render: (offeringData) => pipe(offeringData, E.fold(
    bankOffering => <BankOfferingPage {...bankOffering} />,
    directOffering => <DirectOfferingPage {...directOffering} />)),
});

const ratingsWithRender = (reqParams: SR.IssuersitesBondOfferingsControllerRatingsParams): IssuerSitesRouteMetaWithRender<t.TypeOf<RatingsPageDataC>, t.OutputOf<RatingsPageDataC>, "ratings"> => ({
  ...ratings(reqParams),
  render: (pageProps) => <RatingsPage {...pageProps} />,
});

const programsWithRender = (reqParams: SR.IssuersitesBondProgramsControllerBondProgramsParams): IssuerSitesRouteMetaWithRender<t.TypeOf<ProgramsPageDataC>, t.OutputOf<ProgramsPageDataC>, "programs"> => ({
  ...programs(reqParams),
  render: (pageProps) => <ProgramsPage programs={pageProps} />,
});

const programWithRender = (reqParams: SR.IssuersitesBondProgramsControllerBondProgramParams): IssuerSitesRouteMetaWithRender<t.TypeOf<ProgramPageDataC>, t.OutputOf<ProgramPageDataC>, "bond-program"> => ({
  ...program(reqParams),
  render: (p) => <ProgramPage programWithRelatedContent={p} />,
});

const esgProgramWithRender = (reqParams: SR.IssuersitesAboutControllerEsgProgramParams): IssuerSitesRouteMetaWithRender<t.TypeOf<EsgProgramPageDataC>, t.OutputOf<EsgProgramPageDataC>, "esg-program"> => ({
  ...esgProgram(reqParams),
  render: (p) => <EsgProgram data={p} />,
});

const faqWithRender = (reqParams: SR.IssuersitesResourcesControllerFaqParams): IssuerSitesRouteMetaWithRender<FAQPageData, t.OutputOf<FAQPageDataC>, "faq"> => ({
  ...faq(reqParams),
  render: (p) => <FaqPage faqs={p.faqs} />,
});

const aboutWithRender = (reqParams: SR.IssuersitesAboutControllerIndexParams): IssuerSitesRouteMetaWithRender<AboutPageData, t.OutputOf<AboutPageDataC>, "about"> => ({
  ...about(reqParams),
  render: (p) => <AboutPage {...p} />,
});

const projectsWithRender = (reqParams: SR.IssuersitesAboutControllerProjectsParams): IssuerSitesRouteMetaWithRender<ProjectsPageData, t.OutputOf<ProjectsPageDataC>, "projects"> => ({
  ...projects(reqParams),
  render: (pageProps) => <ProjectsPage data={pageProps} {...reqParams} />,
});

const projectWithRender = (reqParams: SR.IssuersitesAboutControllerProjectItemParams): IssuerSitesRouteMetaWithRender<ProjectPageData, t.OutputOf<ProjectPageDataC>, "project"> => ({
  ...project(reqParams),
  render: (pageProps) => <ProjectPage project={pageProps} />,
});

const downloadsWithRender = (reqParams: SR.IssuersitesReportsControllerDownloadsParams): IssuerSitesRouteMetaWithRender<DownloadsPageData, t.OutputOf<DownloadsPageDataC>, "downloads"> => ({
  ...downloads(reqParams),
  render: (pageProps) => <DocumentsPage data={pageProps} variant="downloads" />,
});

const archivedDocumentsWithRender = (reqParams: SR.IssuersitesReportsControllerDownloadsParams): IssuerSitesRouteMetaWithRender<DownloadsPageData, t.OutputOf<DownloadsPageDataC>, "archived-documents"> => ({
  ...archivedDocuments(reqParams),
  render: (pageProps) => <DocumentsPage data={pageProps} variant="archive" />,
});

const irmaLetterWithRender = (reqParams: SR.IssuersitesReportsControllerIrmaLetterParams): IssuerSitesRouteMetaWithRender<IrmaLetterPageData, t.OutputOf<IrmaLetterPageDataC>, "irma-letter"> => ({
  ...irmaLetter(reqParams),
  render: (pageProps) => <IrmaLetterPage data={pageProps} />,
});

const viewFileWithRender = (reqParams: SR.IssuersitesReportsControllerViewFileParams): IssuerSitesRouteMetaWithRender<ViewFilePageData, t.OutputOf<ViewFilePageDataC>, "view-file"> => ({
  ...viewFile(reqParams),
  render: (pageProps) => <ViewFilePage data={pageProps} />,
});

const teamWithRender = (reqParams: SR.IssuersitesAboutControllerTeamParams): IssuerSitesRouteMetaWithRender<TeamPageData, t.OutputOf<TeamPageDataC>, "team"> => ({
  ...team(reqParams),
  render: (pageProps) => <TeamPage officers={pageProps} />,
});

const resourcesWithRender = (reqParams: SR.IssuersitesResourcesControllerIndexParams): IssuerSitesRouteMetaWithRender<ResourcePageData, t.OutputOf<ResourcePageDataC>, "resources"> => ({
  ...resources(reqParams),
  render: (pageProps) => <ResourcesPage data={pageProps} />,
});

const linksWithRender = (reqParams: SR.IssuersitesResourcesControllerLinksParams): IssuerSitesRouteMetaWithRender<LinksPageData, t.OutputOf<LinksPageDataC>, "links"> => ({
  ...links(reqParams),
  render: (pageProps) => <LinksPage data={pageProps} />,
});

const documentCategoriesWithRender = (reqParams: SR.IssuersitesReportsControllerIndexParams): IssuerSitesRouteMetaWithRender<DocumentCategoriesPageData, t.OutputOf<DocumentCategoriesPageDataC>, "documents"> => ({
  ...documentCategories(reqParams),
  render: (pageProps) => <DocumentCategoriesPage data={pageProps} />,
});

const roadshowLanderWithRender = (reqParams: SR.IssuersitesRoadShowControllerRoadShowParams): IssuerSitesRouteMetaWithRender<RoadshowLanderData, t.OutputOf<RoadshowLanderDataC>, "roadshow-lander"> => ({
  ...roadshowLander(reqParams),
  render: (pageProps) => <RoadshowLander show={pageProps} />,
});

const roadshowPlayerWithRender = (reqParams: SR.IssuersitesRoadShowControllerRoadShowPlayerParams): IssuerSitesRouteMetaWithRender<RoadshowPlayerData, t.OutputOf<RoadshowPlayerDataC>, "roadshow-player"> => ({
  ...roadshowPlayer(reqParams),
  render: (pageProps) => <RoadshowPlayer data={pageProps} />,
});

const customPageWithRender = (reqParams: SR.IssuersitesIssuerControllerCustomParams): IssuerSitesRouteMetaWithRender<SitesCustomPageData, t.OutputOf<SitesCustomPageDataC>, "custom-page"> => ({
  ...customPage(reqParams),
  render: (pageProps) => <CustomPage data={pageProps} />,
});

const partnerLanderWithRender = (reqParams: SR.IssuersitesLanderControllerIndexParams): IssuerSitesRouteMetaWithRender<IssuerPartnerLanderPageData, t.OutputOf<IssuerPartnerLanderPageDataC>, "partner-lander"> => ({
  ...partnerLander(reqParams),
  render: (pageProps) => <PartnerLander data={pageProps} />,
});

const emmaLinksWithRender = (reqParams: SR.IssuersitesEmmaControllerLinksParams): IssuerSitesRouteMetaWithRender<EmmaLinksPageData, t.OutputOf<EmmaLinksPageDataC>, "emma-links"> => ({
  ...emmaLinks(reqParams),
  render: (pageProps) => <EmmaLinks data={pageProps} searchedCusip9={reqParams.cusip9} />,
});

const blpDealUnavailableWithRender: IssuerSitesRouteMetaWithRender<BLPDealUnavailablePageData, t.OutputOf<BLPDealUnavailablePageDataC>, "blp-deal-unavailable"> = {
  ...blpDealUnavailable,
  render: data => <BLPDealUnavailable data={data} />,
};

const notFoundRender = (debugContent: O.Option<Html>) =>
  <NotFound extraContent={<NotFoundLinks />} debugContent={debugContent} />;

const notFoundWithRender: IssuerSitesRouteMetaWithRender<unknown, t.OutputOf<t.UnknownC>, "unexpected-not-found"> = {
  ...unexpectedNotFound,
  render: () => notFoundRender(O.none),
};

const expectedNotFoundWithRender: IssuerSitesRouteMetaWithRender<SitesErrorPageData, t.OutputOf<SitesErrorPageDataC>, "expected-not-found"> = {
  ...expectedNotFound,
  render: data => notFoundRender(data.debugContent),
};

const forbiddenWithRender: IssuerSitesRouteMetaWithRender<SitesErrorPageData, t.OutputOf<SitesErrorPageDataC>, "forbidden"> = {
  ...forbidden,
  render: data => <Forbidden debugContent={data.debugContent} />,
};

const serverErrorWithRender: IssuerSitesRouteMetaWithRender<SitesErrorPageData, t.OutputOf<SitesErrorPageDataC>, "server-error"> = {
  ...serverError,
  render: data => <ServerError debugContent={data.debugContent} />,
};

export type IssuerSitesPageMeta =
  | typeof blpDealUnavailableWithRender
  | typeof expectedNotFoundWithRender
  | typeof forbiddenWithRender
  | typeof notFoundWithRender
  | typeof serverErrorWithRender
  | ReturnType<typeof aboutWithRender>
  | ReturnType<typeof archivedDocumentsWithRender>
  | ReturnType<typeof bondArchiveWithRender>
  | ReturnType<typeof bondsWithRender>
  | ReturnType<typeof customPageWithRender>
  | ReturnType<typeof documentCategoriesWithRender>
  | ReturnType<typeof downloadsWithRender>
  | ReturnType<typeof emmaLinksWithRender>
  | ReturnType<typeof esgProgramWithRender>
  | ReturnType<typeof faqWithRender>
  | ReturnType<typeof homeWithRender>
  | ReturnType<typeof irmaLetterWithRender>
  | ReturnType<typeof linksWithRender>
  | ReturnType<typeof newsAndEventsWithRender>
  | ReturnType<typeof newsItemWithRender>
  | ReturnType<typeof offeringWithRender>
  | ReturnType<typeof partnerLanderWithRender>
  | ReturnType<typeof programWithRender>
  | ReturnType<typeof programsWithRender>
  | ReturnType<typeof projectWithRender>
  | ReturnType<typeof projectsWithRender>
  | ReturnType<typeof ratingsWithRender>
  | ReturnType<typeof resourcesWithRender>
  | ReturnType<typeof roadshowLanderWithRender>
  | ReturnType<typeof roadshowPlayerWithRender>
  | ReturnType<typeof rfpsWithRender>
  | ReturnType<typeof rfpWithRender>
  | ReturnType<typeof teamWithRender>
  | ReturnType<typeof viewFileWithRender>;

export type IssuerSitesPageTagU = IssuerSitesPageMeta["_tag"];

const router = zero<IssuerSitesPageMeta>()
  .alt(homeMatch.parser.map((params: HomeUrlParams) => homeWithRender(params)))
  .alt(newsAndEventsMatch.parser.map((params: NewsAndEventsUrlParams) => newsAndEventsWithRender(params)))
  .alt(newsItemMatch.parser.map(newsItemWithRender))
  .alt(rfpsMatch.parser.map((params: RfpsUrlParams) => rfpsWithRender(params)))
  .alt(rfpMatch.parser.map((params: RfpUrlParams) => rfpWithRender(params)))
  .alt(bondsMatch.parser.map(bondsWithRender))
  .alt(bondArchiveMatch.parser.map(bondArchiveWithRender))
  .alt(offeringPageMatch.parser.map(offeringWithRender))
  .alt(ratingsMatch.parser.map(ratingsWithRender))
  .alt(programMatch.parser.map(programWithRender))
  .alt(programsMatch.parser.map(programsWithRender))
  .alt(esgProgramMatch.parser.map(esgProgramWithRender))
  .alt(faqMatch.parser.map(faqWithRender))
  .alt(aboutMatch.parser.map(aboutWithRender))
  .alt(projectsMatch.parser.map(projectsWithRender))
  .alt(projectMatch.parser.map(projectWithRender))
  .alt(downloadsMatch.parser.map(downloadsWithRender))
  .alt(archivedDownloadsMatch.parser.map(archivedDocumentsWithRender))
  .alt(irmaLetterMatch.parser.map(irmaLetterWithRender))
  .alt(viewFileMatch.parser.map(viewFileWithRender))
  .alt(resourcesMatch.parser.map(resourcesWithRender))
  .alt(documentCategoriesMatch.parser.map(documentCategoriesWithRender))
  .alt(teamMatch.parser.map(teamWithRender))
  .alt(linksMatch.parser.map(linksWithRender))
  .alt(roadshowLanderMatch.parser.map(roadshowLanderWithRender))
  .alt(roadshowPlayerMatch.parser.map(roadshowPlayerWithRender))
  .alt(customPageMatch.parser.map(customPageWithRender))
  .alt(partnerLanderMatch.parser.map(partnerLanderWithRender))
  .alt(emmaLinksMatch.parser.map(emmaLinksWithRender))
  .alt(blpDealUnavailableMatch.parser.map(() => blpDealUnavailableWithRender))
  .alt(expectedNotFoundMatch.parser.map(() => expectedNotFoundWithRender))
  .alt(forbiddenMatch.parser.map(() => forbiddenWithRender))
  .alt(serverErrorMatch.parser.map(() => serverErrorWithRender))
  .alt(end.parser.map(() => notFoundWithRender));

export const parseLocation = (s: string): IssuerSitesPageMeta => parse(router, Route.parse(s), notFoundWithRender);
