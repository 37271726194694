
import { type ReactNode, useCallback } from "react";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";

import { pipe } from "@scripts/fp-ts";
import { ET } from "@scripts/generated/domaintables/timeZones";
import { ButtonPrimary } from "@scripts/react/components/Button";
import { constEmpty, mapOrEmpty } from "@scripts/react/components/Empty";
import { BidSubmissionModal, type BidSubmissionModalProps } from "@scripts/react/components/form/submit-bid/BidSubmissionModal";
import type { BidSubmissionModalDataProps } from "@scripts/react/components/form/submit-bid/BidSubmissionSyntax";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { type UseModalProps } from "@scripts/react/util/useModal";
import { humanDateFull, humanDateFullAtTime } from "@scripts/syntax/date/joda";
import { dateWithOptionalTimeToString } from "@scripts/syntax/dateWithOptionalTime";
import { rfpBidSubmissionBtnText, type RfpBidSubmissionState } from "@scripts/syntax/rfp";

const BidSubmissionModalCallout = (
  props: BidSubmissionModalDataProps & { successAction?: BidSubmissionModalProps["successAction"] } & UseModalProps
) => {

  const successAction = useCallback(() => {
    props.successAction?.();
    props.closeModal();
  }, [props]);

  return pipe(
    rfpBidSubmissionBtnText(props.bidSubmissionState),
    mapOrEmpty(t => <div>
      <ButtonPrimary className="mt-0" onClick={props.openModal}>{t}</ButtonPrimary>
      <BidSubmissionModal
        {...props}
        dismissAction={props.closeModal}
        modalOpen={props.modalOpen}
        successAction={successAction}
      />
    </div>),
  );
};

export const BidSubmissionSection = (props: {
  bidSubmissionState: RfpBidSubmissionState;
  setSubscribedToRfp: () => void;
} & BidSubmissionModalDataProps & UseModalProps) => {

  const bidsDueBy = props.bidSubmissionState.isBiddingOpen
    ? pipe(
      props.rfp.data.record.bidsDue,
      O.fold(
        (): ReactNode => "The due date for bids has not yet been scheduled.",
        E.fold(
          (d): ReactNode => <>Bids are due by <strong>{dateWithOptionalTimeToString(humanDateFull)(d)}</strong>.</>,
          ongoing => `Bids are accepted on an ${ongoing.name} basis.`
        ),
      )
    )
    : "Bidding is now closed.";

  const submittedBidText = pipe(
    props.bidSubmission,
    O.fold(
      () => O.fromPredicate(() => props.bidSubmissionState.isBidInProgress)(
        "You currently have a bid submission in progress that has not yet been completed."
      ),
      s => O.some(
        `You submitted a bid on ${humanDateFullAtTime(O.getOrElse(() => s.data.record.created)(s.data.record.published))} ${ET.name}.`
        + (
          props.bidSubmissionState.isBiddingOpen
            ? " You can edit and re-submit this bid as long as"
            + pipe(
              props.rfp.data.record.bidsDue,
              O.fold(
                () => " bids are still being accepted.",
                E.fold(
                  () => " it is before the submission deadline.",
                  () => " bids are still being accepted.",
                ),
              ),
            )
            + " You can also review this bid at any time."
            : ""
        )
      )
    )
  );

  return props.bidSubmissionState.acceptsBidsOnSite && <AccentDividerSection title={O.some("Bid Submission")}>
    <p>{bidsDueBy} {O.getOrElse<ReactNode>(constEmpty)(submittedBidText)}</p>
    <BidSubmissionModalCallout
      user={props.user}
      rfp={props.rfp}
      bidSubmissionTemplate={props.bidSubmissionTemplate}
      bidSubmission={props.bidSubmission}
      setBidSubmission={props.setBidSubmission}
      issuer={props.issuer}
      bidSubmissionState={props.bidSubmissionState}
      subscription={props.subscription}
      // the BE is going to subscribe the user to the RFP, need to keep UI in sync
      successAction={props.setSubscribedToRfp}
      modalOpen={props.modalOpen}
      openModal={props.openModal}
      closeModal={props.closeModal}
    />
  </AccentDividerSection>;
};
