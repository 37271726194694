import { E, flow, O, RA } from "@scripts/fp-ts";
import type { FeatureU } from "@scripts/generated/domaintables/featureFlags";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import { type JumpLink, type SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import { flipped } from "@scripts/syntax/featureFlags";
import type { HasTag } from "@scripts/util/codecTypeGuards";
import { undistributed } from "@scripts/util/undistributed";

export type ElementId = `#${string}`;
export const elementId = (id: string): ElementId => `#${id}`;

export type AnchorId = `anchor-${string}`;

export const makeJumplinkId = (sectionName: string): AnchorId => `anchor-${sectionName}`;

export const makePageHeaderSideBarLinkHandle = (x: HasTag): AnchorId => makeJumplinkId(x._tag);

export const sectionToFeatures: (section: SitesJumpLink["sections"][number]) => ReadonlyArray<FeatureU> =
  E.fold(RA.of, _ => RA.fromOption(undistributed<O.Option<FeatureU>>(_.enablingFlag)));

export const isPageEnabled = (ffs: ClientFeatureFlags): (p: PageU) => boolean =>
  flow(E.right, sectionToFeatures, RA.every(flipped.isFFEnabled(ffs)));

export const filterEnabledSections = (ffs: ClientFeatureFlags): (sections: SitesJumpLink["sections"]) => SitesJumpLink["sections"] =>
  RA.filter(E.fold(flipped.isFFEnabled(ffs), isPageEnabled(ffs)));

const makeSitesJumpLink = (
  ffs: ClientFeatureFlags,
  pages: ReadonlyArray<PageConfig<PageU>>,
) => (link: SitesJumpLink): O.Option<JumpLink> =>
    O.fromPredicate(() => link.enabled(ffs))({ link: elementId(link.sectionId), anchorContent: link.text(pages) });

export const makeSitesJumpLinks = (
  ffs: ClientFeatureFlags,
  pages: ReadonlyArray<PageConfig<PageU>>,
): (links: ReadonlyArray<SitesJumpLink>) => ReadonlyArray<JumpLink> =>
  flow(RA.map(makeSitesJumpLink(ffs, pages)), RA.compact);
