import type { PropsWithChildren, ReactElement } from "react";

import { b, O } from "@scripts/fp-ts";
import { portalTable } from "@scripts/generated/assets/stylesheets/components/_portal-table";
import { klass } from "@scripts/react/util/classnames";
import { noneStr } from "@scripts/syntax/none";

export const emptyCellStr: string = noneStr;
export const emptyCellConst = (): string => emptyCellStr;
export const stringOrEmptyCell: (s: O.Option<string>) => string = O.getOrElse(emptyCellConst);
export const EmptyCell = () => <span {...klass("inactive", "d-block")}>{emptyCellStr}</span>;

export const mapOrEmptyCell = <A,>(fa: (a: A) => ReactElement): ((ma: O.Option<A>) => ReactElement) => O.fold(EmptyCell, fa);
export const trueOrEmptyCell = (component: ReactElement) => b.fold(EmptyCell, () => component);

export const NoDataCell = (noDataAvailable: boolean) => () => (
  <td {...klass(portalTable[".table-md-breakpoint"][".portal-table-row"].td.attrs[".cell-no-data"])} colSpan={50}>
    {noDataAvailable
      ? "There is currently no data available."
      : "No matching results found. Try expanding your parameters to find more results."}
  </td>
);

export const NoDataRow = (p: PropsWithChildren<object>) => (
  <tr {...klass(portalTable[".table-md-breakpoint"][".portal-table-row"])}>{p.children}</tr>
);
