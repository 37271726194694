import type { PropsWithChildren } from "react";

import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { OfferingParticipant } from "@scripts/generated/models/offeringParticipant";
import type { ParticipantType } from "@scripts/generated/models/participantType";
import type { WithId, WithStatusU } from "@scripts/generated/models/threadThrough";
import { klass } from "@scripts/react/util/classnames";

import { AccentDividerSection } from "../layout/Section";
import { ParticipantRow, type ParticipantRowBreakpoint } from "../ParticipantRow";

export type Participant = [WithId<ParticipantType>, RNEA.ReadonlyNonEmptyArray<WithStatusU<OfferingParticipant>>];

type ParticipantsSectionContainerProps = { title: string, breakpoint?: ParticipantRowBreakpoint, sidebarLinkHandle?: string };

export const ParticipantsSectionContainer = (props: PropsWithChildren<ParticipantsSectionContainerProps>) =>
  <AccentDividerSection sectionId={props.sidebarLinkHandle} title={O.some(props.title)}>
    {props.children}
  </AccentDividerSection>;

export const ParticipantRows = (props: { participants: RNEA.ReadonlyNonEmptyArray<WithStatusU<OfferingParticipant>>, breakpoint?: ParticipantRowBreakpoint }) =>
  <div>
    {pipe(
      props.participants,
      RNEA.map(_ =>
        <ParticipantRow key={_.data.id} participant={_.data.record} breakpoint={props.breakpoint ?? "xl"} />
      )
    )}
  </div>;

export type ParticipantArray = RNEA.ReadonlyNonEmptyArray<Participant>;
export const Participants = (props: ParticipantsSectionContainerProps & {
  participants: ParticipantArray;
}) =>
  <ParticipantsSectionContainer sidebarLinkHandle={props.sidebarLinkHandle} title={props.title}>
    <div {...klass("d-flex", "flex-col", "gap-2")}>
      {pipe(
        props.participants,
        RA.map(([tpe, participants]) =>
          <div key={`participants list ${tpe.id}`}>
            <h3 className="font-sans-normal-500 mb-1 pb-1 bb-0125-300">{tpe.record.name}</h3>
            <ParticipantRows participants={participants} breakpoint={props.breakpoint} />
          </div>
        ),
      )}
    </div>
  </ParticipantsSectionContainer>;
