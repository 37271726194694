import { E } from "@scripts/fp-ts";
import * as p from "@scripts/generated/domaintables/pages";
import type { IssuerLinkSection } from "@scripts/generated/models/issuerLinks";
import type { WithId } from "@scripts/generated/models/threadThrough";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";

export const allStatic: ReadonlyArray<SitesJumpLink> = [SitesJumpLink("Links", [E.right(p.linksPage)])];

export const section = (s: WithId<IssuerLinkSection>): SitesJumpLink =>
  SitesJumpLink(s.record.name, [], { overrideAnchor: `link-section-${s.id}` });

export const all = (sections: ReadonlyArray<WithId<IssuerLinkSection>>): ReadonlyArray<SitesJumpLink> =>
  sections.map(section);
