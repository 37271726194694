import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { IssuerAndProgramRatingsByAgency } from "@scripts/generated/models/rating";
import * as ISR from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { type DocumentDownloadRoute, RatingBlockSection } from "@scripts/react/components/ratings/RatingBlock";
import { klass } from "@scripts/react/util/classnames";
import { ratings } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

export const RatingsPage = (props: IssuerAndProgramRatingsByAgency) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");

  const page = ratings({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const description = page.description(pages, iffs, issuer);

  const issuerRatingsLink = jl.ratings.issuerRatings(props.issuerRatings);

  const issuerRatingsDataO = pipe(
    props.issuerRatings,
    RNEA.fromReadonlyArray
  );

  const jumpLinks = makeSitesJumpLinks(iffs, pages)(jl.ratings.all(props.issuerRatings, props.programRatings.map(([p]) => p)));

  const documentDownloadRoute: DocumentDownloadRoute = (id) => ISR.issuersitesReportsControllerDownloadRedirect({ mediaId: id, issuerId: issuer.id, issuerSlug: issuer.slug });
  return <DirectSitesPageDescriptionLayout
    description={description}
    sidebarContent={
      <>
        <SidebarLinksSites
          headerLinkAnchorContent={page.title()}
          jumpLinks={jumpLinks}
          routeMeta={page}
        />
        <GetAlertsActionSection containerKlass={"d-none-until-md"} />
      </>
    }
  >
    <div {...klass("accent-border-top", "pt-0")}>
      {(O.isNone(issuerRatingsDataO) && RA.isEmpty(props.programRatings)) ? (
        <div {...klass("mt-1")}>There are currently no issuer or program-level ratings available.</div>
      ) : (
        <>
          {pipe(
            issuerRatingsDataO,
            mapOrEmpty((rs) =>
              <AccentDividerSection jumpLink={issuerRatingsLink} pages={pages}>
                <RatingBlockSection
                  ratings={rs}
                  name={O.none}
                  modalName={issuerRatingsLink.text(pages)}
                  issuer={issuer}
                  documentDownloadRoute={documentDownloadRoute}
                />
              </AccentDividerSection>
            )
          )}
            {props.programRatings.map(([program, rs]) =>
              <AccentDividerSection
                key={program.id}
                jumpLink={jl.ratings.programRatings(program)}
                pages={pages}
              >
                <RatingBlockSection
                  ratings={rs}
                  name={O.none}
                  modalName={program.record.name}
                  issuer={issuer}
                  documentDownloadRoute={documentDownloadRoute}
                />
              </AccentDividerSection>
            )}
        </>
      )}
    </div>
  </DirectSitesPageDescriptionLayout>;
};
