import { E, O, RA, type RNEA } from "@scripts/fp-ts";
import { omit } from "@scripts/fp-ts/struct";
import * as f from "@scripts/generated/domaintables/featureFlags";
import * as p from "@scripts/generated/domaintables/pages";
import type { BondProgramRelatedContent, BondProgramWithRelatedContent } from "@scripts/generated/models/bondProgram";
import { rfpsMeta } from "@scripts/meta/dataMeta";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import { bondMeta } from "@scripts/routes/routing/issuerportal/dataMeta";

import { relatedContent as genRelatedContent } from "./relatedContentJumpLinks";
;

export const overview = (prog: BondProgramWithRelatedContent): SitesJumpLink =>
  SitesJumpLink("Overview", [], {
    hidden: O.isNone(prog.program.cusip6)
      && O.isNone(prog.program.sector)
      && RA.isEmpty(prog.relatedContent.quickFacts)
      && RA.isEmpty(prog.relatedContent.documents)
      && RA.isEmpty(prog.relatedContent.externalLinks)
      && O.isNone(prog.program.overview)
      && RA.isEmpty(prog.relatedContent.photos),
  });

export const bonds = (os: BondProgramRelatedContent["offerings"]): SitesJumpLink =>
  SitesJumpLink(bondMeta, [E.left(f.bondOfferings)], { hidden: os.length === 0 });

export const rfps = (rs: BondProgramRelatedContent["rfps"]): SitesJumpLink =>
  SitesJumpLink(rfpsMeta, [E.right(p.rfpsPage)], { hidden: rs.length === 0 });

export const ratings = (rs: BondProgramRelatedContent["ratings"]): SitesJumpLink =>
  SitesJumpLink("Ratings", [E.left(f.bondRatings)], { hidden: rs.length === 0 });

export const cusip9s = (cs: BondProgramRelatedContent["cusip9s"]): SitesJumpLink =>
  SitesJumpLink("CUSIP-9s", [E.left(f.emma)], { hidden: cs.length === 0 });

export const relatedContent = (rc: BondProgramRelatedContent): SitesJumpLink =>
  genRelatedContent(omit("offerings", "rfps", "ratings", "photos", "quickFacts", "cusip9s")(rc));

export const all = (prog: BondProgramWithRelatedContent): RNEA.ReadonlyNonEmptyArray<SitesJumpLink> => [
  overview(prog),
  bonds(prog.relatedContent.offerings),
  rfps(prog.relatedContent.rfps),
  ratings(prog.relatedContent.ratings),
  cusip9s(prog.relatedContent.cusip9s),
  relatedContent(prog.relatedContent),
];
