import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import { formatS3CdnUrl } from "@scripts/bondlink";
import { RA, RNEA } from "@scripts/fp-ts";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { RoadShowData } from "@scripts/generated/models/roadshow";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithId } from "@scripts/generated/models/threadThrough";
import * as ISR from "@scripts/generated/routers/sitesRouter";
import { AspectRatioContainer } from "@scripts/react/components/AspectRatio";
import { Card, CardBody, handleCardClickWithInternalLinksOrButtons } from "@scripts/react/components/card/Card";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Grid, GridCol } from "@scripts/react/components/layout/Grid";
import { Svg } from "@scripts/react/components/Svg";
import { useConfig } from "@scripts/react/context/Config";
import { klass, klassPropO } from "@scripts/react/util/classnames";
import { openInNewTab } from "@scripts/routes/router";
import { issuerSiteAbsUrl } from "@scripts/syntax/issuer";

import playerIcon from "@svgs/player.svg";

import { type LeafIconAsProp } from "./LeafIcon";

type RoadShowViewerProps = LeafIconAsProp & {
  issuer: Issuer;
  roadshow: WithId<TaggedContent<RoadShowData>>;
};

export const RoadshowCard = (props: RoadShowViewerProps) => {
  const config = useConfig();

  const roadshowUrl =
    issuerSiteAbsUrl(config)(props.issuer, O.none)(ISR.issuersitesRoadShowControllerRoadShowPlayer)({ roadShowId: props.roadshow.id }).url;
  return (
    <Card
      klasses={["card-media", "card-roadshow"]}
      onClick={handleCardClickWithInternalLinksOrButtons(() => openInNewTab(roadshowUrl))}
    >
      <CardBody>
        <div {...klass("relative")}>
          <AspectRatioContainer aspectRatio="16:9" klasses={["mb-05"]}>
            {pipe(
              RA.head(props.roadshow.record.data.slides),
              O.map(slide => ({
                imgKlass: O.none,
                imgSrc: slide.record.media.uploadResponse.uri,
              })),
              O.alt(() => O.map((url: string) => ({
                imgKlass: O.some("logo-slide"),
                imgSrc: url,
              }))(props.issuer.logoUrl)),
              mapOrEmpty((imgProps) =>
                <>
                  <img
                    {...klassPropO(["mb-0"])(imgProps.imgKlass)}
                    alt={`Roadshow for ${props.issuer.name}`}
                    src={formatS3CdnUrl(config)(imgProps.imgSrc)}
                  />
                  <div {...klass("icon-play")}>
                    <Svg src={playerIcon} />
                  </div>
                </>
              )
            )}
          </AspectRatioContainer>
        </div>
        <span>
          <h5 {...klass("mb-0", "mt-0")}>
            <span {...klass("a-decoration")}>
              {props.roadshow.record.data.show.title}
            </span>
            {props.leafIcon({ taggedContent: props.roadshow.record, klass: "ml-025" })}
          </h5>
        </span>
      </CardBody>
    </Card>
  );
};

export const RoadshowCardGrid = (props: LeafIconAsProp & {
  roadshows: RNEA.ReadonlyNonEmptyArray<WithId<TaggedContent<RoadShowData>>>;
  issuer: Issuer;
}) =>
  <Grid attrs={O.none} klasses={["card-grid"]}>
    {pipe(
      props.roadshows,
      RNEA.map(roadshow =>
        <GridCol key={roadshow.id} cols={[".c-lg-12"]} klasses={O.none}>
          <RoadshowCard issuer={props.issuer} leafIcon={props.leafIcon} roadshow={roadshow} />
        </GridCol>
      ))}
  </Grid>;
