import { useState } from "react";
import { Lens } from "monocle-ts";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { O, pipe } from "@scripts/fp-ts";
import { cusip6 as cusip6Page } from "@scripts/generated/domaintables/pages";
import { Cusip6SortColumnCU, type Cusip6SortColumnU } from "@scripts/generated/domaintables/sortColumns";
import type { Cusip6, EmmaLinksPageData } from "@scripts/generated/models/cusip";
import type { PageSort } from "@scripts/generated/models/pageSorts";
import type { WithId } from "@scripts/generated/models/threadThrough";
import { emmaName } from "@scripts/literals/emma";
import { AnchorUnsafe } from "@scripts/react/components/Anchor";
import type { TableColumnRow, TableSortColumnName } from "@scripts/react/components/table/tableSyntax";
import { useConfig } from "@scripts/react/context/Config";
import type { SetState } from "@scripts/react/syntax/react";
import { getPageSort } from "@scripts/syntax/pageSorts";
import { caseInsensitiveOrd } from "@scripts/util/string";

import { IssuerSitesTable, IssuerSitesTableColumnHeader, type IssuerSitesTableSortProps } from "../../components/IssuerSitesTable";
import { useIssuerSitesSelector } from "../../state/store";

type Cusip6RowModel = {
  name: string;
  cusip6: string;
};

type Cusip6TableModel = TableColumnRow<Cusip6RowModel, Record<string, never>, ["view"]>;

type Cusip6SortColumnTypes = TableSortColumnName<keyof Cusip6RowModel>;

const cusip6DataMapFn = (cusip6: WithId<Cusip6>): Cusip6TableModel["Row"] => ({
  __klass: O.none,
  __metadata: {},
  __rowId: cusip6.id,
  name: cusip6.record.name,
  cusip6: cusip6.record.cusip6,
});

type Cusip6SortProps = IssuerSitesTableSortProps<keyof Cusip6RowModel>;

const columns = (props: Cusip6SortProps): Cusip6TableModel["Columns"] => ({
  name: {
    title: "Name",
    dataCellKlass: O.none,
    headerComponent: () => <IssuerSitesTableColumnHeader {...props} colName="name" />,
    sort: { direction: "both", ord: caseInsensitiveOrd },
  },
  cusip6: {
    title: "CUSIP-6",
    dataCellKlass: O.none,
    headerComponent: () => <IssuerSitesTableColumnHeader {...props} colName="cusip6" colText="CUSIP-6" />,
    sort: { direction: "both", ord: caseInsensitiveOrd },
  },
  view: {
    title: "View",
    dataCellKlass: O.some("white-space-nowrap"),
    headerComponent: "empty",
    dataCellComponent: r => <AnchorUnsafe
      target="_blank"
      externalLinkLocation="none"
      arrowType="right"
      title={`View on ${emmaName}`}
      // eslint-disable-next-line no-restricted-syntax
      href={`https://emma.msrb.org/IssuerView/IssuerDetails.aspx?cusip=${r.cusip6}`}
    />,
  },
});

type Cusip6FilterState = {
  sortBy: Cusip6SortColumnTypes;
  page: O.Option<number>;
};

const initialCusip6FilterState = (config: BLConfigWithLog, pageSort: PageSort): Cusip6FilterState => ({
  sortBy: pipe(
    O.fromPredicate(Cusip6SortColumnCU.is)(pageSort.column),
    O.fold<Cusip6SortColumnU, Cusip6SortColumnTypes>(
      (): Cusip6SortColumnTypes => "name-asc",
      c => {
        switch (c._tag) {
          case "Cusip6Cusip6": return `cusip6-${pageSort.direction.dir}`;
          case "Cusip6Name": return `name-${pageSort.direction.dir}`;
          default: return config.exhaustive(c);
        }
      }
    )
  ),
  page: O.some(1),
});

const cusip6FiltersLens = Lens.fromProp<Cusip6FilterState>();

const setFilterState = (state: Cusip6FilterState, setState: SetState<Cusip6FilterState>) =>
  <K extends keyof Cusip6FilterState>(key: K) =>
    (value: Cusip6FilterState[K]) => setState(cusip6FiltersLens(key).set(value)(state));

export const Cusip6Table = (props: { cusip6s: EmmaLinksPageData["cusip6s"] }) => {
  const config = useConfig();
  const pages = useIssuerSitesSelector("pages");
  const [filters, setFilters] = useState(initialCusip6FilterState(config, getPageSort(cusip6Page)(pages)));
  const setFilter = setFilterState(filters, setFilters);
  const getFilter = <K extends keyof Cusip6FilterState>(key: K) => cusip6FiltersLens(key).get(filters);

  const sortBy = getFilter("sortBy");
  const page = getFilter("page");

  return <IssuerSitesTable
    data={props.cusip6s.map(cusip6DataMapFn)}
    detailCell={O.none}
    columns={columns({ sortBy, setSortBy: setFilter("sortBy") })}
    exporter={O.none}
    sortable={O.some(sortBy)}
    searchable={O.none}
    paginate={page}
    onParamsChanged={({ page: p }) => setFilter("page")(O.fromNullable(p))}
    tableAction={O.none}
    hideActionsPanel
    variant="issuersites"
  />;
};
