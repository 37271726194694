import { formatS3CdnUrl } from "@scripts/bondlink";
import { O, pipe } from "@scripts/fp-ts";
import type { DocumentWithCategory } from "@scripts/generated/models/document";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithId } from "@scripts/generated/models/threadThrough";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { Anchor, AnchorButtonWithChildren } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Svg } from "@scripts/react/components/Svg";
import { useConfig } from "@scripts/react/context/Config";
import type { ViewFilePageData } from "@scripts/routes/routing/ssr/issuersites";
import { downloads } from "@scripts/routes/routing/ssr/issuersites";
import { qualifierFormat } from "@scripts/syntax/date/dateQualifier";
import { humanDateLong } from "@scripts/syntax/date/joda";

import downloadIcon from "@svgs/download.svg";

import { DirectSitesPageLayoutBase } from "../../components/DirectSitesPageLayout";
import { FileViewerWrapped } from "../../components/FileViewer";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { useIssuerSitesSelector } from "../../state/store";

const documentDescription = (desc: string) => <div className="mt-05">{desc}</div>;

const DocumentHeader = (props: { taggedContent: TaggedContent<DocumentWithCategory> }) => <div className="d-flex justify-content-between w-100">
  <h2 className="mt-0">{props.taggedContent.data.document.uploadResponse.viewName}&nbsp;<IssuerSitesLeafIcon taggedContent={props.taggedContent} /></h2>
</div>;

export const DocumentSidebarContentBase = (props: { document: WithId<unknown> }) => {
  const issuer = useIssuerSitesSelector("issuer");

  return <>
    <div>
      <AnchorButtonWithChildren
        route={{
          _tag: "Download",
          title: () => "Download",
          url: () => SitesRouter.issuersitesReportsControllerDownloadRedirect({
            issuerId: issuer.id,
            issuerSlug: issuer.slug,
            mediaId: props.document.id,
          }).url,
        }}
        target="_blank"
        variant="primary"
        externalLinkLocation="none"
      >
        <Svg src={downloadIcon} /> Download
      </AnchorButtonWithChildren>
    </div>
    <div className="mt-15">
      <Anchor
        route={{
          ...downloads({
            issuerSlug: issuer.slug,
            issuerId: issuer.id,
            mediaCategoryId: O.none,
            docTypeId: O.none,
          }),
          title: () => "View all documents",
        }}
        target="_self"
        externalLinkLocation="none"
        arrowType="right"
      />
    </div>
  </>;
};

const DocumentSidebarContent = (props: { data: WithId<TaggedContent<DocumentWithCategory>> }) => {
  const config = useConfig();

  return <>
    <h5>{pipe(
      props.data.record.data.docType,
      O.fold(
        () => props.data.record.data.category.categoryName,
        (docType) => docType.categoryName
      )
    )}</h5>
    <div className="content-type-details">
      <div className="d-flex flex-grow title-container">
        <div>
          <h4 className="date">{qualifierFormat(config)(props.data.record.data.document.mediaDate, humanDateLong)}</h4>
          {pipe(props.data.record.data.document.caption, mapOrEmpty(documentDescription))}
        </div>
      </div>
    </div>
    <DocumentSidebarContentBase document={props.data} />
  </>;
};

export const ViewFilePage = (props: { data: ViewFilePageData }) => {
  const config = useConfig();

  return <DirectSitesPageLayoutBase
    headerComponent={O.some(<DocumentHeader taggedContent={props.data.data.record} />)}
    sidebarContent={<DocumentSidebarContent data={props.data.data} />}
    appendRightGrid
  >
    <FileViewerWrapped url={formatS3CdnUrl(config)(props.data.data.record.data.document.uploadResponse.uri)} />
  </DirectSitesPageLayoutBase>;
};
