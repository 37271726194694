import { pipe, RA, RNEA } from "@scripts/fp-ts";
import { type PageU } from "@scripts/generated/domaintables/pages";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { AnchorButton } from "@scripts/react/components/Anchor";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { klass } from "@scripts/react/util/classnames";
import { about, type AboutPageData } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";

import { CustomPageSection } from "../../../components/CustomPageSection";
import { DirectSitesPageDescriptionLayout } from "../../../components/DirectSitesPageLayout";
import { ProjectsSection } from "../../../components/related-content/ProjectsSection";
import { SidebarLinksSites } from "../../../components/sidebar/SidebarLinks";
import { ContactUsActionSection } from "../../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../../state/store";
import { AboutSection, makeQuickFactsO } from "./AboutSection";
import { ESGSection } from "./ESGSection";
import { NewsSection } from "./NewsSection";
import { TeamSection } from "./TeamSection";

const setupSidebarLinkSections = (
  issuer: Issuer,
  ffs: ClientFeatureFlags,
  pages: ReadonlyArray<PageConfig<PageU>>,
  customPages: AboutPageData["customPages"],
): ReadonlyArray<JumpLink> =>
  makeSitesJumpLinks(ffs, pages)(jl.about.allStatic(issuer).concat(customPages.map(jl.customPage)));

export const AboutPage = (props: AboutPageData) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");
  const aboutPageRouteMeta = about({ issuerId: issuer.id, issuerSlug: issuer.slug });

  const description = aboutPageRouteMeta.description(pages, iffs, issuer);

  const quickFactsDataO = makeQuickFactsO(props.callouts);
  const newsDataO = RNEA.fromReadonlyArray(props.news);
  const projectsDataO = RNEA.fromReadonlyArray(props.projects);
  const teamDataO = pipe(
    props.team,
    RA.filter(_ => _.data.record.officerPageVisibility),
    RNEA.fromReadonlyArray
  );

  const sidebarLinks = setupSidebarLinkSections(issuer, iffs, pages, props.customPages);

  const isPageEmpty = sidebarLinks.length === 0;

  return (
    <DirectSitesPageDescriptionLayout
      description={description}
      sidebarContent={
        <>
          <SidebarLinksSites
            headerLinkAnchorContent={aboutPageRouteMeta.title()}
            jumpLinks={sidebarLinks}
            routeMeta={aboutPageRouteMeta}
          />
          <ContactUsActionSection containerKlass={"d-none-until-md"} />
        </>
      }
    >
      <div {...klass("accent-border-top", "pt-0")}>
        {isPageEmpty ? <div {...klass("mt-1")}>This issuer has not yet added any information to this section.</div> : (
          <>
            {pipe(
              jl.about.aboutMain(issuer).enabled(iffs),
              trueOrEmpty(
                <AboutSection
                  about={props.about}
                  photos={props.photos}
                  quickFacts={quickFactsDataO}
                />
              )
            )}
            {pipe(jl.about.esgProgram.enabled(iffs), trueOrEmpty(<ESGSection />))}
            {pipe(
              newsDataO,
              mapOrEmpty(_ => <NewsSection news={_} />)
            )}
            {pipe(
              projectsDataO,
              mapOrEmpty(_ =>
                <ProjectsSection
                  projects={_}
                  jumpLink={jl.about.projects}
                  truncateLength={4}
                >
                  <AnchorButton
                    externalLinkLocation="none"
                    klasses={"mt-15"}
                    route={{
                      title: `View All ${jl.about.projects.text(pages)}`,
                      route: SR.issuersitesAboutControllerProjects({
                        issuerId: issuer.id,
                        issuerSlug: issuer.slug,
                      }),
                    }}
                    target="_self"
                    variant="primary"
                  />
                </ProjectsSection>
              )
            )}
            {pipe(teamDataO, mapOrEmpty(_ => <TeamSection team={_} />))}
            {pipe(
              props.customPages,
              RA.map(customPage => <CustomPageSection customPage={customPage} key={customPage.page.name} />)
            )}
          </>
        )}
        <ContactUsActionSection containerKlass={"d-md-none"} />
      </div>
    </DirectSitesPageDescriptionLayout>
  );
};
