import * as t from "io-ts";

export const abbreviateNumber = (value: number): string => {
  return new Intl.NumberFormat(
    "en-US",
    {
      // Allow decimals only when value is >= to 1B. 500m or 1.2b
      minimumFractionDigits: value >= 1_000_000_000 ? 1 : undefined, // eslint-disable-line no-undefined
      notation: "compact",
      compactDisplay: "short",
    })
    .format(value).toLocaleLowerCase();
};

export const onlyNumbersToFixed = (decimalPlaces: number) => <A>(value: A) =>
  !t.number.is(value) || value % 1 === 0 ? value : parseFloat(value.toFixed(decimalPlaces));

export const formatPercentage = (decimalPlaces: number) => (value: number) => `${value.toFixed(decimalPlaces)}%`;

export const formatPercentageSingleDecimal = (value: number) => formatPercentage(1)(value);
