import { useStable, useStableCallback } from "fp-ts-react-stable-hooks";

import { Eq, O, pipe, RA, RNEA, s } from "@scripts/fp-ts";
import type { CustomPageU } from "@scripts/generated/domaintables/pages";
import type { SitesCustomPageData } from "@scripts/generated/models/customPages";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { ImageCarouselModal } from "@scripts/react/components/ImageCarouselModal";
import { DividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { customPage, customPageTitleFn } from "@scripts/routes/routing/ssr/issuersites";

import { DirectSitesPageLayoutBase } from "../components/DirectSitesPageLayout";
import { SidebarLinksSites } from "../components/sidebar/SidebarLinks";
import { ContactUsActionSection } from "../components/SidebarAlert";
import { useIssuerSitesSelector } from "../state/store";

type DynamicHeaderId = `dynamic-header-${number}`;
type DynamicHeader = { id: DynamicHeaderId, text: string };

const dynamicHeaderEq: Eq.Eq<DynamicHeader> = Eq.struct({ id: s.Eq, text: s.Eq });

const CustomPageSidebarLinks = (props: {
  page: CustomPageU;
  dynamicHeaders: ReadonlyArray<DynamicHeader>;
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const jumplinks: ReadonlyArray<JumpLink> = props.dynamicHeaders.map(header => ({
    link: `#${header.id}`,
    anchorContent: header.text,
  }));

  return <SidebarLinksSites
    headerLinkAnchorContent={customPageTitleFn(props.page)(pages)}
    jumpLinks={jumplinks}
    routeMeta={customPage({ issuerId: issuer.id, issuerSlug: issuer.slug, i: props.page.index })}
  />;
};

export const CustomPage = (props: { data: SitesCustomPageData }) => {
  const [dynamicHeaders, setDynamicHeaders] = useStable<ReadonlyArray<DynamicHeader>>([], RA.getEq(dynamicHeaderEq));
  const dynamicContentRef = useStableCallback((ref: HTMLDivElement | null) => {
    pipe(
      O.fromNullable(ref),
      O.map(div => setDynamicHeaders(pipe(
        [...div.querySelectorAll("h2, h3")],
        RA.filterMapWithIndex((idx, el) => pipe(
          O.fromNullable(el.textContent),
          O.map(text => {
            const id: DynamicHeaderId = `dynamic-header-${idx}`;
            el.setAttribute("id", id);
            return { id, text };
          })
        )),
      ))),
    );
  }, [setDynamicHeaders], Eq.eqStrict);

  return <DirectSitesPageLayoutBase
    headerComponent={O.none}
    sidebarContent={
      <>
        <CustomPageSidebarLinks page={props.data.page} dynamicHeaders={dynamicHeaders} />
        <ContactUsActionSection containerKlass="d-none-until-md" />
      </>
    }
  >
    {pipe(
      props.data.text,
      mapOrEmpty(text =>
        <DividerSection title={O.none}>
          <div ref={dynamicContentRef}>
            <Markdown content={text.data} klasses={["last-child-mb-0"]} />
          </div>
        </DividerSection>
      )
    )}
    {pipe(
      props.data.photos.map(_ => _.data.record),
      RNEA.fromReadonlyArray,
      mapOrEmpty(photos =>
        <DividerSection title={O.some("Image Gallery")}>
          <ImageCarouselModal images={photos} isMulti />
        </DividerSection>
      )
    )}
    <ContactUsActionSection containerKlass="mt-2 d-md-none" />
  </DirectSitesPageLayoutBase>;
};
