import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { IssuerEsgPageData } from "@scripts/generated/models/issuerPageData";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { documentMap, RelatedContentListTruncated } from "@scripts/react/components/offering-pages/RelatedContent";
import { RoadshowCardGrid } from "@scripts/react/components/RoadshowCardGrid";
import { type JumpLink } from "@scripts/react/components/SidebarLinks";
import { esgProgram, rfps } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { isRFPArchived } from "@scripts/syntax/rfp";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { newsMap } from "../../components/offering-pages/RelatedContent";
import { OverviewSection } from "../../components/Overview";
import { BondsSection, getNonEmptyItemsRecordO, RfpsSection } from "../../components/related-content/DealSection";
import { ProjectsSection } from "../../components/related-content/ProjectsSection";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

export const EsgProgram = (props: {
  data: IssuerEsgPageData;
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");

  const rfpsPage = rfps({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const rfpsTitle = rfpsPage.title(pages);

  const page = esgProgram({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const description = page.description(pages, iffs, issuer);

  const bondOfferingsO = pipe(
    props.data.bondOfferings,
    RA.partition(_ => _.data.data.record.data.offering.isArchived),
    ({ left: active, right: archived }) => getNonEmptyItemsRecordO({ active, archived })
  );

  const rfpsO = pipe(
    props.data.rfps,
    RA.partition(_ => isRFPArchived(_.data.data.record.data.rfp)),
    ({ left: active, right: archived }) => getNonEmptyItemsRecordO({ active, archived }),
  );

  const roadshowsO = RNEA.fromReadonlyArray(props.data.roadshows);
  const relatedProjectsO = RNEA.fromReadonlyArray(props.data.projects);
  const documentsO = RNEA.fromReadonlyArray(props.data.documents);
  const relatedNewsO = RNEA.fromReadonlyArray(props.data.news);

  const jumpLinks: ReadonlyArray<JumpLink> = makeSitesJumpLinks(iffs, pages)(jl.esgProgram.all(props.data));

  const jumplinksEl = <>
    <SidebarLinksSites
      headerLinkAnchorContent={page.title(pages)}
      jumpLinks={jumpLinks}
      routeMeta={page}
    />
    <GetAlertsActionSection containerKlass={"d-none-until-md"} />
  </>;

  return <DirectSitesPageDescriptionLayout
    description={description}
    sidebarContent={jumplinksEl}
  >
    {pipe(
      O.some(jl.esgProgram.overview(props.data)),
      O.filter(_ => _.enabled(iffs)),
      mapOrEmpty(_ =>
        <OverviewSection
          title={_.text(pages)}
          sectionId={_.sectionId}
          overview={pipe(props.data.overview, O.map(overview => overview.data))}
          facts={props.data.callouts}
          additionalFacts={[]}
          photos={props.data.photos}
          documentsDataO={O.none}
          linksDataO={O.none}
        />)
    )}
    {pipe(bondOfferingsO, mapOrEmpty(bonds => {
      const l = jl.esgProgram.offerings(props.data.bondOfferings);
      return <BondsSection itemName="Bonds" title={l.text(pages)} sectionId={l.sectionId} deals={bonds} />;
    }))}
    {pipe(rfpsO, mapOrEmpty(r => {
      const l = jl.esgProgram.rfps(props.data.rfps);
      return <RfpsSection itemName={rfpsTitle} title={l.text(pages)} sectionId={l.sectionId} deals={r} />;
    }))}
    {pipe(
      roadshowsO,
      mapOrEmpty(roadshows =>
        <AccentDividerSection
          jumpLink={jl.esgProgram.roadshows(props.data.roadshows)}
          pages={pages}
          klasses="accent-border-top"
        >
          <RoadshowCardGrid
            issuer={issuer}
            leafIcon={IssuerSitesLeafIcon}
            roadshows={roadshows}
          />
        </AccentDividerSection>
      ))}
    {pipe(
      relatedProjectsO,
      mapOrEmpty(_ =>
        <ProjectsSection
          projects={_}
          jumpLink={jl.esgProgram.projects(props.data.projects)}
          truncateLength={4}
        />
      )
    )}
    {pipe(
      documentsO,
      mapOrEmpty(documents =>
        <AccentDividerSection
          jumpLink={jl.esgProgram.documents(props.data.documents)}
          pages={pages}
          klasses="accent-border-top"
        >
          <RelatedContentListTruncated
            items={documents}
            issuer={issuer}
            headline="Documents"
            mapFn={documentMap((issuerId, mediaId) => SitesRouter.issuersitesReportsControllerViewFile({
              issuerId,
              mediaId,
              issuerSlug: issuer.slug,
            }), IssuerSitesLeafIcon)}
            truncateLength={4}
          />
        </AccentDividerSection>
      )
    )}
    {pipe(
      relatedNewsO,
      mapOrEmpty(news =>
        <AccentDividerSection
          jumpLink={jl.esgProgram.news(props.data.news)}
          pages={pages}
          klasses="accent-border-top"
        >
          <RelatedContentListTruncated
            items={news}
            issuer={issuer}
            headline="News"
            mapFn={newsMap}
            truncateLength={4}
          />
        </AccentDividerSection>
      )
    )}
  </DirectSitesPageDescriptionLayout>;
};
