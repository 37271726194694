import type { FileType, FileTypeResult, MimeType } from "file-type-ext";
import { fileTypeExt } from "file-type-ext";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as TE from "fp-ts/lib/TaskEither";

import { s } from "@scripts/fp-ts";

const svgMimeType = "image/svg+xml";
const svgExts = ["svg"] as const;
type SvgMimeType = typeof svgMimeType;
type SvgExts = typeof svgExts[number];

const csvMimeType = "text/csv";
const csvExts = ["csv"] as const;
type CsvMimeType = typeof csvMimeType;
type CsvExts = typeof csvExts[number];

type MimeTypeWithCSV = MimeType | CsvMimeType;

type FileTypeResultWithCSV = {
  ext: FileTypeResult["ext"] | CsvExts;
  mime: MimeTypeWithCSV;
  minimumRequiredBytes?: FileTypeResult["minimumRequiredBytes"];
};

export type MimeToExt = { mime: MimeTypeWithCSV | SvgMimeType, exts: ReadonlyArray<FileType | SvgExts | CsvExts> };

export const allowedPdfMimeTypes = [
  { mime: "application/pdf", exts: ["pdf"] },
] satisfies ReadonlyArray<MimeToExt>;

export const allowedDocMimeTypes = [
  ...allowedPdfMimeTypes,
  { mime: "application/postscript", exts: ["ps"] },
  { mime: "application/msword", exts: ["doc"] },
  { mime: "application/vnd.ms-excel", exts: ["xls"] },
  { mime: "application/vnd.ms-powerpoint", exts: ["ppt"] },
  { mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", exts: ["docx"] },
  { mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", exts: ["xlsx"] },
  { mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation", exts: ["pptx"] },
  { mime: "application/vnd.oasis.opendocument.text", exts: ["odt"] },
  { mime: "application/vnd.oasis.opendocument.spreadsheet", exts: ["ods"] },
  { mime: "application/vnd.oasis.opendocument.presentation", exts: ["odp"] },
] satisfies ReadonlyArray<MimeToExt>;

type CSVMimeToExt = { mime: MimeTypeWithCSV, exts: ReadonlyArray<CsvExts> };
export const csvMimeTypes: CSVMimeToExt[] = [{ mime: csvMimeType, exts: csvExts }];

export const allowedImgMimeTypes = [
  { mime: "image/jpeg", exts: ["jpg"] },
  { mime: "image/png", exts: ["png"] },
  { mime: "image/webp", exts: ["webp"] },
] satisfies ReadonlyArray<MimeToExt>;

const allowedSvgMimeTypes = [
  { mime: svgMimeType, exts: svgExts },
] satisfies ReadonlyArray<MimeToExt>;

export const allowedMp3MimeTypes = [
  { mime: "audio/mpeg", exts: ["mp3"] },
] satisfies ReadonlyArray<MimeToExt>;

export const allowedMp4MimeTypes = [
  { mime: "video/mp4", exts: ["mp4"] },
] satisfies ReadonlyArray<MimeToExt>;

export const allowedImgAndSvgMimeTypes = [...allowedImgMimeTypes, ...allowedSvgMimeTypes];

export const mimeToExt = (m: ReadonlyArray<MimeToExt>) => RA.flatten(m.map(_ => _.exts));
export const mimeToHtmlInputAccept = (m: ReadonlyArray<MimeToExt>) => m.map(_ => _.mime).join(",");

/**
Convert Web API File to Node Buffer.
@param {Blob} blob - Web API Blob.
@returns {Promise<Buffer>}
*/

function convertBlobToBuffer(blob: Blob): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("loadend", event => {
      const data = event.target?.result;
      if (data instanceof ArrayBuffer) {
        resolve(new Uint8Array(data));
      }

      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      reject(data);
    });

    fileReader.addEventListener("error", event => {
      reject(new Error(event.target?.error?.message));
    });

    fileReader.addEventListener("abort", event => {
      reject(new Error(event.type));
    });

    fileReader.readAsArrayBuffer(blob);
  });
}

export class MimeError extends Error {
  readonly displayError: string;

  constructor(message: string, displayError: string) {
    super(message);
    this.displayError = displayError;
  }
}

// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
const toMimeError = (e: unknown) => new MimeError(`Unknown error from tryCatch: ${e}`, "Unable to verify file type");

const isCsvExt = (fileExt: string): fileExt is CsvExts => RA.elem(s.Eq)(fileExt)(csvExts);

export const verifyFileType = (allowedMimeTypes: ReadonlyArray<MimeToExt>) => (file: File): TE.TaskEither<MimeError, File> => {
  const fileExt = file.name.slice(file.name.lastIndexOf(".") + 1).toLowerCase();
  const allowSvgs = pipe(
    allowedMimeTypes,
    RA.some(m => svgExts.some(e => m.exts.includes(e)))
  );
  const allowCsv = pipe(
    allowedMimeTypes,
    RA.some(m => csvExts.some(e => m.exts.includes(e)))
  );
  return pipe(
    TE.tryCatch(
      () => convertBlobToBuffer(file),
      toMimeError
    ),
    TE.chain(a => TE.tryCatch(
      async () => {
        if (allowCsv && isCsvExt(fileExt) && file.type === csvMimeType) {
          return O.some<FileTypeResultWithCSV>({
            ext: fileExt,
            mime: file.type,
          });
        }
        return O.fromNullable(await fileTypeExt(a));
      },
      toMimeError
    )),
    TE.chain(
      O.fold(
        () => TE.left(new MimeError("Unable to verify file MIME type", "Unable to verify file type")),
        (a: FileTypeResultWithCSV) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (allowSvgs && allowedSvgMimeTypes.filter(asmt => asmt.mime.includes(file.type))) {
            return TE.right(file);
          }
          const allowedExts = mimeToExt(allowedMimeTypes);
          return pipe(
            O.fromNullable(
              allowedMimeTypes.filter(
                admt => admt.exts.some(r => allowedExts.includes(r))
              ).find(t => t.exts.some(e => e === fileExt))
            ),
            O.fold(
              () => TE.left(new MimeError(
                `File type “${fileExt}” is not a supported upload extension`,
                `“${fileExt}” is not a supported file type. Accepted types: ${allowedExts.join(", ")}`
              )),
              (fm: MimeToExt) => {
                if (a.mime === fm.mime) {
                  return TE.right(file);
                }
                return TE.left(new MimeError(
                  `File MIME type “${a.mime}” does not match file extension “${file.type}”`,
                  `Underlying file type “${a.ext}” does not match the extension “${fileExt}”`
                ));
              }
            ),
          );
        }
      )
    )
  );
};
