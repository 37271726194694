import { E } from "@scripts/fp-ts";
import * as p from "@scripts/generated/domaintables/pages";
import type { BondProgram } from "@scripts/generated/models/bondProgramBase";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";

export const allStatic: ReadonlyArray<SitesJumpLink> = [SitesJumpLink("Programs", [E.right(p.bondProgramsPage)])];

export const program = (prog: WithStatusU<BondProgram>): SitesJumpLink =>
  SitesJumpLink(prog.data.record.name, [], { overrideAnchor: `bond-program-${prog.data.id}` });

export const all = (programs: ReadonlyArray<WithStatusU<BondProgram>>): ReadonlyArray<SitesJumpLink> =>
  programs.map(program);
