import type { BLConfigWithLog } from "@scripts/bondlink";
import type { LinkableU } from "@scripts/codecs/linkable";
import type { AnchorProps } from "@scripts/react/components/Anchor";
import { AnchorWithChildren } from "@scripts/react/components/Anchor";
import { klassPropO } from "@scripts/react/util/classnames";
import type { PageRouteBasic } from "@scripts/routes/routing/base";

export const toPageRouteLinkBasic = (linkable: LinkableU): PageRouteBasic => ({
  title: linkable.displayName,
  route: {
    method: "GET",
    url: linkable.url,
  },
});

export const toAnchor = (config: BLConfigWithLog) => (linkable: LinkableU, anchorTagProps?: Omit<AnchorProps, "target" | "route">): React.ReactNode => {
  const route = toPageRouteLinkBasic(linkable);
  const props = anchorTagProps == null ? {} : anchorTagProps;
  const propsWithMergedKlasses = { ...props, klasses: klassPropO("login-to-download")(props.klasses).className };

  switch (linkable._tag) {
    case "PageLink": {
      return <AnchorWithChildren
        target="_self"
        route={route}
        {...props}
      >
        {linkable.displayName}
      </AnchorWithChildren>;
    }
    case "ExternalLink": {
      return <AnchorWithChildren
        target="_blank"
        route={route}
        {...props}
      >
        {linkable.displayName}
      </AnchorWithChildren>;
    }
    case "DownloadLink": {
      return <AnchorWithChildren
        target="_self"
        route={route}
        aria-label={`Download ${linkable.displayName}`}
        {...propsWithMergedKlasses}
      >
        {linkable.displayName}
      </AnchorWithChildren>;
    }
  }
  return config.exhaustive(linkable);
};
