import type { HTMLAttributes } from "react";
import { untupled } from "fp-ts/lib/function";

import { identity } from "@scripts/fp-ts";

export type WithHTMLAttrs<T, U extends HTMLAttributes<HTMLElement> = HTMLAttributes<HTMLElement>> = T & U;

type TagName = keyof HTMLElementTagNameMap;

export const declareTagNames: <Tags extends ReadonlyArray<TagName>>(...tags: Tags) => Tags =
  untupled(identity);
