import { O, pipe } from "@scripts/fp-ts";
import { bondOfferings } from "@scripts/generated/domaintables/featureFlags";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { ArchivedBondsSsrData } from "@scripts/generated/models/bondsSsrData";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { bondArchive } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { isFFEnabled } from "@scripts/syntax/featureFlags";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";
import { deepLink } from "@scripts/util/url";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { GeneralOfferingInfo } from "../../components/offering-pages/GeneralOfferingInfo";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";
import { BondSales } from "./BondSales";

const setupSidebarLinkSections = (
  ffs: ClientFeatureFlags,
  pages: ReadonlyArray<PageConfig<PageU>>,
): ReadonlyArray<JumpLink> =>
  makeSitesJumpLinks(ffs, pages)(jl.archivedBonds.allStatic);

export const BondArchive = (props: ArchivedBondsSsrData) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");

  const bondArchiveRouteMeta = bondArchive({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const bondArchiveDescription = bondArchiveRouteMeta.description(pages, iffs, issuer);

  const activeBondsEnabled = isFFEnabled(bondOfferings)(iffs);

  const sidebarLinks = setupSidebarLinkSections(iffs, pages);

  return <DirectSitesPageDescriptionLayout
    description={bondArchiveDescription}
    relatedPage={activeBondsEnabled
      ? O.some({
        text: "View Current Bond Offerings",
        url: deepLink({
          type: "Basic",
          route: SR.issuersitesBondOfferingsControllerIndex,
          selector: jl.bonds.bondSales.sectionId,
        })({ issuerId: issuer.id, issuerSlug: issuer.slug }).url,
      })
      : O.none
    }
    sidebarContent={
      <>
        <SidebarLinksSites
          headerLinkAnchorContent={bondArchiveRouteMeta.title()}
          jumpLinks={sidebarLinks}
          routeMeta={bondArchiveRouteMeta}
        />
        <GetAlertsActionSection containerKlass={"d-none-until-md"} />
      </>
    }
  >
    <BondSales
      activeBonds={props.offerings}
      isBondArchiveEnabled={false}
      jumpLink={jl.archivedBonds.archivedBondSales}
    />
    {jl.bonds.generalOfferingInfo.enabled(iffs) && pipe(
      props.generalInfo,
      mapOrEmpty(generalInfo => <GeneralOfferingInfo offeringInfo={generalInfo} />)
    )}
  </DirectSitesPageDescriptionLayout>;
};
