import pluralize from "pluralize";

import { b, E, type Lazy, Mn, N, O, pipe, RA } from "@scripts/fp-ts";
import { documents, showArchivedDocs, showIRMALetter } from "@scripts/generated/domaintables/featureFlags";
import type { MediaCategoryU } from "@scripts/generated/domaintables/mediaCategories";
import type { CustomPageDataO } from "@scripts/generated/models/customPages";
import type { DocumentType } from "@scripts/generated/models/document";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { ButtonLinkMultiline, ButtonPrimary } from "@scripts/react/components/Button";
import { Card, CardBody } from "@scripts/react/components/card/Card";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { Grid, GridCol } from "@scripts/react/components/layout/Grid";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import type { JumpLink, SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import { klass } from "@scripts/react/util/classnames";
import { openInSameTab } from "@scripts/routes/router";
import { documentCategories, type DocumentCategoriesPageData } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { isFFEnabled } from "@scripts/syntax/featureFlags";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";

import { CustomPageSection } from "../../components/CustomPageSection";
import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

type NavigateFn = (mediaCategoryId: O.Option<number>, docTypeId: O.Option<number>) => void;
type Category = [E.Either<DocumentType, MediaCategoryU>, number];
type CategoryArray = ReadonlyArray<Category>;

const sumTotal: (a: CategoryArray) => number = RA.foldMap(N.MonoidSum)(([, count]) => count);

const CategoryCardBody = (props: {
  title: string;
  count: number;
  onClick: Lazy<void>;
}) => <CardBody klass={["d-flex", "flex-col", "align-items-start"]}>
    <ButtonLinkMultiline
      {...klass("h5", "font-sans-normal-500")}
      text={props.title}
      onClick={props.onClick}
    />
    <span {...klass("gray-600")}>{props.count} {pluralize("document", props.count)}</span>
  </CardBody>;

const CategoryCard = (props: {
  title: string;
  count: number;
  onClick: Lazy<void>;
}) => <GridCol cols={[".c-md-12", ".c-lg-8"]} klasses={O.none}>
    <Card klasses={["card-link", "h-100"]} onClick={props.onClick}>
      <CategoryCardBody
        title={props.title}
        count={props.count}
        onClick={props.onClick}
      />
    </Card>
  </GridCol>;

const foldCard = (navigate: NavigateFn) => ([type, count]: Category) => pipe(
  type,
  E.fold(
    ({ id, categoryName }) => <CategoryCard
      key={id}
      title={categoryName}
      count={count}
      onClick={() => navigate(O.none, O.some(id))}
    />,
    ({ id, categoryName }) => <CategoryCard
      key={id}
      title={categoryName}
      count={count}
      onClick={() => navigate(O.some(id), O.none)}
    />
  ),
);

const allDocumentsTitle = "All Documents";
const allArchivedDocumentsTitle = "All Archived Documents";
const CategorySection = (props: {
  jumpLink: SitesJumpLink;
  allDocsTitle: string;
  categories: CategoryArray;
  navigate: NavigateFn;
}) => {
  const pages = useIssuerSitesSelector("pages");

  return <AccentDividerSection
    jumpLink={props.jumpLink}
    pages={pages}
    key={props.jumpLink.sectionId}
    klasses={O.none}
  >
    <Grid attrs={O.some(".grid-sa-1")} klasses={O.some("card-category-grid")}>
      <CategoryCard
        title={props.allDocsTitle}
        count={sumTotal(props.categories)}
        onClick={() => props.navigate(O.none, O.none)}
      />
      {props.categories.map(foldCard(props.navigate))}
    </Grid>
  </AccentDividerSection>;
};

export const DocumentCategoriesPage = (props: {
  data: DocumentCategoriesPageData;
}) => {
  const pages = useIssuerSitesSelector("pages");
  const issuer = useIssuerSitesSelector("issuer");
  const iffs = useIssuerSitesSelector("iffs");

  const issuerMeta = { issuerId: issuer.id, issuerSlug: issuer.slug };
  const categoriesMeta = documentCategories(issuerMeta);

  const navigateToDownloads = (
    mediaCategoryId: O.Option<number>,
    docTypeId: O.Option<number>
  ) => openInSameTab(SitesRouter.issuersitesReportsControllerDownloads({
    ...issuerMeta,
    mediaCategoryId,
    docTypeId,
  }).url);

  const navigateToArchivedDownloads = (
    mediaCategoryId: O.Option<number>,
    docTypeId: O.Option<number>
  ) => openInSameTab(SitesRouter.issuersitesReportsControllerArchiveDownloads({
    ...issuerMeta,
    mediaCategoryId,
    docTypeId,
  }).url);

  const showCategories = RA.isNonEmpty(props.data.categories) && isFFEnabled(documents)(iffs);
  const showArchivedCategories = RA.isNonEmpty(props.data.archivedCategories) && isFFEnabled(showArchivedDocs)(iffs);
  const showIrma = O.isSome(props.data.irmaLetter) && isFFEnabled(showIRMALetter)(iffs);

  const hasContent = Mn.concatAll(b.MonoidAny)([showCategories, showArchivedCategories, showIrma, RA.isNonEmpty(props.data.customPages)]);

  const jumpLinks: ReadonlyArray<JumpLink> =
    makeSitesJumpLinks(iffs, pages)(jl.documents.allStatic.concat(props.data.customPages.map(jl.customPage)));

  const navigateToIRMALetter = () => openInSameTab(SitesRouter.issuersitesReportsControllerIrmaLetter(issuerMeta).url);

  return <DirectSitesPageDescriptionLayout
    description={categoriesMeta.description(pages, iffs, issuer)}
    sidebarContent={<>
      <SidebarLinksSites
        headerLinkAnchorContent={categoriesMeta.title()}
        jumpLinks={jumpLinks}
        routeMeta={categoriesMeta}
      />
      <GetAlertsActionSection containerKlass={"d-none d-md-block"} />
    </>}
  >
    <div {...klass("accent-border-top", "pt-0")}>
      {showCategories && <CategorySection
        jumpLink={jl.documents.categories}
        allDocsTitle={allDocumentsTitle}
        categories={[...props.data.categories]}
        navigate={navigateToDownloads}
      />}
      {showArchivedCategories && <CategorySection
        jumpLink={jl.documents.archivedCategories}
        allDocsTitle={allArchivedDocumentsTitle}
        categories={props.data.archivedCategories}
        navigate={navigateToArchivedDownloads}
      />}
      {showIrma && mapOrEmpty(() => <AccentDividerSection title={O.some(jl.documents.irmaLetter.text(pages))} sectionId={jl.documents.irmaLetter.sectionId}>
        <ButtonPrimary onClick={navigateToIRMALetter}>View IRMA Letter</ButtonPrimary>
      </AccentDividerSection>)(props.data.irmaLetter)}
      {RA.map((customPage: CustomPageDataO) =>
        <CustomPageSection
          customPage={customPage}
          key={customPage.page.name}
        />
      )(props.data.customPages)}
    </div>
    {trueOrEmpty(<AccentDividerSection title={O.none}><p>There are currently no documents available.</p></AccentDividerSection>)(!hasContent)}
  </DirectSitesPageDescriptionLayout>;
};
