import { E } from "@scripts/fp-ts";
import * as f from "@scripts/generated/domaintables/featureFlags";
import * as p from "@scripts/generated/domaintables/pages";
import { programsMeta, rfpsMeta } from "@scripts/meta/dataMeta";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";

export const bondSales = SitesJumpLink("Bond Sales", [E.left(f.bondOfferings), E.right(p.bondArchive)]);

export const bondArchive = SitesJumpLink("Bond Archive", [E.left(f.showArchivedBonds)]);

export const rfps = SitesJumpLink(rfpsMeta, [E.left(f.activeRfps), E.right(p.rfpsPage), E.left(f.archivedRfps)]);

export const roadshows = SitesJumpLink("Roadshows", [E.left(f.roadShows)]);

export const ratings = SitesJumpLink("Ratings", [E.right(p.bondRatings)], { overrideAnchor: "bond-ratings" });

export const programs = SitesJumpLink(programsMeta, [E.right(p.bondProgramsPage)]);

export const participants = SitesJumpLink("Offering Participants", [E.left(f.offeringParticipants)]);

export const generalOfferingInfo = SitesJumpLink("General Offering Information", [E.left(f.bondOfferingsGeneralInfo)]);

export const allStatic: ReadonlyArray<SitesJumpLink> = [bondSales, rfps, roadshows, ratings, programs, participants];
