import { O, pipe, RA } from "@scripts/fp-ts";
import { sequenceS } from "@scripts/fp-ts/Apply";
import { pick } from "@scripts/fp-ts/struct";
import { type PageU } from "@scripts/generated/domaintables/pages";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import type { UserWithRoles } from "@scripts/generated/models/user";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { emmaName } from "@scripts/literals/emma";
import { Anchor, AnchorButton } from "@scripts/react/components/Anchor";
import { constEmpty, mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { type ResourcePageData, resources, team } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { phoneWithExtension } from "@scripts/syntax/phoneNumber";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";

import { ContactModalCallout } from "@scripts-ssr/components/ContactModal";

import { CustomPageSection } from "../../components/CustomPageSection";
import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

const setupSidebarLinkSections = (
  ffs: ClientFeatureFlags,
  pages: ReadonlyArray<PageConfig<PageU>>,
  customPages: ResourcePageData["customPages"],
): ReadonlyArray<JumpLink> =>
  makeSitesJumpLinks(ffs, pages)(jl.resources.allStatic.concat(customPages.map(jl.customPage)));

export const ResourcesPage = (props: { data: ResourcePageData }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");
  const user = useIssuerSitesSelector("user");
  const resourcesRouteMeta = resources({ issuerSlug: issuer.slug, issuerId: issuer.id });

  const sidebarLinks = setupSidebarLinkSections(iffs, pages, props.data.customPages);

  return (
    <DirectSitesPageDescriptionLayout
      description={resourcesRouteMeta.description(pages, iffs, issuer)}
      sidebarContent={
        <>
          <SidebarLinksSites
            headerLinkAnchorContent={resourcesRouteMeta.title(pages)}
            jumpLinks={sidebarLinks}
            routeMeta={resourcesRouteMeta}
          />
          <GetAlertsActionSection containerKlass={"d-none-until-md"} />
        </>
      }
    >
      {trueOrEmpty(
        <AccentDividerSection jumpLink={jl.resources.emma} pages={pages} klasses="accent-border-top">
          <p>View a list of our CUSIPs and see links to more information about our municipal bonds on the {emmaName} website.</p>
          <AnchorButton
            externalLinkLocation="none"
            klasses={"mt-1"}
            route={{
              title: `View ${jl.resources.emma.text(pages)}`,
              route: SR.issuersitesEmmaControllerLinks({
                issuerId: issuer.id,
                issuerSlug: issuer.slug,
                cusip9: O.none,
              }),
            }}
            target="_self"
            variant="primary"
          />
        </AccentDividerSection>
      )(jl.resources.emma.enabled(iffs))}
      {trueOrEmpty(
        <AccentDividerSection jumpLink={jl.resources.faq} pages={pages} klasses="accent-border-top">
          <p>Looking for more information about us and our bond programs? Get answers to common questions by following the link below.</p>
          <AnchorButton
            externalLinkLocation="none"
            klasses={"mt-1"}
            route={{
              title: "View FAQ",
              route: SR.issuersitesResourcesControllerFaq({
                issuerId: issuer.id,
                issuerSlug: issuer.slug,
              }),
            }}
            target="_self"
            variant="primary"
          />
        </AccentDividerSection>
      )(jl.resources.faq.enabled(iffs))}
      {trueOrEmpty(
        <AccentDividerSection jumpLink={jl.resources.links} pages={pages} klasses="accent-border-top">
          <p>View links to related sites and other resources.</p>
          <AnchorButton
            externalLinkLocation="none"
            klasses={"mt-1"}
            route={{
              title: `View ${jl.resources.links.text(pages)}`,
              route: SR.issuersitesResourcesControllerLinks({
                issuerId: issuer.id,
                issuerSlug: issuer.slug,
              }),
            }}
            target="_self"
            variant="primary"
          />
        </AccentDividerSection>
      )(jl.resources.links.enabled(iffs))}
      {trueOrEmpty(
        <AccentDividerSection jumpLink={jl.resources.contact} pages={pages} klasses="accent-border-top">
          <p>Still have questions? You can reach us by mail, phone, or email.</p>
          {pipe(
            sequenceS(O.Apply)(pick("address", "city")(props.data.address)),
            mapOrEmpty(({ address, city }) =>
              <div className="d-flex flex-col">
                <h5 className="mb-0">Mailing Address</h5>
                <p className="mb-0">{address}</p>
                <p className="mb-0">{city}, {props.data.address.state.abbrev} {pipe(props.data.address.postalCode, O.getOrElse(() => ""))}</p>
                {O.fold(constEmpty, (phoneAndExt: string) => <p className="mb-0">Phone: {phoneAndExt}</p>)(phoneWithExtension(O.some(issuer.phoneNumber), issuer.phoneExtension))}
              </div>
            )
          )}
          {pipe(
            props.data.customContact,
            mapOrEmpty(_ => <Markdown klasses={["mt-1", "last-child-mb-0"]} content={_.data} />)
          )}
          <ContactModalCallout
            btnText={"Email Us"}
            bondOfferingId={O.none}
            header={"Contact Us"}
            introText={
              <div className="d-flex flex-col">
                <p>Your message will be sent securely and someone will reply to you shortly.</p>
                <p className="mb-0">
                  Looking for direct contact information?
                  {" "}
                  <Anchor target="_self" route={{ ...team({ issuerId: issuer.id, issuerSlug: issuer.slug }), title: () => `View the ${jl.about.team.text(pages)} page for details.` }} />
                </p>
              </div>
            }
            issuer={issuer}
            rfpId={O.none}
            user={O.map((u: UserWithRoles) => u.user)(user)}
            klasses="mt-2"
          />
        </AccentDividerSection>
      )(jl.resources.contact.enabled(iffs))}
      {pipe(
        props.data.customPages,
        RA.map(customPage => <CustomPageSection
          klasses="accent-border-top"
          customPage={customPage}
          key={customPage.page.name}
        />)
      )}
    </DirectSitesPageDescriptionLayout>
  );
};
