import { Lens } from "monocle-ts";

import type { RfpSitesRelatedContent } from "@scripts/generated/models/rfp";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import type { Subscribed } from "@scripts/generated/models/subscribed";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";

export type IssuerSitesRfp = Subscribed<WithStatusU<TaggedContent<{
  rfp: Rfp;
  relatedContent: Pick<RfpSitesRelatedContent, "roadShows" | "bidSubmissionTemplate" | "bidSubmission">;
}>>>;

const issuerSitesRfpLens = <R extends IssuerSitesRfp>() => Lens.fromProp<R>();

export const bidSubmissionLens = <R extends IssuerSitesRfp>() => issuerSitesRfpLens<R>()("data").compose(Lens.fromPath<IssuerSitesRfp["data"]>()(["data", "record", "data", "relatedContent", "bidSubmission"]));
