import { type O, pipe } from "@scripts/fp-ts";
import type { ClientTextItem } from "@scripts/generated/models/clientTextItem";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Markdown } from "@scripts/react/components/Markdown";

export const PartnerLanderWelcomeLetter = (props: { welcomeLetter: O.Option<ClientTextItem> }) =>
  pipe(props.welcomeLetter, mapOrEmpty(_ => <>
    <h2>Welcome Letter</h2>
    <Markdown content={_.data} />
  </>));
