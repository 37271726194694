import type { ClientTextItem } from "@scripts/generated/models/clientTextItem";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";

import { useIssuerSitesSelector } from "../../state/store";

export const GeneralOfferingInfo = (props: { offeringInfo: ClientTextItem }) => {
  const pages = useIssuerSitesSelector("pages");
  return <AccentDividerSection jumpLink={jl.bonds.generalOfferingInfo} pages={pages}>
    <Markdown content={props.offeringInfo.data} klasses={["last-child-mb-0"]} />
  </AccentDividerSection>;
};
