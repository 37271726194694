import type { ReactElement, ReactNode } from "react";
import type { Column } from "@devexpress/dx-react-grid";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { RA } from "@scripts/fp-ts";
import { useConfig } from "@scripts/react/context/Config";
import { klassNullableProp } from "@scripts/react/util/classnames";

import { Empty } from "../../Empty";
import type { CustomTableComponent, HeaderComponent, TableRowModel } from "../tableSyntax";

export const draggableColumn: Column = { name: "drag", title: "drag" };

const makeHeaderComponent = <A, MetaData>(
  headerComponent: HeaderComponent<A, MetaData>,
  row: TableRowModel<A, MetaData>,
  title: string,
  config: BLConfigWithLog,
): ReactNode => {
  if (headerComponent === "empty") {
    return <Empty />;
  } else if (headerComponent === "title") {
    return title;
  } else if (typeof headerComponent === "function") {
    return headerComponent(row);
  }
  return config.exhaustive(headerComponent);
};

export function TableHeaderCell<A, MetaData>(props: {
  column: CustomTableComponent<A, MetaData>;
  row: TableRowModel<A, MetaData>;
}): ReactElement {
  const config = useConfig();
  return (
    <th {...klassNullableProp(props.column.headerCellKlass)} aria-label={props.column.title}>
      {props.column.title === draggableColumn.title
        ? <Empty />
        : makeHeaderComponent(props.column.headerComponent, props.row, props.column.title, config)}
    </th>
  );
}

export const SplitLineTableHeaderCell = (props: { lines: ReadonlyArray<string> }) =>
  <>{RA.map((line: string) => <div key={line}>{line}</div>)(props.lines)}</>;
