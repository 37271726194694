import type { PropsWithChildren } from "react";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { b, pipe } from "@scripts/fp-ts";

import { useConfig } from "../context/Config";
import { klassNullableProp, type KlassProp } from "../util/classnames";

/** @knipignore - used in corp */
export const socialLinks = ["twitter", "facebook", "linkedin", "email"] as const;
export type SocialLinkType = (typeof socialLinks)[number];

const shareUrl = (config: BLConfigWithLog) => (socialSite: SocialLinkType, url: string, title: string) => {
  switch (socialSite) {
    case "facebook":
      return `https://www.facebook.com/sharer/sharer.php?t=${title}&u=${url}`;
    case "twitter":
      return `https://twitter.com/share?text=${title}&via=BondLink&url=${url}`;
    case "linkedin":
      return `https://www.linkedin.com/feed/?shareActive=true&shareUrl=${url}&text=${title}`;
    case "email":
      return `mailto:?subject=${title}&body=${url}`;
  }
  config.exhaustive(socialSite);
};

const openShareUrl = (config: BLConfigWithLog) => (socialSite: SocialLinkType, url: string, title: string) => pipe(
  shareUrl(config)(socialSite, encodeURI(url), encodeURIComponent(title)),
  _ => {
    if (typeof window !== "undefined") {
      pipe(
        socialSite === "email",
        b.fold(
          () => window.open(
            _,
            "_blank",
            "menubar=no,toolbar=no,resizable=yes,scrollbars=yes"
          ),
          () => window.open(_)
        )
      );
    }
  }
);


type SocialShareLinkProps = PropsWithChildren<{
  klass?: KlassProp;
  type: SocialLinkType;
  url: string;
  title: string;
}>;

export const SocialShareLink = (props: SocialShareLinkProps) => {
  const config = useConfig();
  const onClick = () => openShareUrl(config)(props.type, props.url, props.title);

  return <a
    {...klassNullableProp(props.klass)}
    onClick={onClick}
    aria-label="Share"
    rel="nofollow noopener"
  >{props.children}</a>;
};
