import { useCallback } from "react";
import type * as t from "io-ts";

import type { SVGString } from "*.svg";

import { O, pipe } from "@scripts/fp-ts";
import { news as newsPostType, pressRelease } from "@scripts/generated/domaintables/postTypes";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { ContentTypeDetails } from "@scripts/react/components/activities/ContentTypeDetails";
import { Anchor } from "@scripts/react/components/Anchor";
import { ButtonIcon } from "@scripts/react/components/Button";
import { CopyText } from "@scripts/react/components/CopyText";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { type SocialLinkType, SocialShareLink } from "@scripts/react/components/SocialShareLink";
import { Svg } from "@scripts/react/components/Svg";
import { useConfig } from "@scripts/react/context/Config";
import { type IssuerSitesRouteMeta, newsItem, type NewsItemData, type NewsItemDataC } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { humanDateLong } from "@scripts/syntax/date/joda";
import { issuerSiteAbsUrl } from "@scripts/syntax/issuer";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";

import bullhornIcon from "@svgs/bullhorn.svg";
import facebookIcon from "@svgs/facebook.svg";
import linkIcon from "@svgs/link.svg";
import linkedinIcon from "@svgs/linkedin.svg";
import newsIcon from "@svgs/newspaper.svg";
import twitterIcon from "@svgs/twitter.svg";

import { DirectSitesPageLayoutBase } from "../../components/DirectSitesPageLayout";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { AllRelatedContentSection } from "../../components/offering-pages/RelatedContent";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

const NewsSidebarLinks = (props: {
  jumplinks: ReadonlyArray<JumpLink>;
  routeMeta: IssuerSitesRouteMeta<NewsItemData, t.OutputOf<NewsItemDataC>, "news-item">;
  news: NewsItemData;
}) =>
  <SidebarLinksSites
    headerLinkAnchorContent={props.news.data.record.data.news.newsTitle}
    jumpLinks={props.jumplinks}
    routeMeta={props.routeMeta}
  />;

const NewsShareLink = (props: { newsTitle: string, newsUrl: string, type: SocialLinkType, svg: SVGString }) =>
  <SocialShareLink
    klass="btn btn-social no-decoration mt-0"
    type={props.type}
    url={props.newsUrl}
    title={props.newsTitle}
  >
    <Svg src={props.svg} />
  </SocialShareLink>;

const NewsShareLinks = (props: { news: NewsItemData }) => {
  const config = useConfig();
  const issuer = useIssuerSitesSelector("issuer");
  const newsTitle = props.news.data.record.data.news.newsTitle;
  const newsUrl = issuerSiteAbsUrl(config)(issuer, O.none)(SR.issuersitesAboutControllerNewsItem)({ newsId: props.news.data.id }).url;
  const copyTrigger = useCallback((onClick: () => void) =>
    <ButtonIcon
      variant="none"
      className="btn-social mt-0"
      onClick={onClick}
      icon={linkIcon}
      aria-label="Copy Link"
    />,
    []
  );

  return <div className="social-icons text-center">
    <div className="social-icons-row">
      <CopyText value={newsUrl} trigger={copyTrigger} />
      <NewsShareLink newsTitle={newsTitle} newsUrl={newsUrl} type="twitter" svg={twitterIcon} />
    </div>
    <div className="social-icons-row">
      <NewsShareLink newsTitle={newsTitle} newsUrl={newsUrl} type="facebook" svg={facebookIcon} />
      <NewsShareLink newsTitle={newsTitle} newsUrl={newsUrl} type="linkedin" svg={linkedinIcon} />
    </div>
  </div>;
};

export const NewsItem = (props: { news: NewsItemData }) => {
  const config = useConfig();
  const iffs = useIssuerSitesSelector("iffs");
  const pages = useIssuerSitesSelector("pages");
  const issuer = useIssuerSitesSelector("issuer");
  const page = newsItem({ issuerId: issuer.id, issuerSlug: issuer.slug, newsId: props.news.data.id });

  const relatedContentJL = jl.relatedContent(props.news.data.record.data.relatedContent);
  const sidebarLinks = makeSitesJumpLinks(iffs, pages)([relatedContentJL]);

  const dateElement = pipe(props.news.data.record.data.news.newsDate, mapOrEmpty(d => <>{humanDateLong(d)}</>));

  return <DirectSitesPageLayoutBase
    headerComponent={O.none}
    sidebarContent={
      <>
        <NewsSidebarLinks
          jumplinks={sidebarLinks}
          news={props.news}
          routeMeta={page}
        />
        <GetAlertsActionSection containerKlass={"d-none-until-md"} />
      </>
    }
  >
    <h2 className="mb-0">
      {props.news.data.record.data.news.newsTitle}
      <IssuerSitesLeafIcon taggedContent={props.news.data.record} />
    </h2>
    <Anchor
      target="_self"
      route={{ title: "View all", route: SR.issuersitesAboutControllerNewsEvents({ issuerId: issuer.id, issuerSlug: issuer.slug }) }}
      arrowType="right"
      klasses="d-inline-block mb-1"
    />
    <AccentDividerSection title={O.none} klasses="accent-border-top">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <ContentTypeDetails
          accentColor="default"
          subheader={props.news.data.record.data.news.postType.name}
          date={dateElement}
          icon={(() => {
            switch (props.news.data.record.data.news.postType._tag) {
              case newsPostType._tag: return newsIcon;
              case pressRelease._tag: return bullhornIcon;
              default: return config.exhaustive(props.news.data.record.data.news.postType);
            }
          })()}
          iconBackgroundColor="green"
        />
        <NewsShareLinks news={props.news} />
      </div>
      <Markdown content={props.news.data.record.data.news.newsBlurb} />
    </AccentDividerSection>
    {pipe(
      relatedContentJL.enabled(iffs),
      trueOrEmpty(<AllRelatedContentSection relatedContent={props.news.data.record.data.relatedContent} />)
    )}
  </DirectSitesPageLayoutBase>;
};
