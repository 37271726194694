import type { ReactElement } from "react";
import { Fragment, useEffect, useState } from "react";
import { makeBy } from "fp-ts/lib/ReadonlyArray";

import { klass } from "@scripts/react/util/classnames";

import { Empty } from "../Empty";

function paginate<A>(array: ReadonlyArray<A>, size: number, page: number): ReadonlyArray<A> {
  return array.slice((page - 1) * size, page * size);
}

export const defaultPageSize = 20;

type Page = {
  page: number;
  size: number;
};

type PaginatorMeta = {
  totalPages: number;
  pageSize: number;
  currentPage: number;
  move: (newPage: number) => void;
};

type PaginatorData<A> = {
  items: ReadonlyArray<A>;
  allItems: ReadonlyArray<A>;
  paginatorMeta: PaginatorMeta;
};

export function usePaginator<A>(
  data: ReadonlyArray<A>,
  cPage: number,
  onInvalidPage: () => void,
  size: number = defaultPageSize,
): [PaginatorData<A>, (e: ReadonlyArray<A>) => void] {
  const [source, setSource] = useState(data);
  const [page, setPage] = useState<Page>({ page: cPage, size: size });
  const [items, setItems] = useState(paginate(source, page.size, page.page));
  const totalPages = Math.ceil(source.length / page.size);

  useEffect(() => setPage({ page: cPage, size: size }), [cPage, size]);
  useEffect(() => setItems(paginate(source, page.size, page.page)), [page, source]);
  useEffect(() => {
    if ((data.length > 0 && items.length <= 0 && page.page > 1) || page.page <= 0) {
      setItems(paginate(source, page.size, 1));
      onInvalidPage();
    }
  }, [items, data.length, onInvalidPage, page.page, page.size, source]);
  const paginator: PaginatorData<A> = {
    items: items,
    allItems: data,
    paginatorMeta: {
      totalPages: totalPages,
      pageSize: page.size,
      currentPage: page.page,
      move: (val) => setPage({ page: val, size: page.size }),
    },
  };
  return [paginator, setSource];
}

export function Paginator(props: PaginatorMeta & { navigate: (page: number) => void, startIndex: number }): ReactElement {
  const { currentPage, totalPages } = props;
  const cp = currentPage + props.startIndex;
  const onPageChanged = (page: number) => {
    // Update Paginator current page
    props.move(page);
    // Update Parent component URL
    props.navigate(page);
  };
  return (
    totalPages > 1
      ? <ul {...klass("dataTable-pagination", "mb-0")}>
        <li {...klass("pager", cp <= 1 ? "disabled" : "")}>
          <a onClick={() => onPageChanged(currentPage - 1)}>&larr;</a>
        </li>
        {cp >= 5 && <Fragment>
          <li>
            <a onClick={() => onPageChanged(0)}>1</a>
          </li>
          <li {...klass("ellipsis")}>
            <a>&hellip;</a>
          </li>
        </Fragment>}
        {makeBy(props.totalPages, (pn) => {
          const page = pn + 1;
          const isActive = cp === page;
          if (page >= totalPages && page > 5) return <Empty key={pn} />;
          return (
            (page === cp - 1)
            || (page === cp + 1)
            || (cp < 5 && page <= 5)
            || isActive
          )
            ? <li {...klass(isActive ? "active" : "")} key={pn}>
              <a onClick={() => onPageChanged(props.startIndex === 1 ? pn : page)}>{page}</a>
            </li>
            : <Empty key={pn} />;
        })}
        {props.totalPages > 5 && <Fragment>
          {(cp < totalPages - 1) && <li {...klass("ellipsis")}>
            <a>&hellip;</a>
          </li>}
          <li {...klass(cp === props.totalPages ? "active" : "")}>
            <a onClick={() => onPageChanged(props.totalPages)}>{props.totalPages}</a>
          </li>
        </Fragment>}
        <li {...klass("pager", cp === totalPages ? "disabled" : "")}>
          <a onClick={() => onPageChanged(currentPage + 1)}>&rarr;</a>
        </li>
      </ul>
      : <Empty />
  );
}
