import { type PropsWithChildren, useMemo } from "react";

import { O, pipe, RA } from "@scripts/fp-ts";
import { bidAwardedC } from "@scripts/generated/domaintables/biddingStates";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import { klass } from "@scripts/react/util/classnames";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { humanDateFull } from "@scripts/syntax/date/joda";
import { formatPercentage } from "@scripts/syntax/number";
import { currency } from "@scripts/util/currency";

import { mapOrEmpty, trueOrEmpty } from "../Empty";
import { FactsRow, type FactType } from "../Facts";
import { AccentDividerSection, DividerSection } from "../layout/Section";
import { Markdown } from "../Markdown";

const formatFact = <A,>(title: string, format: (val: A) => string): (factO: O.Option<A>) => O.Option<FactType> => O.map((v: A) => ({
  title: title,
  value: format(v),
}));

const getWinningBidFacts = (rfp: Rfp) => {
  const rateFact = formatFact("Interest Rate", formatPercentage(2))(rfp.winningBidRate);
  const feesFact = formatFact("Bank/Counsel Fees", currency)(rfp.winningBidFees);
  const dateRecievedFact = formatFact("Date Bid Received", humanDateFull)(rfp.winningBidReceived);

  return RA.compact([rateFact, feesFact, dateRecievedFact]);
};

const showWinningBidSection = (rfp: Rfp, winningBidFacts: ReadonlyArray<FactType>) =>
  bidAwardedC.is(rfp.biddingState)
  && (O.isSome(rfp.winningBidCompany) || RA.isNonEmpty(winningBidFacts) || O.isSome(rfp.winningBidAdditionalInfo));

const showWinningBidSectionSites = (rfp: Rfp, winningBidFacts: ReadonlyArray<FactType>) =>
  rfp.showWinningBid && showWinningBidSection(rfp, winningBidFacts);

const WinningBidInfo = (props: Pick<Rfp, "winningBidCompany" | "winningBidAdditionalInfo"> & {
  facts: ReadonlyArray<FactType>;
}) => <div>
    {pipe(props.winningBidCompany, mapOrEmpty((company) => <h5 {...klass("mb-1")}>{company}</h5>))}
    <div {...klass("mb-1")}><FactsRow variant="small" items={props.facts} /></div>
    {pipe(props.winningBidAdditionalInfo, mapOrEmpty((md) => <Markdown content={md} />))}
  </div>;

export const WinningBidSection = (props: PropsWithChildren<{
  rfp: Rfp;
}>) => {
  const facts = useMemo(() => getWinningBidFacts(props.rfp), [props.rfp]);
  const showSection = showWinningBidSection(props.rfp, facts);

  return trueOrEmpty(<DividerSection title={O.some(jl.rfp.winningBid(props.rfp).defaultText)}>
    <WinningBidInfo
      winningBidCompany={props.rfp.winningBidCompany}
      facts={facts}
      winningBidAdditionalInfo={props.rfp.winningBidAdditionalInfo}
    />
    {props.children}
  </DividerSection>)(showSection);
};

export const WinningBidSectionSites = (props: {
  rfp: Rfp;
  pages: ReadonlyArray<PageConfig<PageU>>;
}) => {
  const facts = useMemo(() => getWinningBidFacts(props.rfp), [props.rfp]);
  const showSection = showWinningBidSectionSites(props.rfp, facts);

  return trueOrEmpty(<AccentDividerSection
    jumpLink={jl.rfp.winningBid(props.rfp)}
    pages={props.pages}
  >
    <WinningBidInfo
      winningBidCompany={props.rfp.winningBidCompany}
      facts={facts}
      winningBidAdditionalInfo={props.rfp.winningBidAdditionalInfo}
    />
  </AccentDividerSection>)(showSection);
};
