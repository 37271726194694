const amountTBD = "Amount TBD";
export const amountTBDConst = () => amountTBD;

// If you move this update .eslint.strict.js with the new path for import-restricted-path

export const currency: (n: number) => string = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format;
