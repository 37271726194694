import { pipe } from "fp-ts/lib/function";

import { Ord, RA, RNEA, s } from "@scripts/fp-ts";
import type { MediaCategoryU } from "@scripts/generated/domaintables/mediaCategories";
import { allMediaCategory, officialStmts, plom, pos } from "@scripts/generated/domaintables/mediaCategories";
import { reports } from "@scripts/generated/domaintables/mediaCategoryGroups";
import type { SelectOption } from "@scripts/react/components/form/Select";

const mediaCategoryAlphabeticalOrd = Ord.contramap((category: MediaCategoryU) => category.categoryName)(s.Ord);

export const offeringDocumentCategories = [pos, officialStmts, plom];

// The type cast below is because we know the result of the filter will be non empty
// Unfortunately we don't generate a subset of the `allMediaCategory` array so filter is the only way to get the documentCategories
export const baseDocumentCategories =
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  RA.filter((category: MediaCategoryU) => category.categoryGroupId === reports.id)(allMediaCategory) as RNEA.ReadonlyNonEmptyArray<MediaCategoryU>;

export const documentCategorySelectOptions = pipe(
  RNEA.sort(mediaCategoryAlphabeticalOrd)(baseDocumentCategories),
  RA.map((category): SelectOption<number> => ({
    value: category.id,
    label: category.categoryName,
  }))
);
