import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const custom = {
  _tag: `custom`,
  groupName: `Custom`,
  id: 0
} as const;

export type CustomTaggedC = t.TypeC<{
  _tag: t.LiteralC<`custom`>
}>;
export const customTaggedC: CustomTaggedC = t.type({
  _tag: t.literal(`custom`)
});
export type CustomTagged = t.TypeOf<CustomTaggedC>;
export type Custom = CustomTagged & typeof custom;
export type CustomC = t.Type<Custom, CustomTagged>;
export const customC: CustomC = pipe(customTaggedC, c => new t.Type<Custom, CustomTagged>(
  `custom`,
  (u: unknown): u is Custom => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Custom> => pipe(c.decode(u), E.map(x => ({ ...x, ...custom }))),
  (x: Custom): CustomTagged => ({ ...x, _tag: `custom`}),
)) satisfies t.Type<Custom, unknown>;


export const reports = {
  _tag: `reports`,
  groupName: `Reports`,
  id: 1
} as const;

export type ReportsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`reports`>
}>;
export const reportsTaggedC: ReportsTaggedC = t.type({
  _tag: t.literal(`reports`)
});
export type ReportsTagged = t.TypeOf<ReportsTaggedC>;
export type Reports = ReportsTagged & typeof reports;
export type ReportsC = t.Type<Reports, ReportsTagged>;
export const reportsC: ReportsC = pipe(reportsTaggedC, c => new t.Type<Reports, ReportsTagged>(
  `reports`,
  (u: unknown): u is Reports => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Reports> => pipe(c.decode(u), E.map(x => ({ ...x, ...reports }))),
  (x: Reports): ReportsTagged => ({ ...x, _tag: `reports`}),
)) satisfies t.Type<Reports, unknown>;


export const presentations = {
  _tag: `presentations`,
  groupName: `Presentations`,
  id: 2
} as const;

export type PresentationsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`presentations`>
}>;
export const presentationsTaggedC: PresentationsTaggedC = t.type({
  _tag: t.literal(`presentations`)
});
export type PresentationsTagged = t.TypeOf<PresentationsTaggedC>;
export type Presentations = PresentationsTagged & typeof presentations;
export type PresentationsC = t.Type<Presentations, PresentationsTagged>;
export const presentationsC: PresentationsC = pipe(presentationsTaggedC, c => new t.Type<Presentations, PresentationsTagged>(
  `presentations`,
  (u: unknown): u is Presentations => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Presentations> => pipe(c.decode(u), E.map(x => ({ ...x, ...presentations }))),
  (x: Presentations): PresentationsTagged => ({ ...x, _tag: `presentations`}),
)) satisfies t.Type<Presentations, unknown>;


export const statements = {
  _tag: `statements`,
  groupName: `Statements`,
  id: 3
} as const;

export type StatementsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`statements`>
}>;
export const statementsTaggedC: StatementsTaggedC = t.type({
  _tag: t.literal(`statements`)
});
export type StatementsTagged = t.TypeOf<StatementsTaggedC>;
export type Statements = StatementsTagged & typeof statements;
export type StatementsC = t.Type<Statements, StatementsTagged>;
export const statementsC: StatementsC = pipe(statementsTaggedC, c => new t.Type<Statements, StatementsTagged>(
  `statements`,
  (u: unknown): u is Statements => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Statements> => pipe(c.decode(u), E.map(x => ({ ...x, ...statements }))),
  (x: Statements): StatementsTagged => ({ ...x, _tag: `statements`}),
)) satisfies t.Type<Statements, unknown>;


export const images = {
  _tag: `images`,
  groupName: `Images`,
  id: 4
} as const;

export type ImagesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`images`>
}>;
export const imagesTaggedC: ImagesTaggedC = t.type({
  _tag: t.literal(`images`)
});
export type ImagesTagged = t.TypeOf<ImagesTaggedC>;
export type Images = ImagesTagged & typeof images;
export type ImagesC = t.Type<Images, ImagesTagged>;
export const imagesC: ImagesC = pipe(imagesTaggedC, c => new t.Type<Images, ImagesTagged>(
  `images`,
  (u: unknown): u is Images => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Images> => pipe(c.decode(u), E.map(x => ({ ...x, ...images }))),
  (x: Images): ImagesTagged => ({ ...x, _tag: `images`}),
)) satisfies t.Type<Images, unknown>;


export const other = {
  _tag: `other`,
  groupName: `Other`,
  id: 5
} as const;

export type OtherTaggedC = t.TypeC<{
  _tag: t.LiteralC<`other`>
}>;
export const otherTaggedC: OtherTaggedC = t.type({
  _tag: t.literal(`other`)
});
export type OtherTagged = t.TypeOf<OtherTaggedC>;
export type Other = OtherTagged & typeof other;
export type OtherC = t.Type<Other, OtherTagged>;
export const otherC: OtherC = pipe(otherTaggedC, c => new t.Type<Other, OtherTagged>(
  `other`,
  (u: unknown): u is Other => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Other> => pipe(c.decode(u), E.map(x => ({ ...x, ...other }))),
  (x: Other): OtherTagged => ({ ...x, _tag: `other`}),
)) satisfies t.Type<Other, unknown>;


export const pos = {
  _tag: `pos`,
  groupName: `POS`,
  id: 6
} as const;

export type PosTaggedC = t.TypeC<{
  _tag: t.LiteralC<`pos`>
}>;
export const posTaggedC: PosTaggedC = t.type({
  _tag: t.literal(`pos`)
});
export type PosTagged = t.TypeOf<PosTaggedC>;
export type Pos = PosTagged & typeof pos;
export type PosC = t.Type<Pos, PosTagged>;
export const posC: PosC = pipe(posTaggedC, c => new t.Type<Pos, PosTagged>(
  `pos`,
  (u: unknown): u is Pos => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Pos> => pipe(c.decode(u), E.map(x => ({ ...x, ...pos }))),
  (x: Pos): PosTagged => ({ ...x, _tag: `pos`}),
)) satisfies t.Type<Pos, unknown>;


export const audio = {
  _tag: `audio`,
  groupName: `Audio`,
  id: 7
} as const;

export type AudioTaggedC = t.TypeC<{
  _tag: t.LiteralC<`audio`>
}>;
export const audioTaggedC: AudioTaggedC = t.type({
  _tag: t.literal(`audio`)
});
export type AudioTagged = t.TypeOf<AudioTaggedC>;
export type Audio = AudioTagged & typeof audio;
export type AudioC = t.Type<Audio, AudioTagged>;
export const audioC: AudioC = pipe(audioTaggedC, c => new t.Type<Audio, AudioTagged>(
  `audio`,
  (u: unknown): u is Audio => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Audio> => pipe(c.decode(u), E.map(x => ({ ...x, ...audio }))),
  (x: Audio): AudioTagged => ({ ...x, _tag: `audio`}),
)) satisfies t.Type<Audio, unknown>;


export const irmaLetter = {
  _tag: `irmaLetter`,
  groupName: `IRMA Letter`,
  id: 8
} as const;

export type IrmaLetterTaggedC = t.TypeC<{
  _tag: t.LiteralC<`irmaLetter`>
}>;
export const irmaLetterTaggedC: IrmaLetterTaggedC = t.type({
  _tag: t.literal(`irmaLetter`)
});
export type IrmaLetterTagged = t.TypeOf<IrmaLetterTaggedC>;
export type IrmaLetter = IrmaLetterTagged & typeof irmaLetter;
export type IrmaLetterC = t.Type<IrmaLetter, IrmaLetterTagged>;
export const irmaLetterC: IrmaLetterC = pipe(irmaLetterTaggedC, c => new t.Type<IrmaLetter, IrmaLetterTagged>(
  `irmaLetter`,
  (u: unknown): u is IrmaLetter => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IrmaLetter> => pipe(c.decode(u), E.map(x => ({ ...x, ...irmaLetter }))),
  (x: IrmaLetter): IrmaLetterTagged => ({ ...x, _tag: `irmaLetter`}),
)) satisfies t.Type<IrmaLetter, unknown>;


export const allMediaCategoryGroupC = [customC, reportsC, presentationsC, statementsC, imagesC, otherC, posC, audioC, irmaLetterC] as const;
export const allMediaCategoryGroupNames = [`custom`, `reports`, `presentations`, `statements`, `images`, `other`, `pos`, `audio`, `irmaLetter`] as const;
export type MediaCategoryGroupName = (typeof allMediaCategoryGroupNames)[number];

export type MediaCategoryGroupCU = t.UnionC<[CustomC, ReportsC, PresentationsC, StatementsC, ImagesC, OtherC, PosC, AudioC, IrmaLetterC]>;
export type MediaCategoryGroupU = Custom | Reports | Presentations | Statements | Images | Other | Pos | Audio | IrmaLetter;
export const MediaCategoryGroupCU: MediaCategoryGroupCU = t.union([customC, reportsC, presentationsC, statementsC, imagesC, otherC, posC, audioC, irmaLetterC]) satisfies t.Type<MediaCategoryGroupU, unknown>;

export const mediaCategoryGroupOrd: Ord.Ord<MediaCategoryGroupU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allMediaCategoryGroup = [custom, reports, presentations, statements, images, other, pos, audio, irmaLetter] as const;
export type MediaCategoryGroupMap<A> = { [K in MediaCategoryGroupName]: A };


