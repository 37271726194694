import { pipe } from "fp-ts/lib/function";
import type * as t from "io-ts";

import { O, RA, RNEA } from "@scripts/fp-ts";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { BondsSsrData } from "@scripts/generated/models/bondsSsrData";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import type {
  RfpsSitesList,
  RfpsSitesListC,
} from "@scripts/generated/models/rfp";
import { AnchorButton } from "@scripts/react/components/Anchor";
import { ButtonsContainer } from "@scripts/react/components/Button";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { ParticipantRows, ParticipantsSectionContainer } from "@scripts/react/components/offering-pages/OfferingParticipants";
import { RoadshowsSection } from "@scripts/react/components/offering-pages/RelatedContent";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import {
  bonds,
  type IssuerSitesRouteMeta,
  rfps,
} from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { urlInterface } from "@scripts/routes/urlInterface";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";

import { CustomPageSection } from "../../components/CustomPageSection";
import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { GeneralOfferingInfo } from "../../components/offering-pages/GeneralOfferingInfo";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";
import { BondSales } from "./BondSales";
import { ProgramsSection } from "./ProgramsSection";
import { RatingsSection } from "./RatingsSection";

const setupSidebarLinkSections = (
  ffs: ClientFeatureFlags,
  pages: ReadonlyArray<PageConfig<PageU>>,
  customPages: BondsSsrData["customPages"],
): ReadonlyArray<JumpLink> =>
  makeSitesJumpLinks(ffs, pages)(jl.bonds.allStatic.concat(customPages.map(jl.customPage)));

const RfpsSection = (props: {
  rfpsPage: IssuerSitesRouteMeta<RfpsSitesList, t.OutputOf<RfpsSitesListC>, "rfps">;
}) => {
  const pages = useIssuerSitesSelector("pages");
  const rfpsTitle = jl.bonds.rfps.text(pages);

  return <AccentDividerSection jumpLink={jl.bonds.rfps} pages={pages}>
    Get details and submission information about {rfpsTitle}.
    <ButtonsContainer klasses={[]}>
      <AnchorButton
        externalLinkLocation="none"
        route={{
          title: `View ${rfpsTitle}`,
          route: urlInterface("GET", props.rfpsPage.url()),
        }}
        target="_self"
        variant="primary"
      />
    </ButtonsContainer>
  </AccentDividerSection>;
};

export const Bonds = (props: BondsSsrData) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");

  const bondsRouteMeta = bonds({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const bondsDescription = bondsRouteMeta.description(pages, iffs, issuer);

  const rfpsRouteMeta = rfps({ issuerId: issuer.id, issuerSlug: issuer.slug });

  const activeBonds = pipe(
    props.offerings,
    RA.filter(offering => !offering.data.data.record.data.offering.isArchived)
  );
  const sidebarLinks = setupSidebarLinkSections(iffs, pages, props.customPages);

  return (
    <DirectSitesPageDescriptionLayout
      description={bondsDescription}
      sidebarContent={
        <>
          <SidebarLinksSites
            headerLinkAnchorContent={bondsRouteMeta.title()}
            jumpLinks={sidebarLinks}
            routeMeta={bondsRouteMeta}
          />
          <GetAlertsActionSection containerKlass={"d-none-until-md"} />
        </>
      }
    >
      {jl.bonds.bondSales.enabled(iffs) && (
        <BondSales
          activeBonds={activeBonds}
          isBondArchiveEnabled={jl.bonds.bondArchive.enabled(iffs)}
          jumpLink={jl.bonds.bondSales}
        />
      )}
      {jl.bonds.rfps.enabled(iffs) && <RfpsSection rfpsPage={rfpsRouteMeta} />}
      <RoadshowsSection
        issuer={issuer}
        pages={pages}
        roadshows={RNEA.fromReadonlyArray(props.roadshows)}
        sidebarLinkHandle={jl.bonds.roadshows.sectionId}
        showEmptySection={jl.bonds.roadshows.enabled(iffs)}
        leafIcon={IssuerSitesLeafIcon}
      />
      {jl.bonds.ratings.enabled(iffs) && (
        <RatingsSection
          issuer={issuer}
          issuerRatings={props.issuerRatings}
          programRatings={props.programRatings}
        />
      )}
      {jl.bonds.programs.enabled(iffs) && <ProgramsSection programs={props.programs} />}
      {jl.bonds.participants.enabled(iffs) && (
        <ParticipantsSectionContainer
          sidebarLinkHandle={jl.bonds.participants.sectionId}
          title={jl.bonds.participants.text(pages)}
        >
          {pipe(
            RNEA.fromReadonlyArray(props.participants),
            O.fold(
              () => <p className="mt-1">There are currently no participants listed.</p>,
              ps => <ParticipantRows participants={ps} />,
            )
          )}
        </ParticipantsSectionContainer>
      )}
      {jl.bonds.generalOfferingInfo.enabled(iffs) && pipe(
        props.generalInfo,
        mapOrEmpty(generalInfo => <GeneralOfferingInfo offeringInfo={generalInfo} />)
      )}
      {pipe(
        props.customPages,
        RA.map(customPage =>
          <CustomPageSection
            customPage={customPage}
            key={customPage.page.name}
          />
        )
      )}
    </DirectSitesPageDescriptionLayout>
  );
};
