import { E } from "@scripts/fp-ts";
import * as f from "@scripts/generated/domaintables/featureFlags";
import * as p from "@scripts/generated/domaintables/pages";
import { rfpsMeta } from "@scripts/meta/dataMeta";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import { getCustomTitleOrName } from "@scripts/syntax/pageTitles";

export const activeRfps = SitesJumpLink(`Active ${rfpsMeta.type}`, [E.right(p.rfpsPage)], {
  overrideText: pages => `Active ${getCustomTitleOrName(p.rfpsPage)(pages)}`,
});

export const archivedRfps = SitesJumpLink(`Archived ${rfpsMeta.type}`, [E.left(f.archivedRfps)], {
  overrideText: pages => `Archived ${getCustomTitleOrName(p.rfpsPage)(pages)}`,
});

export const allStatic: ReadonlyArray<SitesJumpLink> = [activeRfps, archivedRfps];
