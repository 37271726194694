import { useState } from "react";
import { Lens } from "monocle-ts";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { E, O, pipe, RA } from "@scripts/fp-ts";
import { emmaLinks } from "@scripts/generated/domaintables/pages";
import { Cusip9SortColumnCU, type Cusip9SortColumnU } from "@scripts/generated/domaintables/sortColumns";
import type { Cusip9, EmmaLinksPageData } from "@scripts/generated/models/cusip";
import type { DateQualifier } from "@scripts/generated/models/dateQualifier";
import type { PageSort } from "@scripts/generated/models/pageSorts";
import type { WithId } from "@scripts/generated/models/threadThrough";
import { emmaName } from "@scripts/literals/emma";
import { AnchorUnsafe } from "@scripts/react/components/Anchor";
import { emptyCellConst } from "@scripts/react/components/table/cells/NoDataCell";
import type { TableColumnRow, TableSortColumnName } from "@scripts/react/components/table/tableSyntax";
import { useConfig } from "@scripts/react/context/Config";
import type { SetState } from "@scripts/react/syntax/react";
import { dateQualifierOrd, qualifierFormat } from "@scripts/syntax/date/dateQualifier";
import { getPageSort } from "@scripts/syntax/pageSorts";
import { caseInsensitiveOrd } from "@scripts/util/string";

import { IssuerSitesTable, IssuerSitesTableColumnHeader, type IssuerSitesTableSortProps } from "../../components/IssuerSitesTable";
import { IssuerSitesSearchInputGrid } from "../../components/SearchInput";
import { useIssuerSitesSelector } from "../../state/store";

type Cusip9RowModel = {
  issueDate: O.Option<DateQualifier>;
  datedDate: O.Option<DateQualifier>;
  maturityDate: O.Option<DateQualifier>;
  bondName: string;
  cusip: string;
};

type Cusip9TableModel = TableColumnRow<Cusip9RowModel, Record<string, never>, ["view"]>;

type Cusip9SortColumnTypes = TableSortColumnName<keyof Cusip9RowModel>;

const cusip9DataMapFn = (cusip9: WithId<Cusip9>): Cusip9TableModel["Row"] => ({
  __klass: O.none,
  __metadata: {},
  __rowId: cusip9.id,
  issueDate: cusip9.record.issueDate,
  datedDate: cusip9.record.datedDate,
  maturityDate: cusip9.record.maturityDate,
  bondName: cusip9.record.bondName,
  cusip: cusip9.record.cusip,
});

type Cusip9SortProps = IssuerSitesTableSortProps<keyof Cusip9RowModel>;

type ShowDates = { issue: boolean, dated: boolean, maturity: boolean };

const computeShowDates = (cusip9s: EmmaLinksPageData["cusip9s"]) => {
  const res: ShowDates = { issue: false, dated: false, maturity: false };
  for (const c of cusip9s) {
    res.issue = res.issue || O.isSome(c.record.issueDate);
    res.dated = res.dated || O.isSome(c.record.datedDate);
    res.maturity = res.maturity || O.isSome(c.record.maturityDate);
    // If every value is true we can short circuit
    if (Object.values(res).every(b => b)) {
      return res;
    }
  }
  return res;
};

const columns = (config: BLConfigWithLog, showDates: ShowDates, props: Cusip9SortProps): Cusip9TableModel["Columns"] => {
  const nowrap = "white-space-nowrap";
  const dateKlass = (base: O.Option<string>) => (show: boolean) =>
    RA.compact([base, O.fromPredicate(() => !show)("d-none")]).join(" ");
  const dateKlassWrap = dateKlass(O.none);
  const dateKlassNowrap = dateKlass(O.some(nowrap));
  return {
    issueDate: {
      title: "Issue Date",
      dataCellKlass: dateKlassNowrap(showDates.issue),
      headerCellKlass: dateKlassWrap(showDates.issue),
      headerComponent: () => <IssuerSitesTableColumnHeader {...props} colName="issueDate" colText="Issue Date" />,
      dataCellComponent: r => pipe(r.issueDate, O.fold(emptyCellConst, qualifierFormat(config))),
      sort: { direction: "both", ord: O.getOrd(dateQualifierOrd(config)) },
    },
    datedDate: {
      title: "Dated Date",
      dataCellKlass: dateKlassNowrap(showDates.dated),
      headerCellKlass: dateKlassWrap(showDates.dated),
      headerComponent: () => <IssuerSitesTableColumnHeader {...props} colName="datedDate" colText="Dated Date" />,
      dataCellComponent: r => pipe(r.datedDate, O.fold(emptyCellConst, qualifierFormat(config))),
      sort: { direction: "both", ord: O.getOrd(dateQualifierOrd(config)) },
    },
    maturityDate: {
      title: "Maturity Date",
      dataCellKlass: dateKlassNowrap(showDates.maturity),
      headerCellKlass: dateKlassWrap(showDates.maturity),
      headerComponent: () => <IssuerSitesTableColumnHeader {...props} colName="maturityDate" colText="Maturity Date" />,
      dataCellComponent: r => pipe(r.maturityDate, O.fold(emptyCellConst, qualifierFormat(config))),
      sort: { direction: "both", ord: O.getOrd(dateQualifierOrd(config)) },
    },
    bondName: {
      title: "Name",
      dataCellKlass: O.none,
      headerComponent: () => <IssuerSitesTableColumnHeader {...props} colName="bondName" colText="Name" />,
      sort: { direction: "both", ord: caseInsensitiveOrd },
    },
    cusip: {
      title: "CUSIP-9",
      dataCellKlass: nowrap,
      headerComponent: () => <IssuerSitesTableColumnHeader {...props} colName="cusip" colText="CUSIP-9" />,
      sort: { direction: "both", ord: caseInsensitiveOrd },
    },
    view: {
      title: "View",
      dataCellKlass: nowrap,
      headerComponent: "empty",
      dataCellComponent: r => <AnchorUnsafe
        target="_blank"
        externalLinkLocation="none"
        arrowType="right"
        title={`View on ${emmaName}`}
        // eslint-disable-next-line no-restricted-syntax
        href={`https://emma.msrb.org/Security/Details/${r.cusip}?tab=tabTradeActivity`}
      />,
      excludeFromSearch: true,
    },
  };
};

type Cusip9FilterState = {
  sortBy: Cusip9SortColumnTypes;
  search: O.Option<string>;
  page: O.Option<number>;
};

const initialCusip9FilterState = (
  config: BLConfigWithLog,
  pageSort: PageSort,
  searchedCusip9: EmmaLinksPageData["searchedCusip9"],
): Cusip9FilterState => ({
  sortBy: pipe(
    O.fromPredicate(Cusip9SortColumnCU.is)(pageSort.column),
    O.fold<Cusip9SortColumnU, Cusip9SortColumnTypes>(
      (): Cusip9SortColumnTypes => "bondName-asc",
      c => {
        switch (c._tag) {
          case "Cusip9BondName": return `bondName-${pageSort.direction.dir}`;
          case "Cusip9Cusip": return `cusip-${pageSort.direction.dir}`;
          case "Cusip9DatedDate": return `datedDate-${pageSort.direction.dir}`;
          case "Cusip9IssueDate": return `issueDate-${pageSort.direction.dir}`;
          case "Cusip9MaturityDate": return `maturityDate-${pageSort.direction.dir}`;
          default: return config.exhaustive(c);
        }
      }
    )
  ),
  search: searchedCusip9,
  page: O.some(1),
});

const cusip9FiltersLens = Lens.fromProp<Cusip9FilterState>();

const setFilterState = (state: Cusip9FilterState, setState: SetState<Cusip9FilterState>) =>
  <K extends keyof Cusip9FilterState>(key: K) =>
    (value: Cusip9FilterState[K]) => setState(cusip9FiltersLens(key).set(value)(state));

export const Cusip9Table = (props: {
  cusip9s: EmmaLinksPageData["cusip9s"];
  searchedCusip9: EmmaLinksPageData["searchedCusip9"];
}) => {
  const config = useConfig();
  const pages = useIssuerSitesSelector("pages");
  const [filters, setFilters] = useState(initialCusip9FilterState(config, getPageSort(emmaLinks)(pages), props.searchedCusip9));
  const setFilter = setFilterState(filters, setFilters);
  const getFilter = <K extends keyof Cusip9FilterState>(key: K) => cusip9FiltersLens(key).get(filters);

  const sortBy = getFilter("sortBy");
  const search = getFilter("search");
  const page = getFilter("page");

  const showDates = computeShowDates(props.cusip9s);

  return <>
    <IssuerSitesSearchInputGrid
      klass="bt-0125 pt-1 pt-md-15"
      ariaLabelledById="cusip-search"
      placeholder={O.some("Search")}
      labelOrAriaLabel={E.right("CUSIP Search")}
      search={search}
      setSearch={setFilter("search")}
    />
    <IssuerSitesTable
      data={props.cusip9s.map(cusip9DataMapFn)}
      detailCell={O.none}
      columns={columns(config, showDates, { sortBy, setSortBy: setFilter("sortBy") })}
      exporter={O.none}
      sortable={O.some(sortBy)}
      searchable={pipe(search, O.map(_ => [O.none, _]))}
      paginate={page}
      onParamsChanged={({ page: p }) => setFilter("page")(O.fromNullable(p))}
      tableAction={O.none}
      hideActionsPanel
      variant="issuersites"
      isLgTable
    />
  </>;
};
