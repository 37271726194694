import type { PageLink } from "@scripts/codecs/linkable";
import { O, pipe, RA } from "@scripts/fp-ts";
import type { PhotoEditableU } from "@scripts/generated/domaintables/pages";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { ImageBackground } from "@scripts/react/components/Image";
import { makePageHeaderSideBarLinkHandle } from "@scripts/syntax/sidebarLinks";

import type { IssuerSitesPageMeta } from "../router";
import { useIssuerSitesSelector } from "../state/store";

type MastheadProps = {
  title: string;
  photoPage: PhotoEditableU;
  routeMeta: Pick<IssuerSitesPageMeta, "_tag" | "isErrorPage">;
};

export const Masthead = (props: MastheadProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  const headerPhotos = useIssuerSitesSelector("headerPhotos");
  const navLinks = useIssuerSitesSelector("navLinks");
  const isBlpIssuer = O.isSome(issuer.bankId);
  const domainPageName = props.photoPage.name;
  const imageUrlO = pipe(
    headerPhotos,
    RA.findFirstMap(([page, photo]) => pipe(
      photo.data.record.media.data.record.uploadResponse.uri,
      O.fromPredicate(() => page.name === domainPageName)
    )),
  );

  const parentPageO: O.Option<PageLink> = pipe(
    navLinks,
    RA.findFirstMap(([mainLink, , subLinks]): O.Option<PageLink> => {
      return !mainLink.enabled || props.routeMeta.isErrorPage
        ? O.none
        : mainLink.displayName === domainPageName
          ? O.some(mainLink)
          : pipe(
            subLinks,
            RA.findFirstMap((subLink) => {
              return subLink._tag !== "PageLink"
                ? O.none
                : subLink.displayName === domainPageName
                  ? O.some(subLink)
                  : O.none;
            }
            )
          );
    }),
  );

  return (
    <div id={makePageHeaderSideBarLinkHandle(props.routeMeta)}>
      <ImageBackground
        klasses={["masthead", "inverted", isBlpIssuer ? "bank-masthead" : "issuer-masthead"]}
        url={imageUrlO}
      >
        <div className="page-name-container">
          <div className="page-name" itemScope itemType="https://schema.org/BreadcrumbList">
            <span itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem">
              <span itemScope itemProp="item" itemType="https://schema.org/WebPage" itemID={SitesRouter.issuersitesIssuerControllerIndex({ issuerSlug: issuer.slug, issuerId: issuer.id }).url}>
                <meta itemProp="name" content={issuer.name} />
              </span>
              <meta itemProp="position" content="1" />
            </span>
            {pipe(
              parentPageO,
              O.filter((p) => !isBlpIssuer && p.displayName !== props.title),
              mapOrEmpty((parentPage) =>
                <span itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem">
                  <a itemScope href={parentPage.url} itemID={parentPage.url} className="breadcrumb" itemProp="item" itemType="https://schema.org/WebPage">{parentPage.displayName}</a>
                  <meta itemProp="name" content={parentPage.displayName} />
                  <meta itemProp="position" content="2" />
                </span>
              ))}
            <span />
            <h1>{props.title}</h1>
          </div>
        </div>
      </ImageBackground>
    </div>
  );
};
