// This file was generated by postcss-transpiler. Do not edit by hand.

export const portalTable = {
  ".portal-table-sites": {
    css: ".portal-table-sites",
    html: "portal-table-sites",
    "table": {
      css: "table",
      html: "table",
      "colgroup": {
        css: "colgroup",
        html: "colgroup"
      }
    }
  },
  ".table-lg-breakpoint": {
    css: ".table-lg-breakpoint",
    html: "table-lg-breakpoint",
    "table": {
      css: "table",
      html: "table",
      ".portal-table-container": {
        css: ".portal-table-container",
        html: "portal-table-container",
        attrs: {
          ".headerless": {
            css: ".headerless",
            html: "headerless"
          }
        },
        "tr": {
          css: "tr",
          html: "tr",
          ".cell-control": {
            css: ".cell-control",
            html: "cell-control",
            "td": {
              css: "td",
              html: "td"
            }
          }
        }
      },
      "body": {
        css: "body",
        html: "body",
        "tr": {
          css: "tr",
          html: "tr",
          ".table-md-breakpoint": {
            css: ".table-md-breakpoint",
            html: "table-md-breakpoint",
            ".portal-table-cell": {
              css: ".portal-table-cell",
              html: "portal-table-cell",
              attrs: {
                ".cell-control": {
                  css: ".cell-control",
                  html: "cell-control"
                },
                ".cell-primary": {
                  css: ".cell-primary",
                  html: "cell-primary"
                }
              }
            }
          },
          ".table-lg-breakpoint": {
            css: ".table-lg-breakpoint",
            html: "table-lg-breakpoint",
            ".portal-table-cell": {
              css: ".portal-table-cell",
              html: "portal-table-cell",
              attrs: {
                ".cell-control": {
                  css: ".cell-control",
                  html: "cell-control"
                },
                ".cell-primary": {
                  css: ".cell-primary",
                  html: "cell-primary"
                }
              }
            }
          }
        }
      },
      ".table-md-breakpoint": {
        css: ".table-md-breakpoint",
        html: "table-md-breakpoint",
        "tr": {
          css: "tr",
          html: "tr",
          attrs: {
            ".row-inactive": {
              css: ".row-inactive",
              html: "row-inactive"
            },
            ".row-input": {
              css: ".row-input",
              html: "row-input"
            },
            ".disabled": {
              css: ".disabled",
              html: "disabled"
            },
            ".on-req": {
              css: ".on-req",
              html: "on-req"
            },
            ".matured": {
              css: ".matured",
              html: "matured"
            },
            ".muted": {
              css: ".muted",
              html: "muted"
            },
            ".draft": {
              css: ".draft",
              html: "draft"
            },
            ".rejected": {
              css: ".rejected",
              html: "rejected"
            },
            ".pending": {
              css: ".pending",
              html: "pending"
            },
            ".row-expanded": {
              css: ".row-expanded",
              html: "row-expanded"
            },
            ".row-error": {
              css: ".row-error",
              html: "row-error"
            },
            ".row-expanded-content": {
              css: ".row-expanded-content",
              html: "row-expanded-content"
            },
            ".row-selected": {
              css: ".row-selected",
              html: "row-selected"
            }
          },
          ".cell-primary": {
            css: ".cell-primary",
            html: "cell-primary"
          },
          "a": {
            css: "a",
            html: "a",
            attrs: {
              ".count": {
                css: ".count",
                html: "count"
              }
            }
          },
          ".portal-table-cell": {
            css: ".portal-table-cell",
            html: "portal-table-cell",
            "button": {
              css: "button",
              html: "button",
              attrs: {
                ".disabled": {
                  css: ".disabled",
                  html: "disabled"
                },
                ".icon": {
                  css: ".icon",
                  html: "icon"
                }
              }
            }
          },
          "td": {
            css: "td",
            html: "td",
            attrs: {
              ".error-detail-cell": {
                css: ".error-detail-cell",
                html: "error-detail-cell"
              },
              ".detail-cell": {
                css: ".detail-cell",
                html: "detail-cell"
              },
              ".row-safe-nowrap": {
                css: ".row-safe-nowrap",
                html: "row-safe-nowrap"
              },
              ".dataTables-empty": {
                css: ".dataTables-empty",
                html: "dataTables-empty"
              },
              ".cell-no-data": {
                css: ".cell-no-data",
                html: "cell-no-data"
              },
              ".cell-actions": {
                css: ".cell-actions",
                html: "cell-actions"
              },
              ".cell-control": {
                css: ".cell-control",
                html: "cell-control"
              },
              ".detail-action-column-d": {
                css: ".detail-action-column-d",
                html: "detail-action-column-d"
              },
              ".detail-action-column-a": {
                css: ".detail-action-column-a",
                html: "detail-action-column-a"
              },
              ".cell-select": {
                css: ".cell-select",
                html: "cell-select"
              },
              ".cell-image": {
                css: ".cell-image",
                html: "cell-image"
              }
            },
            "p": {
              css: "p",
              html: "p",
              attrs: {
                ".error-message": {
                  css: ".error-message",
                  html: "error-message"
                }
              }
            },
            ".content": {
              css: ".content",
              html: "content",
              "h4": {
                css: "h4",
                html: "h4"
              },
              ".separator": {
                css: ".separator",
                html: "separator"
              }
            },
            ":before": {
              css: ":before",
              html: "before"
            },
            ".action-icons": {
              css: ".action-icons",
              html: "action-icons"
            },
            ".bl-react-select-wrapper": {
              css: ".bl-react-select-wrapper",
              html: "bl-react-select-wrapper"
            },
            ".bl-react-select__control": {
              css: ".bl-react-select__control",
              html: "bl-react-select__control"
            },
            ".bl-react-select__control--is-focused": {
              css: ".bl-react-select__control--is-focused",
              html: "bl-react-select__control--is-focused"
            },
            ".bl-react-select__indicator": {
              css: ".bl-react-select__indicator",
              html: "bl-react-select__indicator"
            },
            ".bl-react-select__indicators": {
              css: ".bl-react-select__indicators",
              html: "bl-react-select__indicators"
            },
            ".bl-react-select__value-container": {
              css: ".bl-react-select__value-container",
              html: "bl-react-select__value-container"
            },
            ".bl-react-select__single-value": {
              css: ".bl-react-select__single-value",
              html: "bl-react-select__single-value"
            },
            ".caption-text": {
              css: ".caption-text",
              html: "caption-text"
            },
            ".caption-title": {
              css: ".caption-title",
              html: "caption-title"
            },
            ".cell-caption": {
              css: ".cell-caption",
              html: "cell-caption"
            }
          },
          ".content": {
            css: ".content",
            html: "content",
            "h4": {
              css: "h4",
              html: "h4"
            }
          },
          "img": {
            css: "img",
            html: "img"
          },
          ".cell-control": {
            css: ".cell-control",
            html: "cell-control",
            ".custom-indicator": {
              css: ".custom-indicator",
              html: "custom-indicator"
            },
            "td": {
              css: "td",
              html: "td"
            }
          },
          ".caption-text": {
            css: ".caption-text",
            html: "caption-text"
          },
          ".cell-actions": {
            css: ".cell-actions",
            html: "cell-actions",
            ".label": {
              css: ".label",
              html: "label"
            }
          },
          "th": {
            css: "th",
            html: "th",
            attrs: {
              ".cell-no-data": {
                css: ".cell-no-data",
                html: "cell-no-data"
              },
              ".cell-actions": {
                css: ".cell-actions",
                html: "cell-actions"
              },
              ".cell-control": {
                css: ".cell-control",
                html: "cell-control"
              },
              ".detail-action-column-d": {
                css: ".detail-action-column-d",
                html: "detail-action-column-d"
              },
              ".detail-action-column-a": {
                css: ".detail-action-column-a",
                html: "detail-action-column-a"
              },
              ".cell-select": {
                css: ".cell-select",
                html: "cell-select"
              },
              ".cell-image": {
                css: ".cell-image",
                html: "cell-image"
              }
            },
            ".bl-react-select-wrapper": {
              css: ".bl-react-select-wrapper",
              html: "bl-react-select-wrapper"
            },
            ".bl-react-select__control": {
              css: ".bl-react-select__control",
              html: "bl-react-select__control"
            },
            ".bl-react-select__control--is-focused": {
              css: ".bl-react-select__control--is-focused",
              html: "bl-react-select__control--is-focused"
            },
            ".bl-react-select__indicator": {
              css: ".bl-react-select__indicator",
              html: "bl-react-select__indicator"
            },
            ".bl-react-select__indicators": {
              css: ".bl-react-select__indicators",
              html: "bl-react-select__indicators"
            },
            ".bl-react-select__value-container": {
              css: ".bl-react-select__value-container",
              html: "bl-react-select__value-container"
            },
            ".bl-react-select__single-value": {
              css: ".bl-react-select__single-value",
              html: "bl-react-select__single-value"
            },
            ".caption-text": {
              css: ".caption-text",
              html: "caption-text"
            },
            ".caption-title": {
              css: ".caption-title",
              html: "caption-title"
            },
            ".cell-caption": {
              css: ".cell-caption",
              html: "cell-caption"
            }
          }
        }
      },
      "colgroup": {
        css: "colgroup",
        html: "colgroup"
      },
      "thead": {
        css: "thead",
        html: "thead",
        "th": {
          css: "th",
          html: "th",
          ".header-tooltip": {
            css: ".header-tooltip",
            html: "header-tooltip",
            "svg": {
              css: "svg",
              html: "svg"
            }
          }
        },
        "td": {
          css: "td",
          html: "td"
        }
      },
      "tbody": {
        css: "tbody",
        html: "tbody",
        "tr": {
          css: "tr",
          html: "tr"
        },
        "td": {
          css: "td",
          html: "td"
        },
        "th": {
          css: "th",
          html: "th"
        }
      },
      "td": {
        css: "td",
        html: "td"
      },
      "th": {
        css: "th",
        html: "th",
        attrs: {
          ".asc": {
            css: ".asc",
            html: "asc"
          },
          ".desc": {
            css: ".desc",
            html: "desc"
          }
        },
        "a": {
          css: "a",
          html: "a"
        }
      },
      "tr": {
        css: "tr",
        html: "tr",
        attrs: {
          ".row-inactive": {
            css: ".row-inactive",
            html: "row-inactive"
          },
          ".row-input": {
            css: ".row-input",
            html: "row-input"
          },
          ".disabled": {
            css: ".disabled",
            html: "disabled"
          },
          ".on-req": {
            css: ".on-req",
            html: "on-req"
          },
          ".matured": {
            css: ".matured",
            html: "matured"
          },
          ".muted": {
            css: ".muted",
            html: "muted"
          },
          ".draft": {
            css: ".draft",
            html: "draft"
          },
          ".rejected": {
            css: ".rejected",
            html: "rejected"
          },
          ".pending": {
            css: ".pending",
            html: "pending"
          },
          ".row-expanded": {
            css: ".row-expanded",
            html: "row-expanded"
          },
          ".row-error": {
            css: ".row-error",
            html: "row-error"
          },
          ".row-expanded-content": {
            css: ".row-expanded-content",
            html: "row-expanded-content"
          },
          ".row-selected": {
            css: ".row-selected",
            html: "row-selected"
          }
        },
        ".cell-primary": {
          css: ".cell-primary",
          html: "cell-primary"
        },
        "a": {
          css: "a",
          html: "a",
          attrs: {
            ".count": {
              css: ".count",
              html: "count"
            }
          }
        },
        ".portal-table-cell": {
          css: ".portal-table-cell",
          html: "portal-table-cell",
          "button": {
            css: "button",
            html: "button",
            attrs: {
              ".disabled": {
                css: ".disabled",
                html: "disabled"
              },
              ".icon": {
                css: ".icon",
                html: "icon"
              }
            }
          }
        },
        "td": {
          css: "td",
          html: "td",
          attrs: {
            ".error-detail-cell": {
              css: ".error-detail-cell",
              html: "error-detail-cell"
            },
            ".detail-cell": {
              css: ".detail-cell",
              html: "detail-cell"
            },
            ".row-safe-nowrap": {
              css: ".row-safe-nowrap",
              html: "row-safe-nowrap"
            },
            ".dataTables-empty": {
              css: ".dataTables-empty",
              html: "dataTables-empty"
            },
            ".cell-no-data": {
              css: ".cell-no-data",
              html: "cell-no-data"
            },
            ".cell-actions": {
              css: ".cell-actions",
              html: "cell-actions"
            },
            ".cell-control": {
              css: ".cell-control",
              html: "cell-control"
            },
            ".detail-action-column-d": {
              css: ".detail-action-column-d",
              html: "detail-action-column-d"
            },
            ".detail-action-column-a": {
              css: ".detail-action-column-a",
              html: "detail-action-column-a"
            },
            ".cell-select": {
              css: ".cell-select",
              html: "cell-select"
            },
            ".cell-image": {
              css: ".cell-image",
              html: "cell-image"
            }
          },
          "p": {
            css: "p",
            html: "p",
            attrs: {
              ".error-message": {
                css: ".error-message",
                html: "error-message"
              }
            }
          },
          ".content": {
            css: ".content",
            html: "content",
            "h4": {
              css: "h4",
              html: "h4"
            },
            ".separator": {
              css: ".separator",
              html: "separator"
            }
          },
          ":before": {
            css: ":before",
            html: "before"
          },
          ".action-icons": {
            css: ".action-icons",
            html: "action-icons"
          },
          ".bl-react-select-wrapper": {
            css: ".bl-react-select-wrapper",
            html: "bl-react-select-wrapper"
          },
          ".bl-react-select__control": {
            css: ".bl-react-select__control",
            html: "bl-react-select__control"
          },
          ".bl-react-select__control--is-focused": {
            css: ".bl-react-select__control--is-focused",
            html: "bl-react-select__control--is-focused"
          },
          ".bl-react-select__indicator": {
            css: ".bl-react-select__indicator",
            html: "bl-react-select__indicator"
          },
          ".bl-react-select__indicators": {
            css: ".bl-react-select__indicators",
            html: "bl-react-select__indicators"
          },
          ".bl-react-select__value-container": {
            css: ".bl-react-select__value-container",
            html: "bl-react-select__value-container"
          },
          ".bl-react-select__single-value": {
            css: ".bl-react-select__single-value",
            html: "bl-react-select__single-value"
          },
          ".caption-text": {
            css: ".caption-text",
            html: "caption-text"
          },
          ".caption-title": {
            css: ".caption-title",
            html: "caption-title"
          },
          ".cell-caption": {
            css: ".cell-caption",
            html: "cell-caption"
          }
        },
        ".content": {
          css: ".content",
          html: "content",
          "h4": {
            css: "h4",
            html: "h4"
          }
        },
        "img": {
          css: "img",
          html: "img"
        },
        ".cell-control": {
          css: ".cell-control",
          html: "cell-control",
          ".custom-indicator": {
            css: ".custom-indicator",
            html: "custom-indicator"
          },
          "td": {
            css: "td",
            html: "td"
          }
        },
        ".caption-text": {
          css: ".caption-text",
          html: "caption-text"
        },
        ".cell-actions": {
          css: ".cell-actions",
          html: "cell-actions",
          ".label": {
            css: ".label",
            html: "label"
          }
        },
        "th": {
          css: "th",
          html: "th",
          attrs: {
            ".cell-no-data": {
              css: ".cell-no-data",
              html: "cell-no-data"
            },
            ".cell-actions": {
              css: ".cell-actions",
              html: "cell-actions"
            },
            ".cell-control": {
              css: ".cell-control",
              html: "cell-control"
            },
            ".detail-action-column-d": {
              css: ".detail-action-column-d",
              html: "detail-action-column-d"
            },
            ".detail-action-column-a": {
              css: ".detail-action-column-a",
              html: "detail-action-column-a"
            },
            ".cell-select": {
              css: ".cell-select",
              html: "cell-select"
            },
            ".cell-image": {
              css: ".cell-image",
              html: "cell-image"
            }
          },
          ".bl-react-select-wrapper": {
            css: ".bl-react-select-wrapper",
            html: "bl-react-select-wrapper"
          },
          ".bl-react-select__control": {
            css: ".bl-react-select__control",
            html: "bl-react-select__control"
          },
          ".bl-react-select__control--is-focused": {
            css: ".bl-react-select__control--is-focused",
            html: "bl-react-select__control--is-focused"
          },
          ".bl-react-select__indicator": {
            css: ".bl-react-select__indicator",
            html: "bl-react-select__indicator"
          },
          ".bl-react-select__indicators": {
            css: ".bl-react-select__indicators",
            html: "bl-react-select__indicators"
          },
          ".bl-react-select__value-container": {
            css: ".bl-react-select__value-container",
            html: "bl-react-select__value-container"
          },
          ".bl-react-select__single-value": {
            css: ".bl-react-select__single-value",
            html: "bl-react-select__single-value"
          },
          ".caption-text": {
            css: ".caption-text",
            html: "caption-text"
          },
          ".caption-title": {
            css: ".caption-title",
            html: "caption-title"
          },
          ".cell-caption": {
            css: ".cell-caption",
            html: "cell-caption"
          }
        }
      },
      "table": {
        css: "table",
        html: "table",
        attrs: {
          ".table-simple": {
            css: ".table-simple",
            html: "table-simple"
          }
        },
        "tbody": {
          css: "tbody",
          html: "tbody",
          "tr": {
            css: "tr",
            html: "tr",
            "td": {
              css: "td",
              html: "td"
            }
          }
        },
        "td": {
          css: "td",
          html: "td"
        },
        "th": {
          css: "th",
          html: "th"
        },
        "thead": {
          css: "thead",
          html: "thead",
          "tr": {
            css: "tr",
            html: "tr",
            "th": {
              css: "th",
              html: "th"
            }
          }
        },
        "tr": {
          css: "tr",
          html: "tr",
          attrs: {
            ".rejected": {
              css: ".rejected",
              html: "rejected"
            }
          }
        }
      }
    },
    ".table-md-breakpoint": {
      css: ".table-md-breakpoint",
      html: "table-md-breakpoint",
      "table": {
        css: "table",
        html: "table",
        "tr": {
          css: "tr",
          html: "tr",
          attrs: {
            ".row-input": {
              css: ".row-input",
              html: "row-input"
            }
          },
          "img": {
            css: "img",
            html: "img"
          }
        }
      }
    },
    ".table-loader-inner": {
      css: ".table-loader-inner",
      html: "table-loader-inner"
    },
    ".table-loader-col": {
      css: ".table-loader-col",
      html: "table-loader-col"
    },
    ".table-action-right": {
      css: ".table-action-right",
      html: "table-action-right",
      ".table-actions": {
        css: ".table-actions",
        html: "table-actions",
        ".left-table-actions": {
          css: ".left-table-actions",
          html: "left-table-actions",
          ".table-action": {
            css: ".table-action",
            html: "table-action"
          }
        }
      }
    },
    ".portal-table-cell": {
      css: ".portal-table-cell",
      html: "portal-table-cell",
      attrs: {
        ".cell-control": {
          css: ".cell-control",
          html: "cell-control"
        },
        ".cell-primary": {
          css: ".cell-primary",
          html: "cell-primary"
        },
        ".disable-min-width": {
          css: ".disable-min-width",
          html: "disable-min-width"
        },
        ".icon-text-cell": {
          css: ".icon-text-cell",
          html: "icon-text-cell"
        },
        ".cell-photo": {
          css: ".cell-photo",
          html: "cell-photo"
        },
        ".cell-image-with-count": {
          css: ".cell-image-with-count",
          html: "cell-image-with-count"
        },
        ".cell-sector-state": {
          css: ".cell-sector-state",
          html: "cell-sector-state"
        }
      },
      ".control-icon": {
        css: ".control-icon",
        html: "control-icon",
        attrs: {
          ".control-expand": {
            css: ".control-expand",
            html: "control-expand"
          },
          ".disabled": {
            css: ".disabled",
            html: "disabled"
          }
        },
        "svg": {
          css: "svg",
          html: "svg"
        }
      },
      "td": {
        css: "td",
        html: "td"
      },
      ".control-drag-handle": {
        css: ".control-drag-handle",
        html: "control-drag-handle",
        "svg": {
          css: "svg",
          html: "svg"
        }
      },
      ".icon-edit-row": {
        css: ".icon-edit-row",
        html: "icon-edit-row"
      },
      ".truncated": {
        css: ".truncated",
        html: "truncated"
      },
      ".badge-cell-text": {
        css: ".badge-cell-text",
        html: "badge-cell-text"
      },
      "span": {
        css: "span",
        html: "span",
        attrs: {
          ".badge": {
            css: ".badge",
            html: "badge"
          },
          ".series-icons": {
            css: ".series-icons",
            html: "series-icons"
          },
          ".icons": {
            css: ".icons",
            html: "icons"
          }
        },
        "svg": {
          css: "svg",
          html: "svg",
          attrs: {
            ".icon": {
              css: ".icon",
              html: "icon"
            },
            ".icon-multiple-items": {
              css: ".icon-multiple-items",
              html: "icon-multiple-items"
            }
          }
        },
        ".hidden": {
          css: ".hidden",
          html: "hidden",
          ".icon": {
            css: ".icon",
            html: "icon"
          }
        },
        ".draft": {
          css: ".draft",
          html: "draft",
          ".icon": {
            css: ".icon",
            html: "icon"
          }
        },
        ".blue": {
          css: ".blue",
          html: "blue",
          ".icon": {
            css: ".icon",
            html: "icon"
          }
        }
      },
      "p": {
        css: "p",
        html: "p"
      },
      ".active": {
        css: ".active",
        html: "active"
      },
      ".icon": {
        css: ".icon",
        html: "icon"
      },
      "img": {
        css: "img",
        html: "img"
      },
      ".image-container": {
        css: ".image-container",
        html: "image-container",
        ".image-count-overlay": {
          css: ".image-count-overlay",
          html: "image-count-overlay"
        }
      },
      ".draft-tooltip-icon": {
        css: ".draft-tooltip-icon",
        html: "draft-tooltip-icon",
        "svg": {
          css: "svg",
          html: "svg"
        }
      },
      ".cell-image": {
        css: ".cell-image",
        html: "cell-image",
        "img": {
          css: "img",
          html: "img"
        },
        "svg": {
          css: "svg",
          html: "svg"
        }
      },
      ".icon-content": {
        css: ".icon-content",
        html: "icon-content"
      },
      ".icon-empty": {
        css: ".icon-empty",
        html: "icon-empty"
      },
      ".inactive": {
        css: ".inactive",
        html: "inactive"
      },
      ".action-label": {
        css: ".action-label",
        html: "action-label"
      }
    },
    ".portal-table-row": {
      css: ".portal-table-row",
      html: "portal-table-row",
      attrs: {
        ".row-inactive": {
          css: ".row-inactive",
          html: "row-inactive"
        },
        ".row-input": {
          css: ".row-input",
          html: "row-input"
        },
        ".disabled": {
          css: ".disabled",
          html: "disabled"
        },
        ".on-req": {
          css: ".on-req",
          html: "on-req"
        },
        ".matured": {
          css: ".matured",
          html: "matured"
        },
        ".muted": {
          css: ".muted",
          html: "muted"
        },
        ".draft": {
          css: ".draft",
          html: "draft"
        },
        ".rejected": {
          css: ".rejected",
          html: "rejected"
        },
        ".pending": {
          css: ".pending",
          html: "pending"
        },
        ".row-expanded": {
          css: ".row-expanded",
          html: "row-expanded"
        },
        ".row-error": {
          css: ".row-error",
          html: "row-error"
        },
        ".row-expanded-content": {
          css: ".row-expanded-content",
          html: "row-expanded-content"
        },
        ".row-selected": {
          css: ".row-selected",
          html: "row-selected"
        }
      },
      ".cell-primary": {
        css: ".cell-primary",
        html: "cell-primary"
      },
      "a": {
        css: "a",
        html: "a",
        attrs: {
          ".count": {
            css: ".count",
            html: "count"
          }
        }
      },
      ".portal-table-cell": {
        css: ".portal-table-cell",
        html: "portal-table-cell",
        "button": {
          css: "button",
          html: "button",
          attrs: {
            ".disabled": {
              css: ".disabled",
              html: "disabled"
            },
            ".icon": {
              css: ".icon",
              html: "icon"
            }
          }
        }
      },
      "td": {
        css: "td",
        html: "td",
        attrs: {
          ".error-detail-cell": {
            css: ".error-detail-cell",
            html: "error-detail-cell"
          },
          ".detail-cell": {
            css: ".detail-cell",
            html: "detail-cell"
          },
          ".row-safe-nowrap": {
            css: ".row-safe-nowrap",
            html: "row-safe-nowrap"
          },
          ".dataTables-empty": {
            css: ".dataTables-empty",
            html: "dataTables-empty"
          },
          ".cell-no-data": {
            css: ".cell-no-data",
            html: "cell-no-data"
          },
          ".cell-actions": {
            css: ".cell-actions",
            html: "cell-actions"
          },
          ".cell-control": {
            css: ".cell-control",
            html: "cell-control"
          },
          ".detail-action-column-d": {
            css: ".detail-action-column-d",
            html: "detail-action-column-d"
          },
          ".detail-action-column-a": {
            css: ".detail-action-column-a",
            html: "detail-action-column-a"
          },
          ".cell-select": {
            css: ".cell-select",
            html: "cell-select"
          },
          ".cell-image": {
            css: ".cell-image",
            html: "cell-image"
          }
        },
        "p": {
          css: "p",
          html: "p",
          attrs: {
            ".error-message": {
              css: ".error-message",
              html: "error-message"
            }
          }
        },
        ".content": {
          css: ".content",
          html: "content",
          "h4": {
            css: "h4",
            html: "h4"
          },
          ".separator": {
            css: ".separator",
            html: "separator"
          }
        },
        ":before": {
          css: ":before",
          html: "before"
        },
        ".action-icons": {
          css: ".action-icons",
          html: "action-icons"
        },
        ".bl-react-select-wrapper": {
          css: ".bl-react-select-wrapper",
          html: "bl-react-select-wrapper"
        },
        ".bl-react-select__control": {
          css: ".bl-react-select__control",
          html: "bl-react-select__control"
        },
        ".bl-react-select__control--is-focused": {
          css: ".bl-react-select__control--is-focused",
          html: "bl-react-select__control--is-focused"
        },
        ".bl-react-select__indicator": {
          css: ".bl-react-select__indicator",
          html: "bl-react-select__indicator"
        },
        ".bl-react-select__indicators": {
          css: ".bl-react-select__indicators",
          html: "bl-react-select__indicators"
        },
        ".bl-react-select__value-container": {
          css: ".bl-react-select__value-container",
          html: "bl-react-select__value-container"
        },
        ".bl-react-select__single-value": {
          css: ".bl-react-select__single-value",
          html: "bl-react-select__single-value"
        },
        ".caption-text": {
          css: ".caption-text",
          html: "caption-text"
        },
        ".caption-title": {
          css: ".caption-title",
          html: "caption-title"
        },
        ".cell-caption": {
          css: ".cell-caption",
          html: "cell-caption"
        }
      },
      ".content": {
        css: ".content",
        html: "content",
        "h4": {
          css: "h4",
          html: "h4"
        }
      },
      "img": {
        css: "img",
        html: "img"
      },
      ".cell-control": {
        css: ".cell-control",
        html: "cell-control",
        ".custom-indicator": {
          css: ".custom-indicator",
          html: "custom-indicator"
        },
        "td": {
          css: "td",
          html: "td"
        }
      },
      ".caption-text": {
        css: ".caption-text",
        html: "caption-text"
      },
      ".cell-actions": {
        css: ".cell-actions",
        html: "cell-actions",
        ".label": {
          css: ".label",
          html: "label"
        }
      },
      "th": {
        css: "th",
        html: "th",
        attrs: {
          ".cell-no-data": {
            css: ".cell-no-data",
            html: "cell-no-data"
          },
          ".cell-actions": {
            css: ".cell-actions",
            html: "cell-actions"
          },
          ".cell-control": {
            css: ".cell-control",
            html: "cell-control"
          },
          ".detail-action-column-d": {
            css: ".detail-action-column-d",
            html: "detail-action-column-d"
          },
          ".detail-action-column-a": {
            css: ".detail-action-column-a",
            html: "detail-action-column-a"
          },
          ".cell-select": {
            css: ".cell-select",
            html: "cell-select"
          },
          ".cell-image": {
            css: ".cell-image",
            html: "cell-image"
          }
        },
        ".bl-react-select-wrapper": {
          css: ".bl-react-select-wrapper",
          html: "bl-react-select-wrapper"
        },
        ".bl-react-select__control": {
          css: ".bl-react-select__control",
          html: "bl-react-select__control"
        },
        ".bl-react-select__control--is-focused": {
          css: ".bl-react-select__control--is-focused",
          html: "bl-react-select__control--is-focused"
        },
        ".bl-react-select__indicator": {
          css: ".bl-react-select__indicator",
          html: "bl-react-select__indicator"
        },
        ".bl-react-select__indicators": {
          css: ".bl-react-select__indicators",
          html: "bl-react-select__indicators"
        },
        ".bl-react-select__value-container": {
          css: ".bl-react-select__value-container",
          html: "bl-react-select__value-container"
        },
        ".bl-react-select__single-value": {
          css: ".bl-react-select__single-value",
          html: "bl-react-select__single-value"
        },
        ".caption-text": {
          css: ".caption-text",
          html: "caption-text"
        },
        ".caption-title": {
          css: ".caption-title",
          html: "caption-title"
        },
        ".cell-caption": {
          css: ".cell-caption",
          html: "cell-caption"
        }
      }
    },
    ".portal-table-action-icon": {
      css: ".portal-table-action-icon",
      html: "portal-table-action-icon",
      attrs: {
        ".disabled": {
          css: ".disabled",
          html: "disabled"
        }
      },
      ".portal-table-action-icon-button": {
        css: ".portal-table-action-icon-button",
        html: "portal-table-action-icon-button",
        "span": {
          css: "span",
          html: "span"
        }
      },
      ".portal-table-action-icon-link": {
        css: ".portal-table-action-icon-link",
        html: "portal-table-action-icon-link",
        "span": {
          css: "span",
          html: "span"
        }
      }
    },
    ".table-actions": {
      css: ".table-actions",
      html: "table-actions",
      attrs: {
        ".separator": {
          css: ".separator",
          html: "separator"
        }
      },
      ".btn": {
        css: ".btn",
        html: "btn"
      },
      ".left-action-prepend": {
        css: ".left-action-prepend",
        html: "left-action-prepend"
      },
      ".center-table-actions": {
        css: ".center-table-actions",
        html: "center-table-actions",
        ".sort": {
          css: ".sort",
          html: "sort"
        },
        ".table-action": {
          css: ".table-action",
          html: "table-action",
          ".maturity-select": {
            css: ".maturity-select",
            html: "maturity-select"
          }
        }
      },
      ".left-table-actions": {
        css: ".left-table-actions",
        html: "left-table-actions",
        ".sort": {
          css: ".sort",
          html: "sort"
        },
        ".table-action": {
          css: ".table-action",
          html: "table-action",
          ".maturity-select": {
            css: ".maturity-select",
            html: "maturity-select"
          }
        }
      },
      ".right-table-actions": {
        css: ".right-table-actions",
        html: "right-table-actions",
        ".sort": {
          css: ".sort",
          html: "sort"
        },
        ".table-action": {
          css: ".table-action",
          html: "table-action",
          ".maturity-select": {
            css: ".maturity-select",
            html: "maturity-select"
          }
        }
      },
      ".search": {
        css: ".search",
        html: "search"
      },
      ".sort": {
        css: ".sort",
        html: "sort",
        ".bl-react-select-container": {
          css: ".bl-react-select-container",
          html: "bl-react-select-container",
          ".bl-react-select__single-value": {
            css: ".bl-react-select__single-value",
            html: "bl-react-select__single-value"
          }
        }
      },
      ".table-action": {
        css: ".table-action",
        html: "table-action"
      },
      ".search-input": {
        css: ".search-input",
        html: "search-input"
      }
    },
    ".portal-table-img": {
      css: ".portal-table-img",
      html: "portal-table-img",
      attrs: {
        ".table-img-fixed-width": {
          css: ".table-img-fixed-width",
          html: "table-img-fixed-width"
        }
      },
      "img": {
        css: "img",
        html: "img"
      }
    },
    ".portal-table-img-lg": {
      css: ".portal-table-img-lg",
      html: "portal-table-img-lg",
      "img": {
        css: "img",
        html: "img"
      }
    }
  },
  ".table-md-breakpoint": {
    css: ".table-md-breakpoint",
    html: "table-md-breakpoint",
    "table": {
      css: "table",
      html: "table",
      ".portal-table-container": {
        css: ".portal-table-container",
        html: "portal-table-container",
        attrs: {
          ".headerless": {
            css: ".headerless",
            html: "headerless"
          }
        },
        "tr": {
          css: "tr",
          html: "tr",
          ".cell-control": {
            css: ".cell-control",
            html: "cell-control",
            "td": {
              css: "td",
              html: "td"
            }
          }
        }
      },
      "colgroup": {
        css: "colgroup",
        html: "colgroup"
      },
      "thead": {
        css: "thead",
        html: "thead",
        "th": {
          css: "th",
          html: "th",
          ".header-tooltip": {
            css: ".header-tooltip",
            html: "header-tooltip",
            "svg": {
              css: "svg",
              html: "svg"
            }
          }
        },
        "td": {
          css: "td",
          html: "td"
        }
      },
      "tbody": {
        css: "tbody",
        html: "tbody",
        "tr": {
          css: "tr",
          html: "tr"
        },
        "td": {
          css: "td",
          html: "td"
        },
        "th": {
          css: "th",
          html: "th"
        }
      },
      "td": {
        css: "td",
        html: "td"
      },
      "th": {
        css: "th",
        html: "th",
        attrs: {
          ".asc": {
            css: ".asc",
            html: "asc"
          },
          ".desc": {
            css: ".desc",
            html: "desc"
          }
        },
        "a": {
          css: "a",
          html: "a"
        }
      },
      "tr": {
        css: "tr",
        html: "tr",
        attrs: {
          ".row-inactive": {
            css: ".row-inactive",
            html: "row-inactive"
          },
          ".row-input": {
            css: ".row-input",
            html: "row-input"
          },
          ".disabled": {
            css: ".disabled",
            html: "disabled"
          },
          ".on-req": {
            css: ".on-req",
            html: "on-req"
          },
          ".matured": {
            css: ".matured",
            html: "matured"
          },
          ".muted": {
            css: ".muted",
            html: "muted"
          },
          ".draft": {
            css: ".draft",
            html: "draft"
          },
          ".rejected": {
            css: ".rejected",
            html: "rejected"
          },
          ".pending": {
            css: ".pending",
            html: "pending"
          },
          ".row-expanded": {
            css: ".row-expanded",
            html: "row-expanded"
          },
          ".row-error": {
            css: ".row-error",
            html: "row-error"
          },
          ".row-expanded-content": {
            css: ".row-expanded-content",
            html: "row-expanded-content"
          },
          ".row-selected": {
            css: ".row-selected",
            html: "row-selected"
          }
        },
        ".cell-primary": {
          css: ".cell-primary",
          html: "cell-primary"
        },
        "a": {
          css: "a",
          html: "a",
          attrs: {
            ".count": {
              css: ".count",
              html: "count"
            }
          }
        },
        ".portal-table-cell": {
          css: ".portal-table-cell",
          html: "portal-table-cell",
          "button": {
            css: "button",
            html: "button",
            attrs: {
              ".disabled": {
                css: ".disabled",
                html: "disabled"
              },
              ".icon": {
                css: ".icon",
                html: "icon"
              }
            }
          }
        },
        "td": {
          css: "td",
          html: "td",
          attrs: {
            ".error-detail-cell": {
              css: ".error-detail-cell",
              html: "error-detail-cell"
            },
            ".detail-cell": {
              css: ".detail-cell",
              html: "detail-cell"
            },
            ".row-safe-nowrap": {
              css: ".row-safe-nowrap",
              html: "row-safe-nowrap"
            },
            ".dataTables-empty": {
              css: ".dataTables-empty",
              html: "dataTables-empty"
            },
            ".cell-no-data": {
              css: ".cell-no-data",
              html: "cell-no-data"
            },
            ".cell-actions": {
              css: ".cell-actions",
              html: "cell-actions"
            },
            ".cell-control": {
              css: ".cell-control",
              html: "cell-control"
            },
            ".detail-action-column-d": {
              css: ".detail-action-column-d",
              html: "detail-action-column-d"
            },
            ".detail-action-column-a": {
              css: ".detail-action-column-a",
              html: "detail-action-column-a"
            },
            ".cell-select": {
              css: ".cell-select",
              html: "cell-select"
            },
            ".cell-image": {
              css: ".cell-image",
              html: "cell-image"
            }
          },
          "p": {
            css: "p",
            html: "p",
            attrs: {
              ".error-message": {
                css: ".error-message",
                html: "error-message"
              }
            }
          },
          ".content": {
            css: ".content",
            html: "content",
            "h4": {
              css: "h4",
              html: "h4"
            },
            ".separator": {
              css: ".separator",
              html: "separator"
            }
          },
          ":before": {
            css: ":before",
            html: "before"
          },
          ".action-icons": {
            css: ".action-icons",
            html: "action-icons"
          },
          ".bl-react-select-wrapper": {
            css: ".bl-react-select-wrapper",
            html: "bl-react-select-wrapper"
          },
          ".bl-react-select__control": {
            css: ".bl-react-select__control",
            html: "bl-react-select__control"
          },
          ".bl-react-select__control--is-focused": {
            css: ".bl-react-select__control--is-focused",
            html: "bl-react-select__control--is-focused"
          },
          ".bl-react-select__indicator": {
            css: ".bl-react-select__indicator",
            html: "bl-react-select__indicator"
          },
          ".bl-react-select__indicators": {
            css: ".bl-react-select__indicators",
            html: "bl-react-select__indicators"
          },
          ".bl-react-select__value-container": {
            css: ".bl-react-select__value-container",
            html: "bl-react-select__value-container"
          },
          ".bl-react-select__single-value": {
            css: ".bl-react-select__single-value",
            html: "bl-react-select__single-value"
          },
          ".caption-text": {
            css: ".caption-text",
            html: "caption-text"
          },
          ".caption-title": {
            css: ".caption-title",
            html: "caption-title"
          },
          ".cell-caption": {
            css: ".cell-caption",
            html: "cell-caption"
          }
        },
        ".content": {
          css: ".content",
          html: "content",
          "h4": {
            css: "h4",
            html: "h4"
          }
        },
        "img": {
          css: "img",
          html: "img"
        },
        ".cell-control": {
          css: ".cell-control",
          html: "cell-control",
          ".custom-indicator": {
            css: ".custom-indicator",
            html: "custom-indicator"
          },
          "td": {
            css: "td",
            html: "td"
          }
        },
        ".caption-text": {
          css: ".caption-text",
          html: "caption-text"
        },
        ".cell-actions": {
          css: ".cell-actions",
          html: "cell-actions",
          ".label": {
            css: ".label",
            html: "label"
          }
        },
        "th": {
          css: "th",
          html: "th",
          attrs: {
            ".cell-no-data": {
              css: ".cell-no-data",
              html: "cell-no-data"
            },
            ".cell-actions": {
              css: ".cell-actions",
              html: "cell-actions"
            },
            ".cell-control": {
              css: ".cell-control",
              html: "cell-control"
            },
            ".detail-action-column-d": {
              css: ".detail-action-column-d",
              html: "detail-action-column-d"
            },
            ".detail-action-column-a": {
              css: ".detail-action-column-a",
              html: "detail-action-column-a"
            },
            ".cell-select": {
              css: ".cell-select",
              html: "cell-select"
            },
            ".cell-image": {
              css: ".cell-image",
              html: "cell-image"
            }
          },
          ".bl-react-select-wrapper": {
            css: ".bl-react-select-wrapper",
            html: "bl-react-select-wrapper"
          },
          ".bl-react-select__control": {
            css: ".bl-react-select__control",
            html: "bl-react-select__control"
          },
          ".bl-react-select__control--is-focused": {
            css: ".bl-react-select__control--is-focused",
            html: "bl-react-select__control--is-focused"
          },
          ".bl-react-select__indicator": {
            css: ".bl-react-select__indicator",
            html: "bl-react-select__indicator"
          },
          ".bl-react-select__indicators": {
            css: ".bl-react-select__indicators",
            html: "bl-react-select__indicators"
          },
          ".bl-react-select__value-container": {
            css: ".bl-react-select__value-container",
            html: "bl-react-select__value-container"
          },
          ".bl-react-select__single-value": {
            css: ".bl-react-select__single-value",
            html: "bl-react-select__single-value"
          },
          ".caption-text": {
            css: ".caption-text",
            html: "caption-text"
          },
          ".caption-title": {
            css: ".caption-title",
            html: "caption-title"
          },
          ".cell-caption": {
            css: ".cell-caption",
            html: "cell-caption"
          }
        }
      },
      "table": {
        css: "table",
        html: "table",
        attrs: {
          ".table-simple": {
            css: ".table-simple",
            html: "table-simple"
          }
        },
        "tbody": {
          css: "tbody",
          html: "tbody",
          "tr": {
            css: "tr",
            html: "tr",
            "td": {
              css: "td",
              html: "td"
            }
          }
        },
        "td": {
          css: "td",
          html: "td"
        },
        "th": {
          css: "th",
          html: "th"
        },
        "thead": {
          css: "thead",
          html: "thead",
          "tr": {
            css: "tr",
            html: "tr",
            "th": {
              css: "th",
              html: "th"
            }
          }
        },
        "tr": {
          css: "tr",
          html: "tr",
          attrs: {
            ".rejected": {
              css: ".rejected",
              html: "rejected"
            }
          }
        }
      },
      "body": {
        css: "body",
        html: "body",
        "tr": {
          css: "tr",
          html: "tr",
          ".table-md-breakpoint": {
            css: ".table-md-breakpoint",
            html: "table-md-breakpoint",
            ".portal-table-cell": {
              css: ".portal-table-cell",
              html: "portal-table-cell",
              attrs: {
                ".cell-control": {
                  css: ".cell-control",
                  html: "cell-control"
                },
                ".cell-primary": {
                  css: ".cell-primary",
                  html: "cell-primary"
                }
              }
            }
          },
          ".table-lg-breakpoint": {
            css: ".table-lg-breakpoint",
            html: "table-lg-breakpoint",
            ".portal-table-cell": {
              css: ".portal-table-cell",
              html: "portal-table-cell",
              attrs: {
                ".cell-control": {
                  css: ".cell-control",
                  html: "cell-control"
                },
                ".cell-primary": {
                  css: ".cell-primary",
                  html: "cell-primary"
                }
              }
            }
          }
        }
      },
      ".table-lg-breakpoint": {
        css: ".table-lg-breakpoint",
        html: "table-lg-breakpoint",
        "tr": {
          css: "tr",
          html: "tr",
          attrs: {
            ".row-input": {
              css: ".row-input",
              html: "row-input"
            },
            ".row-inactive": {
              css: ".row-inactive",
              html: "row-inactive"
            },
            ".disabled": {
              css: ".disabled",
              html: "disabled"
            },
            ".on-req": {
              css: ".on-req",
              html: "on-req"
            },
            ".matured": {
              css: ".matured",
              html: "matured"
            },
            ".muted": {
              css: ".muted",
              html: "muted"
            },
            ".draft": {
              css: ".draft",
              html: "draft"
            },
            ".rejected": {
              css: ".rejected",
              html: "rejected"
            },
            ".pending": {
              css: ".pending",
              html: "pending"
            },
            ".row-expanded": {
              css: ".row-expanded",
              html: "row-expanded"
            },
            ".row-error": {
              css: ".row-error",
              html: "row-error"
            },
            ".row-expanded-content": {
              css: ".row-expanded-content",
              html: "row-expanded-content"
            },
            ".row-selected": {
              css: ".row-selected",
              html: "row-selected"
            }
          },
          ".cell-primary": {
            css: ".cell-primary",
            html: "cell-primary"
          },
          "a": {
            css: "a",
            html: "a",
            attrs: {
              ".count": {
                css: ".count",
                html: "count"
              }
            }
          },
          ".portal-table-cell": {
            css: ".portal-table-cell",
            html: "portal-table-cell",
            "button": {
              css: "button",
              html: "button",
              attrs: {
                ".disabled": {
                  css: ".disabled",
                  html: "disabled"
                },
                ".icon": {
                  css: ".icon",
                  html: "icon"
                }
              }
            }
          },
          "td": {
            css: "td",
            html: "td",
            attrs: {
              ".error-detail-cell": {
                css: ".error-detail-cell",
                html: "error-detail-cell"
              },
              ".detail-cell": {
                css: ".detail-cell",
                html: "detail-cell"
              },
              ".row-safe-nowrap": {
                css: ".row-safe-nowrap",
                html: "row-safe-nowrap"
              },
              ".dataTables-empty": {
                css: ".dataTables-empty",
                html: "dataTables-empty"
              },
              ".cell-no-data": {
                css: ".cell-no-data",
                html: "cell-no-data"
              },
              ".cell-actions": {
                css: ".cell-actions",
                html: "cell-actions"
              },
              ".cell-control": {
                css: ".cell-control",
                html: "cell-control"
              },
              ".detail-action-column-d": {
                css: ".detail-action-column-d",
                html: "detail-action-column-d"
              },
              ".detail-action-column-a": {
                css: ".detail-action-column-a",
                html: "detail-action-column-a"
              },
              ".cell-select": {
                css: ".cell-select",
                html: "cell-select"
              },
              ".cell-image": {
                css: ".cell-image",
                html: "cell-image"
              }
            },
            "p": {
              css: "p",
              html: "p",
              attrs: {
                ".error-message": {
                  css: ".error-message",
                  html: "error-message"
                }
              }
            },
            ".content": {
              css: ".content",
              html: "content",
              "h4": {
                css: "h4",
                html: "h4"
              },
              ".separator": {
                css: ".separator",
                html: "separator"
              }
            },
            ":before": {
              css: ":before",
              html: "before"
            },
            ".action-icons": {
              css: ".action-icons",
              html: "action-icons"
            },
            ".bl-react-select-wrapper": {
              css: ".bl-react-select-wrapper",
              html: "bl-react-select-wrapper"
            },
            ".bl-react-select__control": {
              css: ".bl-react-select__control",
              html: "bl-react-select__control"
            },
            ".bl-react-select__control--is-focused": {
              css: ".bl-react-select__control--is-focused",
              html: "bl-react-select__control--is-focused"
            },
            ".bl-react-select__indicator": {
              css: ".bl-react-select__indicator",
              html: "bl-react-select__indicator"
            },
            ".bl-react-select__indicators": {
              css: ".bl-react-select__indicators",
              html: "bl-react-select__indicators"
            },
            ".bl-react-select__value-container": {
              css: ".bl-react-select__value-container",
              html: "bl-react-select__value-container"
            },
            ".bl-react-select__single-value": {
              css: ".bl-react-select__single-value",
              html: "bl-react-select__single-value"
            },
            ".caption-text": {
              css: ".caption-text",
              html: "caption-text"
            },
            ".caption-title": {
              css: ".caption-title",
              html: "caption-title"
            },
            ".cell-caption": {
              css: ".cell-caption",
              html: "cell-caption"
            }
          },
          ".content": {
            css: ".content",
            html: "content",
            "h4": {
              css: "h4",
              html: "h4"
            }
          },
          "img": {
            css: "img",
            html: "img"
          },
          ".cell-control": {
            css: ".cell-control",
            html: "cell-control",
            ".custom-indicator": {
              css: ".custom-indicator",
              html: "custom-indicator"
            },
            "td": {
              css: "td",
              html: "td"
            }
          },
          ".caption-text": {
            css: ".caption-text",
            html: "caption-text"
          },
          ".cell-actions": {
            css: ".cell-actions",
            html: "cell-actions",
            ".label": {
              css: ".label",
              html: "label"
            }
          },
          "th": {
            css: "th",
            html: "th",
            attrs: {
              ".cell-no-data": {
                css: ".cell-no-data",
                html: "cell-no-data"
              },
              ".cell-actions": {
                css: ".cell-actions",
                html: "cell-actions"
              },
              ".cell-control": {
                css: ".cell-control",
                html: "cell-control"
              },
              ".detail-action-column-d": {
                css: ".detail-action-column-d",
                html: "detail-action-column-d"
              },
              ".detail-action-column-a": {
                css: ".detail-action-column-a",
                html: "detail-action-column-a"
              },
              ".cell-select": {
                css: ".cell-select",
                html: "cell-select"
              },
              ".cell-image": {
                css: ".cell-image",
                html: "cell-image"
              }
            },
            ".bl-react-select-wrapper": {
              css: ".bl-react-select-wrapper",
              html: "bl-react-select-wrapper"
            },
            ".bl-react-select__control": {
              css: ".bl-react-select__control",
              html: "bl-react-select__control"
            },
            ".bl-react-select__control--is-focused": {
              css: ".bl-react-select__control--is-focused",
              html: "bl-react-select__control--is-focused"
            },
            ".bl-react-select__indicator": {
              css: ".bl-react-select__indicator",
              html: "bl-react-select__indicator"
            },
            ".bl-react-select__indicators": {
              css: ".bl-react-select__indicators",
              html: "bl-react-select__indicators"
            },
            ".bl-react-select__value-container": {
              css: ".bl-react-select__value-container",
              html: "bl-react-select__value-container"
            },
            ".bl-react-select__single-value": {
              css: ".bl-react-select__single-value",
              html: "bl-react-select__single-value"
            },
            ".caption-text": {
              css: ".caption-text",
              html: "caption-text"
            },
            ".caption-title": {
              css: ".caption-title",
              html: "caption-title"
            },
            ".cell-caption": {
              css: ".cell-caption",
              html: "cell-caption"
            }
          }
        }
      }
    },
    ".table-loader-inner": {
      css: ".table-loader-inner",
      html: "table-loader-inner"
    },
    ".table-loader-col": {
      css: ".table-loader-col",
      html: "table-loader-col"
    },
    ".table-action-right": {
      css: ".table-action-right",
      html: "table-action-right",
      ".table-actions": {
        css: ".table-actions",
        html: "table-actions",
        ".left-table-actions": {
          css: ".left-table-actions",
          html: "left-table-actions",
          ".table-action": {
            css: ".table-action",
            html: "table-action"
          }
        }
      }
    },
    ".portal-table-cell": {
      css: ".portal-table-cell",
      html: "portal-table-cell",
      attrs: {
        ".cell-control": {
          css: ".cell-control",
          html: "cell-control"
        },
        ".cell-primary": {
          css: ".cell-primary",
          html: "cell-primary"
        },
        ".disable-min-width": {
          css: ".disable-min-width",
          html: "disable-min-width"
        },
        ".icon-text-cell": {
          css: ".icon-text-cell",
          html: "icon-text-cell"
        },
        ".cell-photo": {
          css: ".cell-photo",
          html: "cell-photo"
        },
        ".cell-image-with-count": {
          css: ".cell-image-with-count",
          html: "cell-image-with-count"
        },
        ".cell-sector-state": {
          css: ".cell-sector-state",
          html: "cell-sector-state"
        }
      },
      ".control-icon": {
        css: ".control-icon",
        html: "control-icon",
        attrs: {
          ".control-expand": {
            css: ".control-expand",
            html: "control-expand"
          },
          ".disabled": {
            css: ".disabled",
            html: "disabled"
          }
        },
        "svg": {
          css: "svg",
          html: "svg"
        }
      },
      "td": {
        css: "td",
        html: "td"
      },
      ".control-drag-handle": {
        css: ".control-drag-handle",
        html: "control-drag-handle",
        "svg": {
          css: "svg",
          html: "svg"
        }
      },
      ".icon-edit-row": {
        css: ".icon-edit-row",
        html: "icon-edit-row"
      },
      ".truncated": {
        css: ".truncated",
        html: "truncated"
      },
      ".badge-cell-text": {
        css: ".badge-cell-text",
        html: "badge-cell-text"
      },
      "span": {
        css: "span",
        html: "span",
        attrs: {
          ".badge": {
            css: ".badge",
            html: "badge"
          },
          ".series-icons": {
            css: ".series-icons",
            html: "series-icons"
          },
          ".icons": {
            css: ".icons",
            html: "icons"
          }
        },
        "svg": {
          css: "svg",
          html: "svg",
          attrs: {
            ".icon": {
              css: ".icon",
              html: "icon"
            },
            ".icon-multiple-items": {
              css: ".icon-multiple-items",
              html: "icon-multiple-items"
            }
          }
        },
        ".hidden": {
          css: ".hidden",
          html: "hidden",
          ".icon": {
            css: ".icon",
            html: "icon"
          }
        },
        ".draft": {
          css: ".draft",
          html: "draft",
          ".icon": {
            css: ".icon",
            html: "icon"
          }
        },
        ".blue": {
          css: ".blue",
          html: "blue",
          ".icon": {
            css: ".icon",
            html: "icon"
          }
        }
      },
      "p": {
        css: "p",
        html: "p"
      },
      ".active": {
        css: ".active",
        html: "active"
      },
      ".icon": {
        css: ".icon",
        html: "icon"
      },
      "img": {
        css: "img",
        html: "img"
      },
      ".image-container": {
        css: ".image-container",
        html: "image-container",
        ".image-count-overlay": {
          css: ".image-count-overlay",
          html: "image-count-overlay"
        }
      },
      ".draft-tooltip-icon": {
        css: ".draft-tooltip-icon",
        html: "draft-tooltip-icon",
        "svg": {
          css: "svg",
          html: "svg"
        }
      },
      ".cell-image": {
        css: ".cell-image",
        html: "cell-image",
        "img": {
          css: "img",
          html: "img"
        },
        "svg": {
          css: "svg",
          html: "svg"
        }
      },
      ".icon-content": {
        css: ".icon-content",
        html: "icon-content"
      },
      ".icon-empty": {
        css: ".icon-empty",
        html: "icon-empty"
      },
      ".inactive": {
        css: ".inactive",
        html: "inactive"
      },
      ".action-label": {
        css: ".action-label",
        html: "action-label"
      }
    },
    ".portal-table-row": {
      css: ".portal-table-row",
      html: "portal-table-row",
      attrs: {
        ".row-inactive": {
          css: ".row-inactive",
          html: "row-inactive"
        },
        ".row-input": {
          css: ".row-input",
          html: "row-input"
        },
        ".disabled": {
          css: ".disabled",
          html: "disabled"
        },
        ".on-req": {
          css: ".on-req",
          html: "on-req"
        },
        ".matured": {
          css: ".matured",
          html: "matured"
        },
        ".muted": {
          css: ".muted",
          html: "muted"
        },
        ".draft": {
          css: ".draft",
          html: "draft"
        },
        ".rejected": {
          css: ".rejected",
          html: "rejected"
        },
        ".pending": {
          css: ".pending",
          html: "pending"
        },
        ".row-expanded": {
          css: ".row-expanded",
          html: "row-expanded"
        },
        ".row-error": {
          css: ".row-error",
          html: "row-error"
        },
        ".row-expanded-content": {
          css: ".row-expanded-content",
          html: "row-expanded-content"
        },
        ".row-selected": {
          css: ".row-selected",
          html: "row-selected"
        }
      },
      ".cell-primary": {
        css: ".cell-primary",
        html: "cell-primary"
      },
      "a": {
        css: "a",
        html: "a",
        attrs: {
          ".count": {
            css: ".count",
            html: "count"
          }
        }
      },
      ".portal-table-cell": {
        css: ".portal-table-cell",
        html: "portal-table-cell",
        "button": {
          css: "button",
          html: "button",
          attrs: {
            ".disabled": {
              css: ".disabled",
              html: "disabled"
            },
            ".icon": {
              css: ".icon",
              html: "icon"
            }
          }
        }
      },
      "td": {
        css: "td",
        html: "td",
        attrs: {
          ".error-detail-cell": {
            css: ".error-detail-cell",
            html: "error-detail-cell"
          },
          ".detail-cell": {
            css: ".detail-cell",
            html: "detail-cell"
          },
          ".row-safe-nowrap": {
            css: ".row-safe-nowrap",
            html: "row-safe-nowrap"
          },
          ".dataTables-empty": {
            css: ".dataTables-empty",
            html: "dataTables-empty"
          },
          ".cell-no-data": {
            css: ".cell-no-data",
            html: "cell-no-data"
          },
          ".cell-actions": {
            css: ".cell-actions",
            html: "cell-actions"
          },
          ".cell-control": {
            css: ".cell-control",
            html: "cell-control"
          },
          ".detail-action-column-d": {
            css: ".detail-action-column-d",
            html: "detail-action-column-d"
          },
          ".detail-action-column-a": {
            css: ".detail-action-column-a",
            html: "detail-action-column-a"
          },
          ".cell-select": {
            css: ".cell-select",
            html: "cell-select"
          },
          ".cell-image": {
            css: ".cell-image",
            html: "cell-image"
          }
        },
        "p": {
          css: "p",
          html: "p",
          attrs: {
            ".error-message": {
              css: ".error-message",
              html: "error-message"
            }
          }
        },
        ".content": {
          css: ".content",
          html: "content",
          "h4": {
            css: "h4",
            html: "h4"
          },
          ".separator": {
            css: ".separator",
            html: "separator"
          }
        },
        ":before": {
          css: ":before",
          html: "before"
        },
        ".action-icons": {
          css: ".action-icons",
          html: "action-icons"
        },
        ".bl-react-select-wrapper": {
          css: ".bl-react-select-wrapper",
          html: "bl-react-select-wrapper"
        },
        ".bl-react-select__control": {
          css: ".bl-react-select__control",
          html: "bl-react-select__control"
        },
        ".bl-react-select__control--is-focused": {
          css: ".bl-react-select__control--is-focused",
          html: "bl-react-select__control--is-focused"
        },
        ".bl-react-select__indicator": {
          css: ".bl-react-select__indicator",
          html: "bl-react-select__indicator"
        },
        ".bl-react-select__indicators": {
          css: ".bl-react-select__indicators",
          html: "bl-react-select__indicators"
        },
        ".bl-react-select__value-container": {
          css: ".bl-react-select__value-container",
          html: "bl-react-select__value-container"
        },
        ".bl-react-select__single-value": {
          css: ".bl-react-select__single-value",
          html: "bl-react-select__single-value"
        },
        ".caption-text": {
          css: ".caption-text",
          html: "caption-text"
        },
        ".caption-title": {
          css: ".caption-title",
          html: "caption-title"
        },
        ".cell-caption": {
          css: ".cell-caption",
          html: "cell-caption"
        }
      },
      ".content": {
        css: ".content",
        html: "content",
        "h4": {
          css: "h4",
          html: "h4"
        }
      },
      "img": {
        css: "img",
        html: "img"
      },
      ".cell-control": {
        css: ".cell-control",
        html: "cell-control",
        ".custom-indicator": {
          css: ".custom-indicator",
          html: "custom-indicator"
        },
        "td": {
          css: "td",
          html: "td"
        }
      },
      ".caption-text": {
        css: ".caption-text",
        html: "caption-text"
      },
      ".cell-actions": {
        css: ".cell-actions",
        html: "cell-actions",
        ".label": {
          css: ".label",
          html: "label"
        }
      },
      "th": {
        css: "th",
        html: "th",
        attrs: {
          ".cell-no-data": {
            css: ".cell-no-data",
            html: "cell-no-data"
          },
          ".cell-actions": {
            css: ".cell-actions",
            html: "cell-actions"
          },
          ".cell-control": {
            css: ".cell-control",
            html: "cell-control"
          },
          ".detail-action-column-d": {
            css: ".detail-action-column-d",
            html: "detail-action-column-d"
          },
          ".detail-action-column-a": {
            css: ".detail-action-column-a",
            html: "detail-action-column-a"
          },
          ".cell-select": {
            css: ".cell-select",
            html: "cell-select"
          },
          ".cell-image": {
            css: ".cell-image",
            html: "cell-image"
          }
        },
        ".bl-react-select-wrapper": {
          css: ".bl-react-select-wrapper",
          html: "bl-react-select-wrapper"
        },
        ".bl-react-select__control": {
          css: ".bl-react-select__control",
          html: "bl-react-select__control"
        },
        ".bl-react-select__control--is-focused": {
          css: ".bl-react-select__control--is-focused",
          html: "bl-react-select__control--is-focused"
        },
        ".bl-react-select__indicator": {
          css: ".bl-react-select__indicator",
          html: "bl-react-select__indicator"
        },
        ".bl-react-select__indicators": {
          css: ".bl-react-select__indicators",
          html: "bl-react-select__indicators"
        },
        ".bl-react-select__value-container": {
          css: ".bl-react-select__value-container",
          html: "bl-react-select__value-container"
        },
        ".bl-react-select__single-value": {
          css: ".bl-react-select__single-value",
          html: "bl-react-select__single-value"
        },
        ".caption-text": {
          css: ".caption-text",
          html: "caption-text"
        },
        ".caption-title": {
          css: ".caption-title",
          html: "caption-title"
        },
        ".cell-caption": {
          css: ".cell-caption",
          html: "cell-caption"
        }
      }
    },
    ".table-lg-breakpoint": {
      css: ".table-lg-breakpoint",
      html: "table-lg-breakpoint",
      "table": {
        css: "table",
        html: "table",
        "tr": {
          css: "tr",
          html: "tr",
          attrs: {
            ".row-input": {
              css: ".row-input",
              html: "row-input"
            }
          },
          "img": {
            css: "img",
            html: "img"
          },
          "td": {
            css: "td",
            html: "td",
            attrs: {
              ".cell-no-data": {
                css: ".cell-no-data",
                html: "cell-no-data"
              },
              ".cell-actions": {
                css: ".cell-actions",
                html: "cell-actions"
              },
              ".cell-control": {
                css: ".cell-control",
                html: "cell-control"
              },
              ".cell-image": {
                css: ".cell-image",
                html: "cell-image"
              }
            },
            ".caption-text": {
              css: ".caption-text",
              html: "caption-text"
            },
            ".caption-title": {
              css: ".caption-title",
              html: "caption-title"
            },
            ".cell-caption": {
              css: ".cell-caption",
              html: "cell-caption"
            }
          },
          "th": {
            css: "th",
            html: "th",
            attrs: {
              ".cell-no-data": {
                css: ".cell-no-data",
                html: "cell-no-data"
              },
              ".cell-actions": {
                css: ".cell-actions",
                html: "cell-actions"
              },
              ".cell-control": {
                css: ".cell-control",
                html: "cell-control"
              },
              ".cell-image": {
                css: ".cell-image",
                html: "cell-image"
              }
            },
            ".caption-text": {
              css: ".caption-text",
              html: "caption-text"
            },
            ".caption-title": {
              css: ".caption-title",
              html: "caption-title"
            },
            ".cell-caption": {
              css: ".cell-caption",
              html: "cell-caption"
            }
          }
        }
      }
    },
    ".portal-table-action-icon": {
      css: ".portal-table-action-icon",
      html: "portal-table-action-icon",
      attrs: {
        ".disabled": {
          css: ".disabled",
          html: "disabled"
        }
      },
      ".portal-table-action-icon-button": {
        css: ".portal-table-action-icon-button",
        html: "portal-table-action-icon-button",
        "span": {
          css: "span",
          html: "span"
        }
      },
      ".portal-table-action-icon-link": {
        css: ".portal-table-action-icon-link",
        html: "portal-table-action-icon-link",
        "span": {
          css: "span",
          html: "span"
        }
      }
    },
    ".table-actions": {
      css: ".table-actions",
      html: "table-actions",
      attrs: {
        ".separator": {
          css: ".separator",
          html: "separator"
        }
      },
      ".btn": {
        css: ".btn",
        html: "btn"
      },
      ".left-action-prepend": {
        css: ".left-action-prepend",
        html: "left-action-prepend"
      },
      ".center-table-actions": {
        css: ".center-table-actions",
        html: "center-table-actions",
        ".sort": {
          css: ".sort",
          html: "sort"
        },
        ".table-action": {
          css: ".table-action",
          html: "table-action",
          ".maturity-select": {
            css: ".maturity-select",
            html: "maturity-select"
          }
        }
      },
      ".left-table-actions": {
        css: ".left-table-actions",
        html: "left-table-actions",
        ".sort": {
          css: ".sort",
          html: "sort"
        },
        ".table-action": {
          css: ".table-action",
          html: "table-action",
          ".maturity-select": {
            css: ".maturity-select",
            html: "maturity-select"
          }
        }
      },
      ".right-table-actions": {
        css: ".right-table-actions",
        html: "right-table-actions",
        ".sort": {
          css: ".sort",
          html: "sort"
        },
        ".table-action": {
          css: ".table-action",
          html: "table-action",
          ".maturity-select": {
            css: ".maturity-select",
            html: "maturity-select"
          }
        }
      },
      ".search": {
        css: ".search",
        html: "search"
      },
      ".sort": {
        css: ".sort",
        html: "sort",
        ".bl-react-select-container": {
          css: ".bl-react-select-container",
          html: "bl-react-select-container",
          ".bl-react-select__single-value": {
            css: ".bl-react-select__single-value",
            html: "bl-react-select__single-value"
          }
        }
      },
      ".table-action": {
        css: ".table-action",
        html: "table-action"
      },
      ".search-input": {
        css: ".search-input",
        html: "search-input"
      }
    },
    ".portal-table-img": {
      css: ".portal-table-img",
      html: "portal-table-img",
      attrs: {
        ".table-img-fixed-width": {
          css: ".table-img-fixed-width",
          html: "table-img-fixed-width"
        }
      },
      "img": {
        css: "img",
        html: "img"
      }
    },
    ".portal-table-img-lg": {
      css: ".portal-table-img-lg",
      html: "portal-table-img-lg",
      "img": {
        css: "img",
        html: "img"
      }
    }
  },
  ".portal-table-filter": {
    css: ".portal-table-filter",
    html: "portal-table-filter",
    ".action-btns": {
      css: ".action-btns",
      html: "action-btns",
      ".btn": {
        css: ".btn",
        html: "btn"
      }
    },
    ".filters-button": {
      css: ".filters-button",
      html: "filters-button"
    },
    ".toolbar": {
      css: ".toolbar",
      html: "toolbar",
      attrs: {
        ".header": {
          css: ".header",
          html: "header"
        }
      },
      ".subheader-standard": {
        css: ".subheader-standard",
        html: "subheader-standard"
      },
      ".actions": {
        css: ".actions",
        html: "actions",
        "*": {
          css: "*",
          html: "*"
        },
        ":last-child": {
          css: ":last-child",
          html: "last-child"
        }
      }
    },
    ".group-header": {
      css: ".group-header",
      html: "group-header",
      "svg": {
        css: "svg",
        html: "svg"
      }
    },
    ".filter-type-list": {
      css: ".filter-type-list",
      html: "filter-type-list",
      attrs: {
        ".expanded": {
          css: ".expanded",
          html: "expanded"
        },
        ".checkbox-group": {
          css: ".checkbox-group",
          html: "checkbox-group"
        },
        ".transitioned": {
          css: ".transitioned",
          html: "transitioned"
        }
      }
    },
    ".filter-radio": {
      css: ".filter-radio",
      html: "filter-radio",
      ".custom-indicator": {
        css: ".custom-indicator",
        html: "custom-indicator"
      }
    },
    ".checkbox-group": {
      css: ".checkbox-group",
      html: "checkbox-group",
      attrs: {
        ".transitioning": {
          css: ".transitioning",
          html: "transitioning"
        },
        ".transitioned": {
          css: ".transitioned",
          html: "transitioned"
        }
      },
      ".close-filters-button": {
        css: ".close-filters-button",
        html: "close-filters-button"
      },
      ".header": {
        css: ".header",
        html: "header"
      }
    }
  },
  ".portal-table-header": {
    css: ".portal-table-header",
    html: "portal-table-header",
    ".action-btns": {
      css: ".action-btns",
      html: "action-btns",
      ".btn": {
        css: ".btn",
        html: "btn"
      }
    },
    ".filters-button": {
      css: ".filters-button",
      html: "filters-button"
    },
    ".toolbar": {
      css: ".toolbar",
      html: "toolbar",
      attrs: {
        ".header": {
          css: ".header",
          html: "header"
        }
      },
      ".subheader-standard": {
        css: ".subheader-standard",
        html: "subheader-standard"
      },
      ".actions": {
        css: ".actions",
        html: "actions",
        "*": {
          css: "*",
          html: "*"
        },
        ":last-child": {
          css: ":last-child",
          html: "last-child"
        }
      }
    },
    ".group-header": {
      css: ".group-header",
      html: "group-header",
      "svg": {
        css: "svg",
        html: "svg"
      }
    },
    ".filter-type-list": {
      css: ".filter-type-list",
      html: "filter-type-list",
      attrs: {
        ".expanded": {
          css: ".expanded",
          html: "expanded"
        },
        ".checkbox-group": {
          css: ".checkbox-group",
          html: "checkbox-group"
        },
        ".transitioned": {
          css: ".transitioned",
          html: "transitioned"
        }
      }
    },
    ".filter-radio": {
      css: ".filter-radio",
      html: "filter-radio",
      ".custom-indicator": {
        css: ".custom-indicator",
        html: "custom-indicator"
      }
    },
    ".checkbox-group": {
      css: ".checkbox-group",
      html: "checkbox-group",
      attrs: {
        ".transitioning": {
          css: ".transitioning",
          html: "transitioning"
        },
        ".transitioned": {
          css: ".transitioned",
          html: "transitioned"
        }
      },
      ".close-filters-button": {
        css: ".close-filters-button",
        html: "close-filters-button"
      },
      ".header": {
        css: ".header",
        html: "header"
      }
    }
  },
  ".subheader-divider": {
    css: ".subheader-divider",
    html: "subheader-divider"
  },
  ".portal-table-container": {
    css: ".portal-table-container",
    html: "portal-table-container",
    attrs: {
      ".headerless": {
        css: ".headerless",
        html: "headerless"
      },
      ".table-error": {
        css: ".table-error",
        html: "table-error"
      }
    },
    ".portal-table-row": {
      css: ".portal-table-row",
      html: "portal-table-row",
      ".cell-control": {
        css: ".cell-control",
        html: "cell-control",
        "td": {
          css: "td",
          html: "td"
        }
      }
    },
    ".table-lg-breakpoint": {
      css: ".table-lg-breakpoint",
      html: "table-lg-breakpoint",
      "table": {
        css: "table",
        html: "table",
        "tr": {
          css: "tr",
          html: "tr",
          ".cell-control": {
            css: ".cell-control",
            html: "cell-control",
            "td": {
              css: "td",
              html: "td"
            }
          }
        }
      }
    },
    ".table-md-breakpoint": {
      css: ".table-md-breakpoint",
      html: "table-md-breakpoint",
      "table": {
        css: "table",
        html: "table",
        "tr": {
          css: "tr",
          html: "tr",
          ".cell-control": {
            css: ".cell-control",
            html: "cell-control",
            "td": {
              css: "td",
              html: "td"
            }
          }
        }
      }
    },
    "table": {
      css: "table",
      html: "table",
      "thead": {
        css: "thead",
        html: "thead"
      },
      "td": {
        css: "td",
        html: "td"
      }
    },
    ".table-loader-header": {
      css: ".table-loader-header",
      html: "table-loader-header"
    }
  },
  "table": {
    css: "table",
    html: "table",
    attrs: {
      ".draft-table": {
        css: ".draft-table",
        html: "draft-table"
      }
    },
    ".md-to-html": {
      css: ".md-to-html",
      html: "md-to-html",
      "table": {
        css: "table",
        html: "table",
        "tbody": {
          css: "tbody",
          html: "tbody"
        },
        "td": {
          css: "td",
          html: "td"
        },
        "th": {
          css: "th",
          html: "th"
        },
        "thead": {
          css: "thead",
          html: "thead"
        },
        "tr": {
          css: "tr",
          html: "tr"
        }
      }
    },
    "table": {
      css: "table",
      html: "table",
      ".md-to-html": {
        css: ".md-to-html",
        html: "md-to-html",
        "tbody": {
          css: "tbody",
          html: "tbody"
        },
        "td": {
          css: "td",
          html: "td"
        },
        "th": {
          css: "th",
          html: "th"
        },
        "thead": {
          css: "thead",
          html: "thead"
        },
        "tr": {
          css: "tr",
          html: "tr"
        }
      }
    }
  },
  ".dataTable-info": {
    css: ".dataTable-info",
    html: "dataTable-info"
  },
  ".dataTable-top": {
    css: ".dataTable-top",
    html: "dataTable-top"
  },
  ".cell-actions": {
    css: ".cell-actions",
    html: "cell-actions"
  },
  ".portal-table-action-icons": {
    css: ".portal-table-action-icons",
    html: "portal-table-action-icons",
    ":first-child": {
      css: ":first-child",
      html: "first-child",
      ".icon": {
        css: ".icon",
        html: "icon"
      }
    },
    ":last-child": {
      css: ":last-child",
      html: "last-child",
      ".icon": {
        css: ".icon",
        html: "icon"
      }
    },
    ".icon": {
      css: ".icon",
      html: "icon"
    }
  },
  ".dx-g-bs4-cursor-pointer": {
    css: ".dx-g-bs4-cursor-pointer",
    html: "dx-g-bs4-cursor-pointer"
  },
  ".dx-g-bs4-toggle-button": {
    css: ".dx-g-bs4-toggle-button",
    html: "dx-g-bs4-toggle-button"
  },
  ".portal-table-divider": {
    css: ".portal-table-divider",
    html: "portal-table-divider"
  },
  ".detail-table-title": {
    css: ".detail-table-title",
    html: "detail-table-title"
  },
  "body": {
    css: "body",
    html: "body",
    ".portal-table-row": {
      css: ".portal-table-row",
      html: "portal-table-row",
      ".table-md-breakpoint": {
        css: ".table-md-breakpoint",
        html: "table-md-breakpoint",
        ".portal-table-cell": {
          css: ".portal-table-cell",
          html: "portal-table-cell",
          attrs: {
            ".cell-control": {
              css: ".cell-control",
              html: "cell-control"
            },
            ".cell-primary": {
              css: ".cell-primary",
              html: "cell-primary"
            }
          }
        }
      },
      ".table-lg-breakpoint": {
        css: ".table-lg-breakpoint",
        html: "table-lg-breakpoint",
        ".portal-table-cell": {
          css: ".portal-table-cell",
          html: "portal-table-cell",
          attrs: {
            ".cell-control": {
              css: ".cell-control",
              html: "cell-control"
            },
            ".cell-primary": {
              css: ".cell-primary",
              html: "cell-primary"
            }
          }
        }
      }
    }
  }
};
